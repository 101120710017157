import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, NgZone, OnInit, Output, Type, ViewChild } from '@angular/core';
import { AllPatientsComponent } from '../all-patients/all-patients.component';
import { CreateNewPatientComponent } from '../create-new-patient/create-new-patient.component';
import { ContentDirective } from '../directives/content.directive';
import { ContentComponent } from '../interfaces/content-component';
import { PatientCardComponent } from '../patient-card/patient-card.component';
import { PlaceholderComponent } from '../placeholder/placeholder.component';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-content-container',
  templateUrl: './content-container.component.html',
  styleUrls: ['./content-container.component.css'],
  animations: [
    trigger('transparentOpaque', [
      state('transparent', style({
        opacity: 0
      })),
      state('opaque', style({
        opacity: 1
      })),
      transition('transparent => opaque', [
        animate('0.25s')
      ]),
      transition('opaque => transparent', [
        animate('0.25s')
      ]),
    ]),
  ],
})
export class ContentContainerComponent implements OnInit {
  transparentOpaque = "opaque";

  pages : { [key: string] : Type<ContentComponent>}= {
    "overview": PlaceholderComponent,
    "patients": AllPatientsComponent,
    "patientCard": PatientCardComponent,
    "createNewPatient": CreateNewPatientComponent,
    "messages": PlaceholderComponent,
    "administration": PlaceholderComponent,
    "mediaLibrary": PlaceholderComponent,
    "schedule": PlaceholderComponent,
    "knowledgeBase": PlaceholderComponent,
    "settings": PlaceholderComponent,
    "support": PlaceholderComponent,
  }

  currentComponent: ContentComponent | undefined;

  @Output() onPageMove: EventEmitter<{ key: string; data: any; }> = new EventEmitter<{ key: string; data: any; }>();

  @ViewChild(ContentDirective, {static: true}) contentHost: ContentDirective | undefined;

  constructor() { }

  ngOnInit(): void {
    this.loadComponent('patients', null);
  }

  public loadComponent(key: string, data: any){
    this.onPageMove.emit({key: key, data: data});
    if(this.contentHost != undefined){
      const viewContainerRef = this.contentHost.viewContainerRef;
      this.transparentOpaque = "transparent";
      var that = this;
      setTimeout(() =>{
        if(that.currentComponent != null){
          that.currentComponent.onPageMove.unsubscribe();
        }
        viewContainerRef.clear();
        that.currentComponent = viewContainerRef.createComponent<ContentComponent>(this.pages[key]).instance;
        that.currentComponent.setData(data);
        that.currentComponent.onPageMove.subscribe((value : {key: string, data: any}) =>{
          that.loadComponent(value.key, value.data);
        });
        that.transparentOpaque = "opaque";
      }, 250);
    }
  } 
}
