import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { catchError, finalize } from 'rxjs/operators';
import { SpinnerService } from './services/spinner.service';
import { AuthService } from './services/auth.service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

     constructor(private spinnerService: SpinnerService, private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.spinnerService.show();

        return next.handle(req)
             .pipe(finalize(() => {
                    this.spinnerService.hide();
                }),
                catchError((error: HttpErrorResponse) => {
                    let msg = '';
                    switch(error.status) {
                        case 0:
                            msg = 'Unknown error';
                            break;
                        case 403:
                            msg = "Forbidden - logging out";
                            this.authService.logout();
                            break;
                        case 404:
                            msg = "Not Found";
                            break;
                        case 408:
                            msg = "Timeout (Client)";
                            break;
                        case 413:
                            msg = "Payload too large";
                            break;
                        case 429:
                            msg = "Too many requests";
                            break;
                        case 500:
                            msg = "Internal server error";
                            break;
                        case 504:
                            msg = "Timeout";
                            break;
                        default:
                            msg = "Unexpecetd error";
                            break;
                        }
                    console.log("http-interceptor: http error: " + error.status + " with message: " +  error.message);
                    return throwError(() => msg);
                })
                );
    }
}