import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string = '';
  password: string = '';
  company: string = '';
  errorText: string = '';

  loginButtonTextStyle = {
    'font-family': 'Poppins-Bold',
    'font-size': '20px',
    'color': '#ffffff',
    'vertical-align': 'middle',
    'margin-left': '50px',
    'margin-top': '2px',
  }

  constructor(private authService : AuthService, private router: Router, ) { }

  ngOnInit(): void {
    if(this.authService.checkAndLogin()){
      this.onSuccessfulLogin();
    }
  }

  onSuccessfulLogin(){
    this.router.navigate(['/main']);
  }

  onEmailChange(email : string){
    this.email = email;
  }

  onPasswordChange(password: string){
    this.password = password;
  }

  onCompanyChange(company: string){
    this.company = company;
  }

  onLoginClicked(){
    this.authService.login({email: this.email, password: this.password}, (data)=>{
      this.onSuccessfulLogin();
    },(err)=>{
      this.errorText = "One or more of the fields are incorrect."
    });
  }

  onKeyup(event: any) {
    if(event.key == "Enter" ) {
      this.onLoginClicked();
    }
  }
  
}