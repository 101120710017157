<div class="top-bar">
    <div>
        <app-search-input placeholderText="Search..." (textChange)="onSearchInput($event)" [searchResults]="searchResults" (onSelected)="onSearchSelected($event)" [(text)]="searchInputText"></app-search-input>
    </div>
    <div></div>
    <div style="display: flex; ">
        <img class="icon" src="assets/img/shape.svg" />
        <div class="user">
            {{"Dr. " + this.fname + " " + this.lname}}
<!--            Dr. Ofer Elishoov
-->
        </div>
        <div *ngIf="isUserMenuOpen" class="drawer">
            <div *ngFor="let option of userMenuOptions" class="drawer-option" (click)="onOptionSelected(option)">
                <div class="selected-text">
                    {{option.displayName}}
                </div>
            </div>
        </div>        
        <div style="display: flex; ">
            <button class="expand-btn" (click)="isUserMenuOpen = !isUserMenuOpen">
                <img [ngClass]="isUserMenuOpen ? 'cheveron-up' : 'cheveron-down'" src="assets/img/cheveron-full.svg" />
             </button>
        </div>
        <div style="width: 109px;"></div>
        <button class="btn-disabled" >
            <img class="notifications-btn-img" src="assets/img/ic-notifications-48px.png" />
        </button>
        <div style="width: 26px;"></div>
        <div style="margin-top: 8px; width: 1px; height: 32px; background-color: rgba(98,131,166,0.2);"></div>
        <div style="width: 26px;"></div>
        <button class="btn-disabled" >
            <img class="settings-btn-img" src="assets/img/settings.png" />
        </button>
    </div>
</div>
