import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone, OnInit, QueryList, ViewChild, ViewChildren  } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { NewVisitDialogComponent } from '../dialogs/new-visit-dialog/new-visit-dialog.component';
import { GraphComponent } from '../graph/graph.component';
import { ContentComponent } from '../interfaces/content-component';
import { DataPoint, GraphPoint } from '../interfaces/data-point';
import { PatientRecord, PatientSummary, InsoleRecord } from '../interfaces/patient-record';
import { SelectInterface } from '../interfaces/select-interface';
import { ApiService } from '../services/api.service';
import { CustomDialogService } from '../services/custom-dialog.service';
import { ToggleComponentItem, ToggleComponent } from '../toggle/toggle.component';
import 'chartjs-adapter-moment';
import { WidgetItem } from '../widget/widget-item';
import { GraphWidgetComponent } from '../widget/widgets/graph-widget/graph-widget.component';
import { AlertsWidgetComponent } from '../widget/widgets/alerts-widget/alerts-widget.component';
import { Alert } from '../interfaces/alert';
import { WidgetComponent } from '../widget/widget.component';
import { formatDate } from '@angular/common';
import { Convertions } from 'utils/converstions';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SpinnerService } from '../services/spinner.service';
import { HttpClient } from '@angular/common/http';
import { Activity } from '../interfaces/activity';
import { WidgetInterface } from '../widget/widget-interface';

enum apiTriggerer {
  dateRange,
  recordLeft,
  recordRight
}


@Component({
  selector: 'app-patient-card',
  templateUrl: './patient-card.component.html',
  styleUrls: ['./patient-card.component.scss']
})
export class PatientCardComponent implements OnInit, ContentComponent,AfterViewInit {
  summaryRecord: PatientSummary = {
    id: 0,
    imageBase64: '',
    is_active: true,
    isChecked: false,
    insole_status: false,
    //lastActiveInsoleDate: new Date(),
    authorization_date: new Date(),  
    last_name: '',
    first_name: '',
    patient_id: '',
    last_record_date: new Date(),
    last_seen: '',
    last_visits: []   
  }
  record : PatientRecord = {
    basicInfo: {
      id: 0,
      first_name : '',
      last_name: '',
      address1:'',
      address2:'',
      city: '',
      //dob: new Date(),
      dob: '',
      email: '',
      gender: 'male',
      phone_number: '',
      shoe_size: '8',
      state: '',
      zip_code: '',
      imageBase64: '',
      patient_id: '',
      footedness: 'right'
    },
    medicalInfo: {
      feet_pathology: '',
      relevant_background: '',
      foot_ulcer: false
    },
    insurance: {
      insurer: '',
      insurer_visits: 12,
    },
    history: [],
    isActive : true,
    insoleStatus: false,
    lastActiveInsoleDate: new Date(),
    authorizationDate: new Date(),
  };

  selectShow : SelectInterface = {
    constantText : '',
    constantTextStyle: {},
    optionTextStyle: {},
    selectedOptionKey: {},
    options: [],
    drawerStyle: {},
    selectDivStyle: {},
  };

  selectRecords : SelectInterface = {
    constantText : '',
    constantTextStyle: {},
    optionTextStyle: {
      'font-family': 'Poppins-Bold',
      'font-size': '14px',
      'width': '210px',
      'margin-top': '-4px',
    },
    selectedOptionKey: {},
    options: [],
    drawerStyle: {
      'max-height': '250px',
      'width': '286px',
      'margin': '1px 15px 1px 15px',
    },
    selectDivStyle: {
      'height': '38px',
      'width': '286px',
      'margin': '1px 15px 1px 15px',
      'border-radius': '10px',
      'border': 'solid 1px #f1f1f5',
      'background-color': '#fff',

    }
  };

  patientsList: PatientSummary[] = [];
  endDate: Date = new Date();
  startDate: Date = new Date();
  offsetMs: number = 0;
  graphAlerts: Alert[] = [];
  clickedAlert: Alert = {graph_type:'pressure', severity:'low', message:'', foot_side:'left', foot_area:'heel', date: new Date(), value: 0};
  graphPressure: GraphPoint[] = [];
  graphTemperature: GraphPoint[] = [];

  heatmap_pressure_left: string = '';
  heatmap_pressure_right: string = '';
  heatmap_temperature_left: string = '';
  heatmap_temperature_right: string = '';
  heatmap_temperature_api_msg: string = '';
  
  showVideoLeft: boolean = true;
  showVideoRight: boolean = true;
  patientRecords: InsoleRecord[] = [];

  triggererEnum: typeof apiTriggerer = apiTriggerer;
  spinnerTriggerer: apiTriggerer = this.triggererEnum.dateRange;

  heatmap_pressure_left_api_msg: string = '';
  heatmap_pressure_right_api_msg: string = '';
  heatmap_temperature_left_api_msg: string = '';
  heatmap_temperature_right_api_msg: string = '';
  videoLeftApiMsg: string = '';
  videoRightApiMsg: string = '';

  activities: DataPoint[] = [];
  steps: DataPoint[] = [];
  
  @ViewChild('divToMeasure') divToMeasureElement: ElementRef | undefined;
  //@ViewChild('selectedVideo') selectedVideoElement: ElementRef | undefined;

  @ViewChild('showToggleOptions') showToggleOptions: ToggleComponent | undefined;
  @ViewChild('pressureGraph') pressureGraph: GraphComponent | undefined;
  @ViewChild('temperatureGraph') temperatureGraph: GraphComponent | undefined;
  
  @ViewChildren(WidgetComponent) widgetQuery: QueryList<WidgetComponent> | undefined;

  footOptions: ToggleComponentItem[] = [
    { value: "left", displayName: "LEFT"},
    { value: "both", displayName: "BOTH"},
    { value: "right", displayName: "RIGHT"},
  ];

  showOptions: ToggleComponentItem[] = [
    { value: "heel", displayName: "Heel"},
    { value: "archDist", displayName: "Dist. Arch"},
    { value: "arch", displayName: "Arch"},
    { value: "archProx", displayName: "Prox. Arch"},
    { value: "midFoot", displayName: "Mid Foot"},
    { value: "1stMeta", displayName: "1st Meta"},
    { value: "2ndMeta", displayName: "2nd Meta"},
    { value: "3-4thMeta", displayName: "3-4th Meta"},
    { value: "5thMeta", displayName: "5th Meta"},
    { value: "bigToe", displayName: "Big Toe"},
    { value: "2ndToe", displayName: "2nd Toe"},
  ];

  pressureGraphOptions : ChartOptions= {
    responsive:true,
    maintainAspectRatio: false,
    scales: {
      x: {
        //type: 'time',
/*        
        time:{
          round: 'second',
          minUnit: 'millisecond',
          displayFormats:{
            second: 'MM/DD/YY hh:mm:ss',
            minute: 'MM/DD/YY hh:mm:ss',
            hour: 'MM/DD/YY hh:mm:ss',
            hour: 'MM/DD/YY hh a',
            day: 'MM/DD/YY',
            month: 'MM/DD/YY',
          }
        },
*/
        type: 'category',
        ticks: {
          font: {
            family: 'Poppins-Regular',
          }
        },
        grid: {
          display:true
        }
      },
      y: {
        beginAtZero: false,
        title:{
          display: true,
          text: 'Pressure (kPA)',
          font: {
            family: 'Poppins-Regular',
          }
        },
        min: 0,
        max: 350,
        ticks: {
          font: {
            family: 'Poppins-Regular',
          }
        },
        grid:{
          display:false
        }
      },
    },
    plugins: {
    }
  };

  pressureData : ChartData= {
    labels: [],
    datasets: [{
      pointBackgroundColor: ['black'],
      pointRadius: 3,
      data: Array<number>(),
      type: 'line',
      label: 'Left',
      fill: false,
      spanGaps:true,
      normalized: true
    },
    {
      pointBackgroundColor: ['black'],
      pointRadius: 3,
      data: Array<number>(),
      type: 'line',
      label: 'Right',
      fill: false,
      spanGaps:true,
      normalized: true
    }]
  }; 

  temperatureData : ChartData = {
    datasets: [{
      pointBackgroundColor: ['black'],
      pointRadius: 3,
      data: Array<number>(),
      type: 'line',
      label: 'Left',
      fill: false,
      spanGaps:true,
      normalized: true,
    },
    {
      pointBackgroundColor: ['black'],
      pointRadius: 3,
      data: Array<number>(),
      type: 'line',
      label: 'Right',
      fill: false,
      spanGaps:true,
      normalized: true,
    }],
  }; 

  temperatureGraphOptions : ChartOptions= {
    responsive:true,
    maintainAspectRatio: false,
    scales: {
      x: {
/*
        type: 'time',
        time:{
          round: 'second',
          minUnit: 'millisecond',
          displayFormats:{
            //second: 'MM/DD/YY hh:mm:ss',
            //minute: 'MM/DD/YY hh:mm:ss',
            //hour: 'MM/DD/YY hh:mm:ss',
            day: 'MM/DD/YY',
            month: 'MM/DD/YY',
          }
        },
*/
        type: 'category',
        ticks: {
          font: {
            family: 'Poppins-Regular',
          }
        },
        grid: {
          display:true
        }
      },
      y: {
        beginAtZero: false,
        title:{
          display: true,
          text: 'Temperature (°C)',
          font: {
            family: 'Poppins-Regular',
          }
        },
        min: 18,
        max: 42,
        ticks: {
          font: {
            family: 'Poppins-Regular',
          }
        },
        grid:{
          display:false
        }
        
      },
    },
    plugins: {
    }
  };

  widgets : WidgetItem[] = [
    {
      component: GraphWidgetComponent,
      data: { 
        backgroundColor: "#ebfded",
        icon: "assets/img/heart-graph.svg",
        title: "AVG daily activity",
        calculateValue: (activities : DataPoint[]) =>{
          return activities.length > 0 ? Math.floor(activities.map(v => v.value).reduce((partialSum, a) => partialSum + a, 0) / activities.length).toString() + ' min.' : 'No Data';
        },
        getData: (callback: (activities : DataPoint[]) => void) => {
          callback(this.activities);
        }
      },
    },
    {
      component: GraphWidgetComponent,
      data: { 
        backgroundColor: "#fffdf2",
        icon: "assets/img/footprints.svg",
        title: "AVG daily steps",
        calculateValue: (steps : DataPoint[]) =>{
          return steps.length > 0 ? Math.floor(steps.map(v => v.value).reduce((partialSum, a) => partialSum + a, 0) / steps.length).toString() : 'No Data';
        },
        getData: (callback: (points : DataPoint[]) => void) => {
          //return this.api.getGraphData({patient_id: this.summaryRecord.id, startDate: this.startDate, endDate: this.endDate, foot: 'both', footArea: this.showSelectedItem.value, measurementType: 'steps', dataType: 'points', averageSpanMs: 30000},callback);
          callback(this.steps);
        }
      },
    },
  ]

  alertsWidget : WidgetItem = {
    component: AlertsWidgetComponent,
    data: {
      backgroundColor: "#fff6ed",
      icon: "assets/img/alerts.svg",
      getData: (callback: (alerts : Alert[]) => void) => {
        callback(this.graphAlerts);
      }
    },
  }

  showSelectedItem : ToggleComponentItem = this.showOptions[0];

  videoDataLeft: {url: SafeResourceUrl} = {
    url:''
  };

  videoDataRight: {url: SafeResourceUrl} = {
    url:''
  };

  onFootSelectedChange($event: ToggleComponentItem){
    this.updateGraphsSide($event.value);
  }

  onShowSelectedChange($event: ToggleComponentItem){
    this.showSelectedItem = $event;
    this.updateGraphsArea($event.value);
  }

  onRecordOptionChanged($event: string) {
    console.log("patient-card: onRecordOptionChanged: selected: " + $event);
    if($event == "0") {
      this.showVideoLeft = false;
      this.videoDataLeft.url = '';
      this.heatmap_pressure_left_api_msg= '';
      this.videoLeftApiMsg = '';

      this.showVideoRight = false;
      this.videoDataRight.url = '';
      this.heatmap_pressure_right_api_msg = '';
      this.videoRightApiMsg = '';
      return;
    }

    let record = this.patientRecords.find(rec => rec.id == Number.parseInt($event));
    if(record?.is_right_foot) {
      this.spinnerTriggerer = this.triggererEnum.recordRight;
      this.showVideoRight = false;
      this.heatmap_pressure_right_api_msg = '';
      this.videoRightApiMsg = '';
    }
    else {
      this.spinnerTriggerer = this.triggererEnum.recordLeft;
      this.showVideoLeft = false;
      this.heatmap_pressure_left_api_msg= '';
      this.videoLeftApiMsg = '';
    }
      this.api.getRecordVideo(Number.parseInt($event), (video, err)=> {
        if(err != '') {
          if(record?.is_right_foot) {
            this.heatmap_pressure_right_api_msg= err;
            this.videoRightApiMsg = err;
          }
          else {
            this.heatmap_pressure_left_api_msg= err;
            this.videoLeftApiMsg = err;  
          }        
        }
        else {
          if(record?.is_right_foot) {
            this.videoDataRight.url = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(video));
            this.showVideoRight = true;
          }
          else {
            this.videoDataLeft.url = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(video));
            this.showVideoLeft = true;
          }  
        }
        this.changeDet.detectChanges();
    })
  }

  constructor(private changeDet : ChangeDetectorRef, private dialog: CustomDialogService, private api: ApiService, private utlConv: Convertions, private domSanitizer: DomSanitizer, public spinnerService: SpinnerService,
    private httpClient: HttpClient) { }

  ngAfterViewInit(): void {
    this.loadData();
  }
  setData(data: any): void {
    if(data.record.basicInfo != undefined) this.record = data.record;
    else {
      this.record.basicInfo.id = data.record.id;
    }
    if(data.list != undefined) this.patientsList = data.list;
  }
  onPageMove: EventEmitter<{ key: string; data: any; }> = new EventEmitter<{ key: string; data: any; }>();

  loadData() {
    this.clearApiMsg();
    this.selectRecords.selectedOptionKey = 0; // Clear record selection
    
    this.api.getHeatmapImages("temperature", this.record.basicInfo.id, this.startDate.toISOString(), this.endDate.toISOString(), (heatmaps, err) => {
      if(err != '') {
        this.heatmap_temperature_left = '';
        this.heatmap_temperature_right = '';
        this.heatmap_temperature_left_api_msg = err;
        this.heatmap_temperature_right_api_msg = err;
        return;    
      }
      this.heatmap_temperature_left = heatmaps.image_left == null ? '': heatmaps.image_left;
      this.heatmap_temperature_right = heatmaps.image_right == null ? '': heatmaps.image_right;

      if(this.heatmap_temperature_left == '') this.heatmap_temperature_left_api_msg = 'No Data';
      if(this.heatmap_temperature_right == '') this.heatmap_temperature_right_api_msg = 'No Data';
    })

    setTimeout(() => this.api.getHeatmapImages("pressure", this.record.basicInfo.id, this.startDate.toISOString(), this.endDate.toISOString(), (heatmaps,err) => {
      if(err != '') {
        this.heatmap_pressure_left = '';
        this.heatmap_pressure_right = '';
        this.heatmap_pressure_left_api_msg = err;
        this.heatmap_pressure_right_api_msg = err;
        return;    
      }

      this.heatmap_pressure_left = heatmaps.image_left == null ? '': heatmaps.image_left;
      this.heatmap_pressure_right = heatmaps.image_right == null ? '': heatmaps.image_right;

      if(this.heatmap_pressure_left == '') this.heatmap_pressure_left_api_msg = 'No Data';
      if(this.heatmap_pressure_right == '') this.heatmap_pressure_right_api_msg = 'No Data';
    }), 100);

    // Replace getGraphsData with getGraphsDataLambda (same API only through Lambda)
    //this.api.getGraphsData(this.record.basicInfo.id, this.startDate.toISOString(), this.endDate.toISOString(), (graphs, err) => {
    this.api.getGraphsDataLambda(this.record.basicInfo.id, this.startDate.toISOString(), this.endDate.toISOString(), (graphs, err) => {
      if(err!='') {
        //console.log("PatientCard: getGraphsData: error = " + err);
        console.log("PatientCard: getGraphsDataLambda: error = " + err);
        this.graphPressure = [];
        this.graphTemperature = [];
        this.graphAlerts = [];
        this.updateGraphsArea(this.showSelectedItem.value);
        this.updateAlertsWidget();
        return;
      }
      // Remove redundant data (select only 1 sensor from complexed areas)
      ["midFoot", "3-4thMeta"].forEach(area => {                           // Loop Areas with multiple sensors
        let pressureSensors = this.utlConv.getSensorsByAreaPressure(area); // Retrieve sensors list related to specified Area
        ["left", "right"].forEach(footSide => {                            // Loop foot sides
          let selectedArea = this.getSensorWithHighestVal(graphs.pressure, footSide, pressureSensors);                       // Get selected sensor with highest values for specified footArea and footSide
          graphs.pressure = this.removeSensorsDataFromAreaPressure(graphs.pressure, footSide, pressureSensors, selectedArea); // Remove redundant sensors' data from pressure graph for specified footArea and footSide
          graphs.alerts = this.removeSensorsDataFromAreaAlert(graphs.alerts, footSide, pressureSensors, selectedArea);        // Remove redundant sensors' data from Alerts for specified footArea and footSide
        });  
      })
    
      this.graphPressure = graphs.pressure;
      this.graphTemperature = graphs.temperature;
      this.graphAlerts = graphs.alerts;

      this.updateGraphsArea(this.showSelectedItem.value);
      this.updateAlertsWidget();
    });

    this.api.getActivities(this.record.basicInfo.id, this.startDate.toISOString(), this.endDate.toISOString(), (activities, err) => {
      if(err!='') {
        console.log("PatientCard: getActivities: error = " + err);
        this.activities = [];
        this.updateActivityWidget();
        return;
      }
      //this.activities = activities;
      let steps_dp: DataPoint[] = [];
      let activity_dp: DataPoint[] = [];
      let lastDate = "";
      let steps = 0;
      let duration = 0;
      if(this.selectShow.selectedOptionKey=='day') {
        (activities as Activity[]).forEach(activity => {
          let duration = new Date(activity.record_end_datetime_local).getTime() - (new Date(activity.record_start_datetime_local).getTime());
          steps_dp.push({date: new Date(activity.record_start_datetime_local), value: activity.number_of_steps})
          activity_dp.push({date: new Date(activity.record_start_datetime_local), value: duration/1000/60})
        });  
      } else {
        for(let i=0; i<activities.length; i++) {
          let activity: Activity = activities[i];
          let activityDate = formatDate(activity.record_start_datetime_local,"YYYY-MM-dd", "en-us");
          if(lastDate!="" &&  lastDate!=activityDate) {
            steps_dp.push({date: new Date(lastDate), value: steps});
            activity_dp.push({date: new Date(lastDate), value: duration/1000/60});
            steps = 0;
            duration = 0;
          }
          duration = duration + new Date(activity.record_end_datetime_local).getTime() - (new Date(activity.record_start_datetime_local).getTime());
          steps = steps + activity.number_of_steps;
          lastDate = activityDate;
        }
        steps_dp.push({date: new Date(lastDate), value: steps});
        activity_dp.push({date: new Date(lastDate), value: duration/1000/60});    
      }

      this.steps = steps_dp;
      this.activities = activity_dp;
      this.updateActivityWidget();
    });

  }

  clearApiMsg() {
    this.heatmap_pressure_left_api_msg= '';
    this.heatmap_pressure_right_api_msg = '';
    this.heatmap_temperature_left_api_msg = '';
    this.heatmap_temperature_right_api_msg = '';
    this.videoLeftApiMsg = '';
    this.videoRightApiMsg = '';
  }

  updateAlertsWidget() {
    this.widgetQuery?.forEach(widgetComp => {
      if(widgetComp.type  == AlertsWidgetComponent) {
        widgetComp.alerts = this.graphAlerts;
        widgetComp.updateView();
      }
    });
  }

  /*instanceOfGraphWidgetComponent(object: any): object is GraphWidgetComponent{
    return typeof object.loadData === 'function';
  }*/

  updateActivityWidget() {
    this.widgetQuery?.forEach(widgetComp => {
      widgetComp.updateView();
    });
  }

  updateGraphsArea(footArea: any) {
    console.log("patient-card: updateGraphsArea");
    let pressure = this.graphPressure;
    let sorted = pressure.sort((a, b) => new Date(a.points[0]?.date).getTime() - new Date(b.points[0]?.date).getTime());
    let pressureSensors = this.utlConv.getSensorsByAreaPressure(footArea);
    let sideWithData = this.getFirstFootSideWithData(pressure);
    let graphLabels = this.getGraphLabels(this.startDate, this.endDate, sorted.map((v) => (v.foot_side == sideWithData && pressureSensors.includes(v.foot_area)) ? v : null).filter(data => data != null));
    //this.pressureData.datasets[0].data = this.getGraphMeanVals(sorted, "left", pressureSensors);
    //this.pressureData.datasets[1].data = this.getGraphMeanVals(sorted, "right", pressureSensors);;
    this.pressureData.datasets[0].data = this.getGraphHighestSensorVals(sorted, "left", pressureSensors);
    this.pressureData.datasets[1].data = this.getGraphHighestSensorVals(sorted, "right", pressureSensors);
    this.pressureData!.labels! = graphLabels;
    this.pressureGraph?.update();

    let temperature = this.graphTemperature;
    let sorted2 = temperature.sort((a, b) => new Date(a.points[0]?.date).getTime() - new Date(b.points[0]?.date).getTime());
    let temperatureSensors = this.utlConv.getSensorsByAreaTemperature(footArea);
    //this.temperatureData.datasets[0].data = this.getGraphMeanVals(sorted2, "left", temperatureSensors);
    //this.temperatureData.datasets[1].data = this.getGraphMeanVals(sorted2, "right", temperatureSensors);
    this.temperatureData.datasets[0].data = this.getGraphHighestSensorVals(sorted2, "left", temperatureSensors);
    this.temperatureData.datasets[1].data = this.getGraphHighestSensorVals(sorted2, "right", temperatureSensors);
    this.temperatureData!.labels! = graphLabels;
    this.temperatureGraph?.update();
  }

  getFirstFootSideWithData(graphPoints: GraphPoint[]) : string {
    let side = "left"; // Default
    let pointWithData: GraphPoint | undefined = undefined;
    pointWithData = graphPoints.find(gPoint => gPoint.points.length > 0);
    if (pointWithData != undefined) side = pointWithData.foot_side;
    return side;
  }

  getGraphHighestSensorVals(graphPoints: GraphPoint[], side: any, areas: string[]) : number[] {
    let area = this.getSensorWithHighestVal(graphPoints, side, areas);
    let vals: number[] = this.getGraphVals(graphPoints, side, area);
    return vals;
  }

  getGraphMeanVals(graphPoints: GraphPoint[], side: any, areas: string[]) : number[] {
    let vals: number[] = [];
    areas.forEach(area => {
      let temp: number[] = this.getGraphVals(graphPoints, side, area)
      temp.forEach((val, idx) => vals[idx] = (vals[idx] | 0) + val);
    })
    vals.forEach((val, idx) => vals[idx] = vals[idx]/areas.length);
    return vals;
  }

  getGraphVals(graphPoints: GraphPoint[], side: any, area: string) : number[] {
    let vals: number[] = [];
    let filtered: GraphPoint[] = graphPoints.filter((v) => (v.foot_side == side && v.foot_area == area));
    filtered.forEach(gp => gp.points.forEach(dp => vals.push(dp.value)));
    return vals;
  }

  getSensorWithHighestVal(graphPoints: GraphPoint[], side: any, areas: string[]) : string {
    let result: string = areas[0];
    let max: number = 0;
    areas.forEach(area => {
      let temp: number[] = this.getGraphVals(graphPoints, side, area);
      if(temp.length == 0) return;
      let candidate: number = temp.reduce((a,b) => Math.max(a,b));
      if(candidate > max) {
        result = area;
        max = candidate;
      }
    })

    return result;
  }

  removeSensorsDataFromAreaPressure(graphPoints: GraphPoint[], side: any, areas: string[], selectedArea: string) : GraphPoint[] {
    areas.forEach(area => {
      if(area != selectedArea) {
        graphPoints.forEach( (gPoint, idx) => {
          if(gPoint.foot_area == area && gPoint.foot_side == side)
          graphPoints.splice(idx, 1);
        })
      }
    })
    return graphPoints;
  }

  removeSensorsDataFromAreaAlert(alerts: Alert[], side: any, areas: string[], selectedArea: string) : Alert[] {
    areas.forEach(area => {
      if(area != selectedArea) {
        alerts.forEach( (alert, idx) => {
          if(alert.foot_area == area && alert.foot_side == side)
          alerts.splice(idx, 1);
        })
      }
    })
    return alerts;
  }
  
  getGraphLabels(startDate: Date, endDate: Date, graphPoints: (GraphPoint|null)[]) : Array<string> {
    let labels: Array<string> = [];
/*
    if(startDate == endDate) {
      let year = startDate.getFullYear();
      let month = startDate.getMonth();
      let date = startDate.getDate();
      for(let i =1 ; i < 24; i++) labels.push(formatDate(new Date(year,month,date,i,0),"YYYY-MM-dd hh:mm", "en-us"));
    }
    else {
      let step = 1;
      for(let dt=new Date(startDate); dt<=endDate; ){
        labels.push(formatDate(dt, "YYYY-MM-dd", "en-us"));
        dt.setDate(dt.getDate() + step);
      }
    }
*/
    let points: DataPoint[] | undefined = [];
    if (graphPoints.length > 0) points = graphPoints[0]?.points;
    if(points != undefined) points.forEach(point => labels.push(formatDate(new Date(point.date), "MM-dd, h a", "en-us")));

    return labels;
  }

  updateGraphsSide(footSide: any) {
    console.log("patient-card: updateGraphsSide");
    let hideLeft: boolean = (footSide == 'right');
    let hideRight: boolean = (footSide == 'left');

    this.pressureData.datasets[0].hidden = hideLeft;
    this.pressureData.datasets[1].hidden = hideRight;
    this.pressureGraph?.update();

    this.temperatureData.datasets[0].hidden = hideLeft;
    this.temperatureData.datasets[1].hidden = hideRight;
    setTimeout(() => {this.temperatureGraph?.update()},50);
  }

  ngOnInit(): void {
    var date = new Date();
    this.startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()-6, 0, 0, 0, 0);
    // START - REMOVE AFTER TESTING
    //this.startDate = new Date(1999,11,1, 0, 0, 0, 0);
    //this.startDate = new Date(2000,0,1, 0, 0, 0, 0);
    //this.endDate = new Date(2000,1,1, 0, 0, 0, 0);
    // END - REMOVE AFTER TESTING
    this.selectShow = {
      constantText : '',
      constantTextStyle : {
        //'font-family': 'Poppins-Bold' 
      },
      optionTextStyle: {
        'font-family': 'Poppins-Bold',
        'width': '140px'
      },
      selectedOptionKey: 'week',
      options: [
        {
          key: 'day', displayName: 'Day'
        },
        {
          key: 'week', displayName: 'Week'
        },
        {
          key: 'month', displayName: 'Month'
        },
        {
          key: 'custom', displayName: 'Custom'
        }
      ],
      drawerStyle: {},
      selectDivStyle: {}
    };

    if(this.record.basicInfo.id != 0) {
      this.api.getPatient(this.record.basicInfo.id, (basicInfo) => {
        if(basicInfo.gender == null) basicInfo.gender = "male";
        this.record.basicInfo = basicInfo;
      })
      this.api.getPatientImage(this.record.basicInfo.id, (image) => {
        this.record.basicInfo.imageBase64 = image;
      });
      this.api.getVisits(this.record.basicInfo.id, (visits) => {
        visits.forEach(visit => {
          visit.visit_date_start = new Date(visit.visit_date_start);
          visit.visit_date_end = new Date(visit.visit_date_end);
        })
        this.record.history = visits;
      })
      this.api.getInsurance(this.record.basicInfo.id, (insurance) => {
        this.record.insurance = insurance;
      })
      this.api.getMedicalInfo(this.record.basicInfo.id, (medicalInfo) => {
        this.record.medicalInfo = medicalInfo;
      })

      this.api.getInsoleRecords(this.record.basicInfo.id, (recordsList) => {
        this.patientRecords = recordsList;
        let recordOptions:{key: any, isRightFoot: any, displayName: string}[] = [];
        recordOptions.push({key:0, isRightFoot:'', displayName: "Play gait records"})
        recordsList.forEach(record => {
          //let rec_info: string = formatDate(record.record_start_datetime_local, "EEE dd MMM hh:mm a", "en-us");
          let isNotSynced = record.record_start_datetime_local.startsWith("2000")? true : record.record_start_datetime_local.startsWith("1999")? true : false;
          let rec_info: string = formatDate(isNotSynced ? record.creation_date : record.record_start_datetime_local, "EEE dd MMM hh:mm a", "en-us");
          isNotSynced ? rec_info = rec_info + '*' : rec_info = rec_info + '';
          record.is_right_foot? rec_info = rec_info + ' (Right)': rec_info = rec_info + ' (Left)';
          recordOptions.push({key:record.id, isRightFoot: record.is_right_foot, displayName: rec_info});
          this.selectRecords.options = recordOptions;
        })

        this.changeDet.detectChanges();
      })
    }
    this.videoDataLeft.url = '';
    this.videoDataRight.url = '';
  }

  onShowOptionChanged($event: string){
    switch($event){
      case 'month':
        var date = new Date();
        this.startDate = new Date(date.getFullYear(), date.getMonth()-1, date.getDate(), 0, 0, 0, 0);
        this.clearRangeData();
        break;
      case 'week':
        var date = new Date();
        //Last 7 days
        //this.startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()-7, date.getHours(), date.getMinutes(), 0, 0);
        this.startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()-6, 0, 0, 0, 0);
        this.clearRangeData();
        break;
      case 'day':
        var date = new Date();
        //this.startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()-1, date.getHours(), date.getMinutes(), 0, 0);
        this.startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
        this.clearRangeData();
        break;
      case 'custom':
        break;
    }
    if($event == "custom") return;
    this.endDate = new Date();
    this.spinnerTriggerer = this.triggererEnum.dateRange;
    this.loadData();
  }

  clearRangeData() {
    this.showVideoLeft = false;
    this.showVideoRight = false;

    this.pressureData.datasets[0].data = [];
    this.pressureData.datasets[1].data = [];
    this.temperatureData.datasets[0].data = [];
    this.temperatureData.datasets[1].data = [];
    this.heatmap_pressure_left = '';
    this.heatmap_pressure_right = '';
    this.heatmap_temperature_left = ''
    this.heatmap_temperature_right = ''
  }

  getAge(){
    var today = new Date();
    var dob = new Date(this.record.basicInfo.dob);
    var age = today.getFullYear() - dob.getFullYear();
    var m = today.getMonth() - dob.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
        age--;
    }
    return age;
  }

  addPatientVisit(){
    const ref = this.dialog.open(NewVisitDialogComponent, {
      data: { record: {user: this.record.basicInfo.id} },
      //paddingBottom: "33px",
      //paddingTop: "40px",
      //paddingLeft: "48px",
      //paddingRight: "48px",

      paddingBottom: "30px",
      paddingTop: "30px",
      paddingLeft: "40px",
      paddingRight: "40px",
      borderRadius: "34px"
    });

    ref.afterClosed.subscribe(result => {
      if((result.user != 0) && (result.user != undefined) ){
        //TODO: Add result.visit to DB.
        this.record.history.push(result);
      }
      this.dialog.dialogComponentRef?.destroy();
    });
  }

  onAlertClicked($event: Alert) {
    console.log("patient-cart: onAlertClicked");
    this.highlightAlert($event);
  }

  highlightAlert(alert: Alert) {
    var point;
    var dataIndex;
    //let alertDate = formatDate(new Date(alert.graph_info.points[0].date), "YYYY-MM-dd, hh:mm a", "en-us")
    //const dsIndex = alert.graph_info.foot_side == "left" ? 0 : 1;
    //const area = alert.graph_info.foot_area;
    let alertDate = formatDate(new Date(alert.date), "YYYY-MM-dd, hh:mm a", "en-us")
    const dsIndex = alert.foot_side == "left" ? 0 : 1;
    let area = '';
    if (alert.graph_type == "pressure") area = this.utlConv.getAreaBySensorPressure(alert.foot_area);
    else if (alert.graph_type == "temperature") area = this.utlConv.getAreaBySensorTemperature(alert.foot_area);
    let areaToggleIndex = this.showOptions.findIndex(option => option.value == area);
    if(this.showToggleOptions != undefined)  {
      this.showToggleOptions.selected = this.showOptions[areaToggleIndex];
      this.showToggleOptions.selectedChange.emit(this.showOptions[areaToggleIndex])
    }

    switch (alert.graph_type) {
      case 'temperature':
        point = this.temperatureData.labels?.find(lblDate => lblDate == alertDate)
        dataIndex = this.temperatureData.datasets[dsIndex].data.findIndex(value => value == alert.value);
        break;
      case 'pressure':
        point = this.pressureData.labels?.find(lblDate => lblDate == alertDate)
        dataIndex = this.pressureData.datasets[dsIndex].data.findIndex(value => value == alert.value);
        let gdata = this.pressureData.datasets[dsIndex].data;
        this.pressureData.datasets[dsIndex] = {
          pointRadius: function(context) {
            var index = context.dataIndex
            var value = context.dataset.data[index];
            if (value == alert.value) {
              return 7;
            }
            return 3;
          },
          data: gdata,
          type: 'line',
          label: 'Left',
          fill: false,
          spanGaps:true,
          normalized: true    
        },
        this.pressureGraph?.update();
      break;
      }
    console.log("Point Found: " + point);
  }

  updateStartDate($event: Date) {
    this.startDate = $event;
  }

  updateEndDate($event: Date) {
    this.endDate = $event;
    this.endDate.setHours(23);
    this.endDate.setMinutes(59);
    this.endDate.setSeconds(59);
  }

  updateDateRange() {
    console.log("patient-card: updateDateRange");
    this.clearRangeData();
    this.loadData();
  }

  onVisitDeleted(visitId: number) {
    const index = this.record.history.findIndex(visit => visit.id == visitId);
    this.record.history.splice(index,1);
  }

  onAllPatientsRouteClick() {
      this.onPageMove.emit({key: 'patients', data: null});  
  }
}
