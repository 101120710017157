import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ToggleComponentItem{
  value: any;
  displayName: string;
}

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {

  @Input() items: ToggleComponentItem[] = [];
  @Output() selectedChange: EventEmitter<ToggleComponentItem> = new EventEmitter<ToggleComponentItem>();
  @Input() selected?: ToggleComponentItem;
  @Input() isVertical: boolean = false;
  @Input() disabled: boolean = false;
  value: string = '';

  constructor() {}

  ngOnInit(): void {
  }

  onItemSelected(selected: ToggleComponentItem){
    if(this.disabled) return;
    if(this.selected != selected){
      this.selected = selected;
      this.selectedChange.emit(selected);
    }
  }

}
