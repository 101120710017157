import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimeInputTime } from '../time-input/time-input.component';

@Component({
  selector: 'app-time-range-input',
  templateUrl: './time-range-input.component.html',
  styleUrls: ['./time-range-input.component.scss']
})
export class TimeRangeInputComponent implements OnInit {

  _MS_PER_MINUTE = 1000 * 60;

  @Input() date : Date = new Date();
  @Output() startDate: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() endDate: EventEmitter<Date> = new EventEmitter<Date>();

  get datePlus15(){
    return new Date(this.date.getTime() + 15 * this._MS_PER_MINUTE);
  }

  startTime : TimeInputTime = { hours: this.date.getHours() > 11 ? this.date.getHours() - 12 : this.date.getHours(), minutes: this.date.getMinutes(), is24hour: false, isAm: this.date.getHours() < 11};
  endTime : TimeInputTime = { hours: this.datePlus15.getHours() > 11 ? this.datePlus15.getHours() - 12 : this.datePlus15.getHours(), minutes: this.datePlus15.getMinutes(), is24hour: false, isAm: this.datePlus15.getHours() < 11};

  get duration(){
    let start = new Date(this.date);
    start.setHours(this.startTime.is24hour ? this.startTime.hours : (this.startTime.isAm ? this.startTime.hours : this.startTime.hours + 12));
    start.setMinutes(this.startTime.minutes);
    let end = new Date(this.date);
    end.setHours(this.endTime.is24hour ? this.endTime.hours : (this.endTime.isAm ? this.endTime.hours : this.endTime.hours + 12));
    end.setMinutes(this.endTime.minutes);
    let duration = this.dateDiffInMinutes(start, end);
    let minutes = duration % 60;
    let hours = Math.floor(duration / 60);
    return hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0');
  }

  dateDiffInMinutes(a : Date, b : Date) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate(), a.getHours(), a.getMinutes(), a.getSeconds());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), b.getHours(), b.getMinutes(), b.getSeconds());
  
    return Math.floor((utc2 - utc1) /this._MS_PER_MINUTE);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
