<div class="grid">
    <div>
        <div class="path" #divToMeasure>
            <div class="single-path clickable" (click)="onAllPatientsRouteClick()">
                All patients
            </div>
            <div style="width: 8px"></div>
            <div class="single-path">
                >
            </div>
            <div style="width: 8px"></div>
            <div class="single-path">
                {{record.basicInfo.first_name + " " + record.basicInfo.last_name}}            
            </div>
        </div>
        <div class="title">
            Patient Card
        </div>
        <div class="main-content">
            <div class="sub-title-bar">
                <div class="nav-btn disabled">
                    <img class="nav-img" src="assets/img/arrow-left.svg" />
                </div>
                <div></div>
                <div class="nav-btn disabled">
                    <img class="nav-img" src="assets/img/arrow-right.svg" />
                </div>
                <div></div>
                <div class="date-range-grid">
                    <!--TODO: add control -->
                    <app-date-range-select *ngIf="selectShow.selectedOptionKey=='custom'" [dateFrom]="this.startDate" (dateFromChange)="updateStartDate($event)" [dateTo]="this.endDate" (dateToChange)="updateEndDate($event)" (btnCLick)="updateDateRange()"></app-date-range-select>
                </div>
                <!--<div></div>-->
                <app-select [optionTextStyle]="selectShow.optionTextStyle" [constantTextStyle]="selectShow.constantTextStyle" [options]="selectShow.options" [constantText]="selectShow.constantText" [(selectedOptionId)]="selectShow.selectedOptionKey" (selectedOptionIdChange)="onShowOptionChanged($event)"></app-select>
                <div></div>
                <div class="generate-btn disabled">
                    Generate report
                </div>
            </div>
            <div class="linear-widgets-container">
                <app-widget *ngFor="let widget of widgets; let i = index" [widthPx]="280" [widget]="widget"></app-widget>
                <app-widget [widthPx]="280" [widget]="alertsWidget" [alerts]="graphAlerts" (alertClick)="highlightAlert($event)"></app-widget>
            </div>
            <div class="sub-heading-grid">
                <div class="sub-heading">
                    Contralateral temperature and pressure differences
                </div>
                <div></div>
                <app-toggle [items]="footOptions" [selected]="footOptions[1]" (selectedChange)="onFootSelectedChange($event)" ></app-toggle>


            </div>
            <div class="main-separator"></div>
            <app-toggle #showToggleOptions style="width: 818px; align-self: center;" [items]="showOptions" [selected]="showOptions[0]" (selectedChange)="onShowSelectedChange($event)" ></app-toggle>

            <div class="heatmap-spinner-container float-start">
                <div *ngIf="(!spinnerService.isVisible.value)&&this.spinnerTriggerer==triggererEnum.dateRange&&this.pressureData.datasets[0].data.length===0&&this.pressureData.datasets[1].data.length===0" class="graph-pressure-api-msg text-center api-msg">No Data</div>

                <div *ngIf="spinnerService.isVisible.value&&this.spinnerTriggerer==triggererEnum.dateRange&&this.pressureData.datasets[0].data.length===0" class="spinner-border text-primary graph-pressure-spinner" role="status">
                   <span class="visually-hidden">Loading...</span>
                </div>

                <div *ngIf="(!spinnerService.isVisible.value)&&this.spinnerTriggerer==triggererEnum.dateRange&&this.temperatureData.datasets[0].data.length===0&&this.temperatureData.datasets[1].data.length===0" class="graph-temperature-api-msg text-center api-msg">No Data</div>

                <div *ngIf="spinnerService.isVisible.value&&this.spinnerTriggerer==triggererEnum.dateRange&&this.temperatureData.datasets[0].data.length===0" class="spinner-border text-primary graph-temperature-spinner" role="status">
                   <span class="visually-hidden">Loading...</span>
                </div>
            </div>

            <app-graph #pressureGraph style="width: 100%; height: 348px;" [graphData]="pressureData" [graphOptions]="pressureGraphOptions"></app-graph>
            <app-graph #temperatureGraph style="width: 100%; height: 348px;" [graphData]="temperatureData" [graphOptions]="temperatureGraphOptions"></app-graph>
            <div class="patient-history-title-bar">
                <div></div>
                <img class="patient-history-title-img" src="assets/img/recent-history.svg" />
                <div></div>
                <div class="patient-history-title-text">
                    Patient history ({{record.history.length}})
                </div>
                <div></div>
                <img class="patient-history-title-add" src="assets/img/add-orange.svg" (click)="addPatientVisit()"/>
            </div>
            <div class="table-header-line">
                <div></div>
                <div class="table-header">
                    TITLE
                </div>
                <div class="table-header">
                    DESCRIPTION
                </div>
                <div class="table-header">
                    DATE
                </div>
                <div class="table-header">
                    DURATION
                </div>
                <div class="table-header">
                    RISK LEVEL
                </div>
                <div class="table-header">
                    ATTACHMENTS
                </div>
                <div></div>
            </div>
            <div class="visit-list">
                <app-patient-history-entry *ngFor="let visit of record.history" [visit2]="visit" (deleteRecord)="onVisitDeleted($event)"></app-patient-history-entry>
            </div>
        </div>
    </div>
    <div class="patient-details-block">
        <div class="patient-details-title">
            Patient details
        </div>
        <div class="main-details-offset">
            <div class="main-details">
<!--                <img class="patient-image" [src]="record.basicInfo.imageBase64" />-->
                <img class="patient-image" [src]="record.basicInfo.imageBase64 ? 'data:image/jpg;base64,'+record.basicInfo.imageBase64 : 'assets/img/man.svg'" />
                <div>
                    <div class="patient-name">
                        {{record.basicInfo.first_name + " " + record.basicInfo.last_name}}
<!--                        {{summaryRecord.id? summaryRecord.first_name + " " + summaryRecord.last_name : record.basicInfo.first_name + " " + record.basicInfo.last_name}}-->
                    </div>
                    <div class="patient-sub-details">
                        {{record.basicInfo.gender[0].toUpperCase() + record.basicInfo.gender.substring(1).toLowerCase()}}, Age {{getAge()}}
                    </div>
                    <div class="patient-sub-details" style="color: #ff4f13;">
                        Patient ID {{record.basicInfo.patient_id}}
<!--                        Patient ID {{summaryRecord.id? summaryRecord.id : record.basicInfo.patient_id}}-->
                    </div>
                </div>
                <img class="edit-btn disabled" src="assets/img/edit-btn.svg" />
            </div>
            <div class="sub-details">
                <img class="sub-details-icon" src="assets/img/location-orange.svg" />
                <div class="sub-details-text">
                    {{record.basicInfo.address1}}, {{record.basicInfo.city}}, {{record.basicInfo.state}}
                </div>
                <img class="sub-details-icon" src="assets/img/insurer-orange.svg" />
                <div class="sub-details-text">
                    {{record.insurance.insurer}}, {{record.insurance.insurer_visits}} 
                </div>
                <img class="sub-details-icon" src="assets/img/email-orange.svg" />
                <div class="sub-details-text">
                    {{record.basicInfo.email}}
                </div>
                <img class="sub-details-icon" src="assets/img/call-orange.svg" />
                <div class="sub-details-text">
                    {{record.basicInfo.phone_number}}
                </div>
            </div>
        </div>
        <div class="patient-details-separator"></div>
        <div class="heatmap-container">
            <div class="heatmap-pressure">
                <div class="heatmap-title-bar">
                    <div class="heatmap-title">
                        Mean pressure
                    </div>
                    <div></div>
                    <div class="heatmap-date">
                        <div *ngIf="(this.startDate| date:'MMM d') == (this.endDate | date:'MMM d')">{{this.startDate | date:'MMM d'}}</div>
                        <div *ngIf="(this.startDate| date:'MMM d') != (this.endDate | date:'MMM d')">{{this.startDate | date:'MMM d'}} - {{this.endDate | date:'MMM d'}}</div>
<!-- 
                      {{this.startDate | date:'MMM d'}} - {{this.endDate | date:'MMM d'}}
                        {{this.startDate | date:'MMM YYYY'}}
   -->
                    </div>
                </div>
                <div class="records-selection-bar">
                    <app-select id="select-records" [optionTextStyle]="selectRecords.optionTextStyle" [constantTextStyle]="selectRecords.constantTextStyle" [options]="selectRecords.options" [constantText]="selectRecords.constantText" [(selectedOptionId)]="selectRecords.selectedOptionKey" (selectedOptionIdChange)="onRecordOptionChanged($event)" [drawerStyle]="selectRecords.drawerStyle" [selectDivStyle]="selectRecords.selectDivStyle" [selectedOptionId]=0></app-select>
                </div>
                <div class="heatmap-images-container">
                    <div class="heatmap-spinner-container float-start">
                        <div *ngIf="spinnerService.isVisible.value&&(this.spinnerTriggerer==triggererEnum.dateRange || this.spinnerTriggerer==triggererEnum.recordLeft)&&(!showVideoLeft || this.heatmap_pressure_left=='')" class="spinner-border text-primary heatmap-spinner-left" role="status">
                                <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    
<!--                    <div *ngIf="videoDataLeft.url=='' && this.heatmap_pressure_left==''" class="heatmap-image-left text-center">No Data</div>
    -->
                    <div *ngIf="this.heatmap_pressure_left_api_msg!=''" class="heatmap-image-left text-center api-msg-heatmap">{{heatmap_pressure_left_api_msg}}</div>
                    <video *ngIf="videoDataLeft.url!='' && showVideoLeft" muted autoplay id="video1" class="heatmap-video-left" [src]="videoDataLeft.url" controls></video>
                    <img *ngIf="this.heatmap_pressure_left!='' && (videoDataLeft.url=='' || !showVideoLeft) && heatmap_pressure_left_api_msg==''" class="heatmap-image-left" [src]="'data:image/jpeg;base64,' + this.heatmap_pressure_left" />
                    
<!--                    <div *ngIf="videoDataRight.url=='' && this.heatmap_pressure_right==''" class="heatmap-image-right text-center">{{heatmap_pressure_right_api_msg}}</div>
    -->
                    <div *ngIf="heatmap_pressure_right_api_msg!=''" class="heatmap-image-right text-center api-msg-heatmap">{{heatmap_pressure_right_api_msg}}</div>
                    <video *ngIf="videoDataRight.url!='' && showVideoRight" muted autoplay id="video1" class="heatmap-video-right" [src]="videoDataRight.url" controls></video>
                    <img *ngIf="this.heatmap_pressure_right!='' && (videoDataRight.url=='' || !showVideoRight) && heatmap_pressure_right_api_msg==''"  class="heatmap-image-right" [src]="'data:image/jpeg;base64,' + this.heatmap_pressure_right" />

                    <div class="heatmap-spinner-container float-start">
                        <div *ngIf="spinnerService.isVisible.value&&(this.spinnerTriggerer==triggererEnum.dateRange || this.spinnerTriggerer==triggererEnum.recordRight)&&(!showVideoRight || this.heatmap_pressure_right=='')" class="spinner-border text-primary heatmap-spinner-right" role="status">
                           <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                </div>

            </div>
            <div style="height: 21px;"></div>
            <div class="heatmap">
                <div class="heatmap-title-bar">
                    <div class="heatmap-title">
                        Mean temperature
                    </div>
                    <div></div>
                    <div class="heatmap-date">
                        <div *ngIf="(this.startDate| date:'MMM d') == (this.endDate | date:'MMM d')">{{this.startDate | date:'MMM d'}}</div>
                        <div *ngIf="(this.startDate| date:'MMM d') != (this.endDate | date:'MMM d')">{{this.startDate | date:'MMM d'}} - {{this.endDate | date:'MMM d'}}</div>
<!-- 
                        {{this.startDate | date:'MMM d'}} - {{this.endDate | date:'MMM d'}}
                        {{this.startDate | date:'MMM YYYY'}}
    -->
                    </div>
                </div>
                <div class="heatmap-images-container">
                    <div class="heatmap-spinner-container float-start">
                        <div *ngIf="spinnerService.isVisible.value&&this.spinnerTriggerer==triggererEnum.dateRange&&this.heatmap_temperature_left==''" class="spinner-border text-primary heatmap-spinner-left" role="status">
                           <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    <div *ngIf="this.heatmap_temperature_left_api_msg!=''" class="heatmap-image-left text-center api-msg-heatmap">{{heatmap_temperature_left_api_msg}}</div>
                    <img *ngIf="this.heatmap_temperature_left!=''" class="heatmap-image-left" [src]="'data:image/jpeg;base64,' + this.heatmap_temperature_left" />
                    
                    <div *ngIf="this.heatmap_temperature_right_api_msg!=''" class="heatmap-image-right text-center api-msg-heatmap">{{heatmap_temperature_right_api_msg}}</div>
                    <img *ngIf="this.heatmap_temperature_right!=''" class="heatmap-image-right" [src]="'data:image/jpeg;base64,' + this.heatmap_temperature_right" />

                    <div class="heatmap-spinner-container float-start">
                        <div *ngIf="spinnerService.isVisible.value&&this.spinnerTriggerer==triggererEnum.dateRange&&this.heatmap_temperature_right==''" class="spinner-border text-primary heatmap-spinner-right" role="status">
                           <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>