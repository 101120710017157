import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
  
export class Convertions {

    areaToSensorTemperature: {area: string, sensor: string}[] = [
        {area: 'heel', sensor: 't1'},
        //{area: 'arch', sensor: 't2'},
        {area: 'midFoot', sensor: 't2'},
        {area: 'bigToe', sensor: 't6'},
        {area: '1stMeta', sensor: 't5'},
        //{area: '2ndMeta', sensor: 't5'},
        //{area: '2ndMeta', sensor: 't4'},
        {area: '3-4thMeta', sensor: 't4'},
        {area: '5thMeta', sensor: 't3'},
    ]

    areaToSensorPressure: {area: string, sensor: string}[] = [
        {area: 'heel', sensor: 'p1'},
 /*
        {area: 'arch', sensor: 'p2'},
        {area: 'arch', sensor: 'p3'},
        {area: 'arch', sensor: 'p8'},
 */
        {area: 'archProx', sensor: 'p2'},
        {area: 'arch', sensor: 'p3'},
        {area: 'archDist', sensor: 'p8'},
        //{area: 'midFoot', sensor: 'p3'}, // Commented out since we use MAX() and ignore rest of sensors. This sensor is required for 'arch' area
        {area: 'midFoot', sensor: 'p5'},
        {area: 'midFoot', sensor: 'p6'},
        {area: 'midFoot', sensor: 'p7'},
        //{area: 'midFoot', sensor: 'p8'}, // Commented out since we use MAX() and ignore rest of sensors. This sensor is required for 'arch' area
        {area: 'bigToe', sensor: 'p14'},
        {area: '2ndToe', sensor: 'p15'},
        {area: '1stMeta', sensor: 'p13'},
        {area: '2ndMeta', sensor: 'p12'},
        {area: '3-4thMeta', sensor: 'p11'},
        {area: '3-4thMeta', sensor: 'p10'},
        {area: '5thMeta', sensor: 'p9'},
    ]

    getSensorsByAreaTemperature(area: string) :string[] {
        let result = this.getSensorsByArea(this.areaToSensorTemperature, area);
        return result;
    }

    getSensorsByAreaPressure(area: string) :string[] {
        let result = this.getSensorsByArea(this.areaToSensorPressure, area);
        return result;
    }

    getSensorsByArea(list: {area: string, sensor: string}[], area: string) :string[] {
        let result: string[] = [];
        let sub_list = list.filter(item => item.area == area);
        sub_list.forEach(item => result.push(item.sensor));
        return result;
    }

    getAreaBySensorTemperature(sensor: string) :string {
        let result = this.getAreaBySensor(this.areaToSensorTemperature, sensor);
        return result;
    }

    getAreaBySensorPressure(sensor: string) :string {
        let result = this.getAreaBySensor(this.areaToSensorPressure, sensor);
        return result;
   }

    getAreaBySensor(list: {area: string, sensor: string}[], sensor: string) :string {
        let result = list.find(record => record.sensor == sensor);
        if (result != undefined) return result.area;
        else return '';
    }

}