<div [ngClass]="containerClass">
    <div class="headline">
        <div></div>
    
        <app-checkbox style="margin-top: 30px;" [(checked)]="summaryRecord.isChecked"></app-checkbox>
        <div></div>
        <img class="patient-image" [src]="summaryRecord.imageBase64? 'data:image/jpg;base64,' + summaryRecord.imageBase64 : 'assets/img/man.svg'" (click)="onPatientClick()" />
        <div></div>
        <div class="value" (click)="onPatientClick()">
            {{(summaryRecord.last_name + ' ' + summaryRecord.first_name) | truncate:[16, '...']}}
        </div>
        <div class="active-image">
            <img [src]="summaryRecord.is_active ? 'assets/img/ic-check-circle.svg' : 'assets/img/ic-x-circle.svg'" />
        </div>
        <div></div>

        <div class="history-header">
            <div class="value">
                <button *ngIf="summaryRecord.last_visits.length > 0" class="expand-btn" (click)="isExpanded = !isExpanded">
                    <img [ngClass]="isExpanded ? 'cheveron-up' : 'cheveron-down'" src="assets/img/cheveron-full.svg" />
                </button>
            </div>
            <div class="value">
                {{summaryRecord.last_visits.length > 0 ? (summaryRecord.last_visits[0].description | truncate:[20, '...']) : '' }}
            </div>
            <div class="history-value-date" style="line-height: 80px; margin-left: 9px;">
                {{ summaryRecord.last_visits.length > 0 ? (summaryRecord.last_visits[0].visit_date_start | date: "MM/dd/YYYY") : ""}}
            </div>
        </div>
        <div></div>
    
        <div class="value" (click)="onPatientClick()">
            {{summaryRecord.patient_id}}
        </div>
        <div></div>
        <div class="value" (click)="onPatientClick()">
            <div [ngClass]="summaryRecord.insole_status ? 'active' : 'inactive'">
                {{summaryRecord.insole_status ? 'ACTIVE' : 'INACTIVE'}}
            </div>
            <div class="history-value-date" style="line-height: 80px; margin-left: 9px;">
                {{ lastActiveText}}
            </div>
        </div>
        <div></div>
        <div class="value" (click)="onPatientClick()">
            {{summaryRecord.authorization_date? summaryRecord.authorization_date.toLocaleDateString("en-US") : ''}}
        </div>
        <div></div>
        <div class="value">
            <app-record-menu [menuOptions]="patientMenuOptions" (menuSelected)="onMenuOptionSelected($event)"></app-record-menu>
        </div>
    </div>
    <div *ngIf="isExpanded" class="history-container">
<!--        <div *ngFor="let visit of summaryRecord.last_visits.slice(1)" [ngClass]="getHistoryRecordClass(visit)">-->
        <div *ngFor="let visit of summaryRecord.last_visits" [ngClass]="getHistoryRecordClass(visit)">
            <div></div>
            <div class="history-value">
                {{visit.title | truncate:[16, '...']}}
            </div>
            <div></div>
            <div class="history-value-desc">
                {{visit.description | truncate:[42, '...']}}
            </div>
            <div></div>
            <div class="history-value-date">
                {{visit.visit_date_start | date : "MM/dd/YYYY"}}
            </div>
        </div>
    </div>
</div>