<div style="position: relative;">
    <input [ngClass]="selected?'Input-Selected':'Input'" (blur)="selected=false" (focus)="selected=true" [placeholder]="placeholderText" [(ngModel)]="text" (ngModelChange)="valueChange($event)">
    <img src="/assets/img/combined-shape.svg"
    [ngClass]="selected?'search-Selected':'search'">
<!--    <div *ngFor="let result of searchResults" class="drawer-option" (click)="onOptionSelected(option)">-->
    <div *ngIf="searchResults.length" class="drawer">
        <div *ngFor="let result of searchResults" class="drawer-option">
            <div class="selected-text" (click)="onSearchSelected(result.id)">
                {{result.displayName}}
            </div>
        </div>
    </div>
</div>