import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Alert } from '../interfaces/alert';

@Component({
  selector: 'app-graph-alert',
  templateUrl: './graph-alert.component.html',
  styleUrls: ['./graph-alert.component.scss']
})
export class GraphAlertComponent implements OnInit {

  @Output() alertClick: EventEmitter<Alert> = new EventEmitter<Alert>();
  //@Input() alert: Alert = {graph_info: {foot_area:'heel', foot_side:'left', points:[]}, message:'', severity:'low', graph_type:'pressure'};
  @Input() alert: Alert = {foot_area:'heel', foot_side:'left', date: new Date(), value: 0, message:'', severity:'low', graph_type:'pressure'};
  
  constructor() { }

  ngOnInit(): void {
  }

  onAlertClick(alert: Alert) {
    console.log("graph-alert: onAlertClick");
    this.alertClick.emit(alert);
  }
}
