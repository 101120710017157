<div class="container">
    <div class="header">
        <div class="icon">
            <img [src]="data.icon">
        </div>
        <div class="title">
            Alerts
        </div>
        <div>
            <div *ngIf="alerts.length > 2" class="view_all">
                <div class="view_all_text">View all</div>
                <div class="expand_div">
                    <button class="expand-btn" (click)="onExpandClick()">
                        <img [ngClass]="expanded ? 'cheveron_down' : 'cheveron_right' " src="assets/img/cheveron-full.svg" />
                    </button>    
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="expanded ? 'alerts_div_expanded' : 'alerts_div'">
        <app-graph-alert *ngFor="let alert of alerts" [alert]="alert" (alertClick)="onAlertClicked($event)"></app-graph-alert>     
    </div>
</div>