<div [ngClass]="containerClass">
    <div class="headline">
        <div></div>
        <app-checkbox style="margin-top: 30px;" [(checked)]="displayRecord.isChecked"></app-checkbox>
        <div></div>
        <img class="patient-image" [src]="displayRecord.record.basicInfo.imageBase64" (click)="onPatientClick()" />
        <div></div>
        <div class="value" (click)="onPatientClick()">
            {{(displayRecord.record.basicInfo.first_name + ' ' + displayRecord.record.basicInfo.last_name) | truncate:[16, '...']}}
        </div>
        <div class="active-image">
            <img [src]="displayRecord.record.isActive ? 'assets/img/ic-check-circle.svg' : 'assets/img/ic-x-circle.svg'" />
        </div>
        <div></div>
        <div class="value">
            <button class="expand-btn" (click)="displayRecord.isExpanded = !displayRecord.isExpanded">
                <img [ngClass]="displayRecord.isExpanded ? 'cheveron-up' : 'cheveron-down'" src="assets/img/cheveron-full.svg" />
            </button>
            {{displayRecord.record.history[0].title | truncate:[20, '...']}}
        </div>
        <div></div>
        <div class="value" (click)="onPatientClick()">
            {{displayRecord.record.basicInfo.patient_id}}
        </div>
        <div class="value" (click)="onPatientClick()">
            <div [ngClass]="displayRecord.record.insoleStatus ? 'active' : 'inactive'">
                {{displayRecord.record.insoleStatus ? 'ACTIVE' : 'INACTIVE'}}
            </div>
            <div class="history-value-date" style="line-height: 80px; margin-left: 9px;">
                {{ lastActiveText}}
            </div>
        </div>
        <div></div>
        <div class="value" (click)="onPatientClick()">
            {{displayRecord.record.authorizationDate.toLocaleDateString("en-US")}}
        </div>
        <div></div>
        <div class="value">
            <button class="options-btn">
                <img class="options-btn-img" src="assets/img/menu.svg" />
            </button>
        </div>
    </div>
    <div *ngIf="displayRecord.isExpanded" class="history-container">
        <div *ngFor="let historyRecord of displayRecord.record.history.slice(1)" [ngClass]="getHistoryRecordClass(historyRecord)">
            <div></div>
            <div class="history-value">
                {{historyRecord.title | truncate:[16, '...']}}
            </div>
            <div></div>
            <div class="history-value-desc">
                {{historyRecord.description | truncate:[42, '...']}}
            </div>
            <div></div>
            <div class="history-value-date">
                {{historyRecord.visit_date_start.toLocaleDateString('en-US')}}
            </div>
        </div>
    </div>
</div>
