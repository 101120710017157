<div class="grid">
    <div class="date-div">
        From:
        <app-date-select [date]="dateFrom" (dateChange)="updateDateFrom($event)" class="date-element"></app-date-select>
    </div>
    <div class="date-div">
        To:
        <app-date-select [date]="dateTo" (dateChange)="updateDateTo($event)" class="date-element"></app-date-select>
    </div>
    <div class="btn-div">
        <button class="btn-update" (click)="onUpdateClick()">Update</button>
    </div>
</div>