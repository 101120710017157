import { Component, EventEmitter, HostListener, NgZone, OnInit, ViewChildren } from '@angular/core';
import { ContentComponent } from '../interfaces/content-component';
import { PatientDisplayRecord, PatientRecord, PatientSummary } from '../interfaces/patient-record';
import { SelectInterface } from '../interfaces/select-interface';
import { ApiService } from '../services/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SpinnerService } from '../services/spinner.service';
import { HttpClient } from '@angular/common/http';
import { PatientSummaryRecordComponent } from '../patient-summary-record/patient-summary-record.component';

@Component({
  selector: 'app-all-patients',
  templateUrl: './all-patients.component.html',
  styleUrls: ['./all-patients.component.scss']
})
export class AllPatientsComponent implements OnInit, ContentComponent {

  @ViewChildren(PatientSummaryRecordComponent) recordsViewer : PatientSummaryRecordComponent[] | null = null;

  @HostListener('document:click')
  closeMenu() {
    console.log("allPatientsComponent: closeMenu");
    if(this.recordsViewer!= undefined) this.recordsViewer.forEach((record)=> {
      if(record.summaryRecord.id!=this.lastClicked && record.menuViewer!= undefined) record.menuViewer.isDropdownOpen = false;
    })
    this.lastClicked = '';
  }


  numberOfPatients = 0;
  showSelectedOptionId = 'allPatients';
  selectShow : SelectInterface = {
    constantText : '',
    constantTextStyle: {},
    optionTextStyle: {},
    selectedOptionKey: {},
    options: [],
    drawerStyle: {},
    selectDivStyle: {}
  };
  selectSortBy : SelectInterface = {
    constantText : '',
    constantTextStyle: {},
    optionTextStyle: {},
    selectedOptionKey: {},
    options: [],
    drawerStyle: {},
    selectDivStyle: {}
  };
  selectAction : SelectInterface = {
    constantText : '',
    constantTextStyle: {},
    optionTextStyle: {},
    selectedOptionKey: {},
    options: [],
    drawerStyle: {},
    selectDivStyle: {}
  };
  newPatientButtonStyle = {
    'height': '48px',
    'width': '160px',
    'border-radius': '11px',
    'background-color': '#FF4F13'
  };
  newPatientButtonTextStyle = {
    'font-family': 'Poppins-SemiBold',
    'font-size': '14px',
    'color': '#ffffff',
    'vertical-align': 'middle',
    'margin-left': '17.5px',
    'margin-top': '14px'
  };
  newPatientButtonImageStyle = {
    'width': '16px',
    'height': '16px',
    'margin-left': '15.5px',
    'margin-top': '15.5px'
  };

  patientRecords : PatientSummary[] = [];
  showRecords: PatientSummary[] = [];
  currentIndex : number = 0;
  updateCount: number = 1;
  isFetching: boolean = false;
  moreToFetch: boolean = true;
  prevTerm: string = '';

  lastClicked: any = '';
  dashboardVersion: string = 'v1.0.11';
  backendVersion: string = 'v1.0.0';

  constructor(private apiService : ApiService, private zone: NgZone, private sanitizer: DomSanitizer, public spinnerService: SpinnerService,
    private httpClient: HttpClient) { }

  setData(data: any): void {
  }

  onPageMove: EventEmitter<{ key: string; data: any; }> = new EventEmitter<{ key: string; data: any; }>();

  ngOnInit(): void {
    //TODO: make API calls to get view options.
    this.selectShow = {
      constantText : 'Show: ',
      constantTextStyle : {
        //'font-family': 'Poppins-Bold' 
      },
      optionTextStyle: {
        'font-family': 'Poppins-Bold',
        'width': '140px'
      },
      selectedOptionKey: 'allPatients',
      options: [
        {
          key: 'allPatients', displayName: 'All patients'
        },
        {
          key: 'activePatients', displayName: 'Active patients'
        },
        {
          key: 'inactivePatients', displayName: 'Inactive patients'
        },
        /*{
          key: 'newPatients', displayName: 'New patients'
        }*/
      ],
      drawerStyle: {},
      selectDivStyle: {}
    }
    this.selectSortBy = {
      constantText : 'Sort by: ',
      constantTextStyle : {
        //'font-family': 'Poppins-Bold' 
      },
      optionTextStyle: {
        'font-family': 'Poppins-Bold',
        'width': '170px'
      },
      selectedOptionKey: 'az',
      options: [
        {
          key: 'az', displayName: 'A ➔ Z'
        },
        {
          key: 'za', displayName: 'Z ➔ A'
        },
        {
          key: 'authDate', displayName: 'Authorization date'
        },
        {
          key: 'lastCheckup', displayName: 'From last checkup'
        }
      ],
      drawerStyle: {},
      selectDivStyle: {}
    }
    this.selectAction = {
      constantText : 'Action',
      constantTextStyle : {
        //'font-family': 'Poppins-Bold' 
      },
      optionTextStyle: {
        'display': 'none'
      },
      selectedOptionKey: '',
      options: [
        {
          key: 'delete', displayName: 'Delete'
        }
      ],
      // Currently hardcoded disabled therefore following styling attributes
      drawerStyle: {'cursor':'default !important'},
      selectDivStyle: {'opacity':'0.38', 'cursor':'default'}
    }
    
    this.onInterval();
    //setInterval(this.onInterval, 100);
    //TODO: make API calls to get sort options.
  }

  onInterval = () => {
    this.zone.run(() => {
      //if(!this.isFetching
          this.isFetching = true;
          this.apiService.getPatients({show: this.selectShow.selectedOptionKey, sortBy: this.selectSortBy.selectedOptionKey, searchInput: '', startIndex: this.currentIndex, endIndex: this.currentIndex+this.updateCount}, (records) => {
            records.results.forEach(patient => {
              this.apiService.getPatientImage(patient.id, (image) => {
                patient.imageBase64 = image;
              });
              this.patientRecords.push(patient);
            })
            this.numberOfPatients = records.count;
            this.moreToFetch = records.next != null;

            this.isFetching = false;
            this.currentIndex+=this.updateCount;
            this.filterRecords(this.selectShow.selectedOptionKey);
            this.sortRecords(this.selectSortBy.selectedOptionKey);
          }, (err) =>{
            return;
          });
          this.apiService.getBackendVersion((version) => {
            this.backendVersion = version.backend_version;
          }, (err) =>{
            return;
          })
    });
  }
/*
 onInterval = () => {
    this.zone.run(() => {
      //if(!this.isFetching
      if(this.moreToFetch && !this.isFetching){
        let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
        let max = document.documentElement.scrollHeight;
        // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
        if(pos == max )   {
          this.isFetching = true;
          this.apiService.getPatients({show: this.selectShow.selectedOptionKey, sortBy: this.selectSortBy.selectedOptionKey, searchInput: '', startIndex: this.currentIndex, endIndex: this.currentIndex+this.updateCount}, (records) => {
            records.results.forEach(patient => {
              this.apiService.getPatientImage(patient.id, (image) => {
                patient.imageBase64 = image;
              });
              patient.is_active = patient.is_active || true;
              this.patientRecords.push(patient);
            })
            this.numberOfPatients = records.count;
            this.moreToFetch = records.next != null;

            this.isFetching = false;
            this.currentIndex+=this.updateCount;
            this.filterRecords(this.selectShow.selectedOptionKey);
            this.sortRecords(this.selectSortBy.selectedOptionKey);
          });
        }
      }
    });
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    this.zone.run(() => {
      //In chrome and some browser scroll is given to body tag
      let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
      let max = document.documentElement.scrollHeight;
      // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
      if(pos == max )   {
        this.isFetching = true;
        //TODO: modify getRecordsForSummary to getPatients when we have enough data or when we go live
        this.apiService.getRecordsForSummary({show: this.selectShow.selectedOptionKey, sortBy: this.selectSortBy.selectedOptionKey, searchInput: '', startIndex: this.currentIndex, endIndex: this.currentIndex+this.updateCount}, (records) => {
          this.patientRecords = this.patientRecords.concat(records);
          this.isFetching = false;
        });
        this.currentIndex+=this.updateCount;
      }
    });
  }
*/
  onShowOptionChanged($event: string){
    this.filterRecords($event);
  }

  onSortByOptionChanged($event: string){
    this.sortRecords($event);
  }

  onSearchTextChanged($event: string){
    //this.patientRecords = [];
    this.filterRecordsBySearchTerm($event);
  }

  onNewPatientClick(){
    this.onPageMove.emit({key: 'createNewPatient', data: undefined});
  }

  onActionSelected(){
    //TODO: make API call
  }

  onAllCheckToggle(checked : boolean){
    for(let i=0;i<this.patientRecords.length;i++){
      this.patientRecords[i].isChecked = checked;
    }
  }

  onSummaryRecordClicked($event: PatientSummary){
    this.onPageMove.emit({key: 'patientCard', data: {record: $event, list: this.patientRecords}});
  }

  onRecordClick($event: number) {
    this.lastClicked = $event;
  }
  onPatientMenuSelected($event: {menuOption: string, patientId: number}) {
    switch ($event.menuOption) {
      case "EDIT":
        this.onPageMove.emit({key: 'createNewPatient', data: {id: $event.patientId}});
        break;
      case "DELETE":
        this.deletePatient($event.patientId);
        break;
      default:
        break;
    }
  }

  deletePatient(id: number) {
    console.log("all-patients: onPatientMenuSelected: DELETE: ID = " + id);
    this.apiService.deletePatient(id, (success) => {
      console.log("all-patients: onPatientMenuSelected: deletePatient = " + success);
      // TODO: Check selected SHOW option to decide if to remove from list or just set its Active status
      //this.patientRecords.splice(this.patientRecords.findIndex(record => record.id == id),1);
      const recIndex = this.patientRecords.findIndex(record => record.id == id);
      this.patientRecords[recIndex].is_active = false;
      this.patientRecords[recIndex].insole_status = false;
      this.filterRecords(this.selectShow.selectedOptionKey);
    });

  }

  filterRecords(show: string) {
    switch(show) {
      case "allPatients":
        this.showRecords = this.patientRecords;
        break;
      case "activePatients":
        this.showRecords = this.patientRecords.filter(patient => patient.is_active == true);
        break;
      case "inactivePatients":
        this.showRecords = this.patientRecords.filter(patient => patient.is_active == false);
        break;
      /*case "newPatients":
        this.showRecords = this.patientRecords.filter(patient => patient.last_visits.length < 2);
        break;*/
      default:
        this.showRecords = this.patientRecords.filter(patient => patient.is_active == true); // Active patients
        break;
    }
  }

  filterRecordsBySearchTerm(term: string) {
    term = term.toUpperCase();
    if(term.length < this.prevTerm.length) this.filterRecords(this.selectShow.selectedOptionKey); // Deleted a character -> Set 'showRecords' list from all-patients list
    this.showRecords = this.showRecords.filter(patient => { if(patient.first_name.toUpperCase().includes(term) || patient.last_name.toUpperCase().includes(term) || patient.patient_id.includes(term)) return true; else return false;}); // Filter records from 'showRecords' list
    this.prevTerm = term;
  }

  sortRecords(sortBy: string) {
    switch(sortBy) {
      case "az": // A ➔ Z
        this.showRecords = this.showRecords.sort( (a, b) => a.last_name.localeCompare(b.last_name));
        break;
      case "za": // Z ➔ A
        this.showRecords = this.showRecords.sort( (a, b) => b.last_name.localeCompare(a.last_name));
        break;
      case "authDate": // Authorization date
        this.showRecords = this.showRecords.sort( (a, b) => (a.authorization_date != null ? new Date(a.authorization_date).valueOf() : 0) - (b.authorization_date != null ? new Date(b.authorization_date).valueOf() : 0));
        break;
      case "lastCheckup": // From last checkup
        this.showRecords = this.showRecords.sort( (a, b) => (new Date(a.last_visits[a.last_visits.length-1]?.visit_date_start).valueOf() |0) - (new Date(b.last_visits[b.last_visits.length-1]?.visit_date_start).valueOf() | 0));
        break;
      default:
        this.showRecords = this.showRecords.sort( (a,b) => a.last_name.localeCompare(b.last_name)); // A ➔ Z
        break;
    }
  }
}
