import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  @Input() constantText: string = '';
  @Output() selectedOptionIdChange: EventEmitter<string> = new EventEmitter<string>();
  //@Input() selectedOptionId: string = '';
  @Input() selectedOptionId: any = '';
  @Input() options: { key: any, displayName: string}[] = [];
  isDropdownOpen: boolean = false;
  @Input() constantTextStyle: any;
  @Input() optionTextStyle: any;
  @Input() displaySelectedOption: boolean = true;
  @Input() drawerStyle: any;
  @Input() selectDivStyle: any;
  @Input() selectDivImage: any;
  @Input() disabled: boolean = false;
  
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  get selectedOptionDisplayName(){
    return this.options.find(value => value.key == this.selectedOptionId)?.displayName ?? '';
  }

  constructor(private eRef: ElementRef) { }

  ngOnInit(): void {
  }

  onExpandClick(){
    if(this.disabled) return;
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onOptionSelected(option: { key: any, displayName: string}){
    if(option.key != this.selectedOptionId){
      this.selectedOptionId = option.key;
      this.selectedOptionIdChange.emit(this.selectedOptionId);
    }
    this.isDropdownOpen = false;
  }

}
