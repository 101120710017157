import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { TemporaryStorageService } from './temporary-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { LoginInfo, LoginAttempt } from '../interfaces/server-interface';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private apiService : ApiService, private cookieService: CookieService, private storageService : TemporaryStorageService, private router: Router) { }

  logout(){
    this.cookieService.delete('auth');
    //this.storageService.info = undefined;
    this.storageService.token = undefined;
    this.router.navigate(['/']);
  }

  checkAndLogin():boolean{
    var data = this.cookieService.get('auth');
    if(data != null){
      try{
        //this.storageService.info = JSON.parse(data) as LoginInfo;
        let token = JSON.parse(data) as string;
        //this.storageService.token = info.token;
        this.storageService.token = token;
      }catch{
        return false;
      }
      return true;
    }
    return false;
  }

  login(attempt: LoginAttempt, callback: (data: LoginInfo) => void, error: (err: any) => void){
    this.apiService.login(attempt, (data) =>{
      //this.storageService.info = data;
      //this.cookieService.set('auth', JSON.stringify(this.storageService.info), 0.5, '/');
      this.storageService.token = data.token;
      this.cookieService.set('auth', JSON.stringify(this.storageService.token), 0.5, '/');
      callback(data);
    }, error);
  }
}
