import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  isUserMenuOpen : boolean = false;
  @Output() searchInput: EventEmitter<string> = new EventEmitter<string>();
  @Input() searchResults: {displayName: string, id: any}[] = [];
  @Output() onSelected : EventEmitter<string> = new EventEmitter<string>();
  @Input() searchInputText: string = '';

  userMenuOptions: { key: any, displayName: string}[] = [ {key:"LOGOUT", displayName:"Logout"} ];
  fname: string = "";
  lname: string = "";

  constructor(private authService : AuthService, private api: ApiService) { }

  ngOnInit(): void {
    this.api.getPhysician((record) => {
      this.fname = record.first_name;
      this.lname = record.last_name;
    })
  }

  onSearchInput($event: string) {
    this.searchInput.emit($event);
  }

  onSearchSelected($event: string) {
    this.onSelected.emit($event)
  }

  onOptionSelected(option: {key: string, displayName: string}) {
    console.log("TopBarComponent: onOtionSelected: selected " + option.displayName);
    switch(option.key) {
      case "LOGOUT":
          this.authService.logout();
        break;
      default:
        break;
    }
  }
}
