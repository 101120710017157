import { AfterViewInit, Component, OnInit, ViewChild, Input, HostListener, HostBinding } from '@angular/core';
import { LeftMenuComponent } from '../left-menu/left-menu.component';
import { PatientCardComponent } from '../patient-card/patient-card.component';
import { ContentContainerComponent } from '../content-container/content-container.component';
import { TopBarComponent } from '../top-bar/top-bar.component';
import { PatientSummary } from '../interfaces/patient-record';

@Component({
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  //screenHeight: number;
  screenWidth: number = 1920;
  scale: number = 1;
  
  //@HostBinding('style.transform')
  //transform: string = 'scale(' + Math.min(this.screenWidth / 1920, 1) + ')'


  @ViewChild(LeftMenuComponent) leftMenu :LeftMenuComponent | undefined;
  
  
  @ViewChild(ContentContainerComponent) patientCardScreen: ContentContainerComponent | undefined;
  @ViewChild(TopBarComponent) topBar: TopBarComponent | undefined;
  topBarSearchResults: {displayName: string, id: any}[] = [];

  constructor() { 
    //this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    //this.transform = 'scale(' + Math.min(this.screenWidth / 1920, 1) + ')'
  }

  ngOnInit(): void {
    this.onResize();
  }

  onPageMove(event: { key: string, data: any}){
    if(this.leftMenu != undefined){
      this.leftMenu.selectByPageKey(event.key);
    }
  }

  onSearchInput($event: string) {
    console.log("mainComponent: on SearchInput: event = " + $event);
    if(this.patientCardScreen != undefined) {
      this.topBarSearchResults = [];
      if($event == '') return;
      $event = $event.toUpperCase();
      let patientCard = <PatientCardComponent>this.patientCardScreen.currentComponent;
      let results = patientCard.patientsList.filter(patient => {if (patient.first_name.toUpperCase().includes($event) || (patient.last_name.toUpperCase().includes($event))) return true; else return false;})
      results.forEach(record => {
        console.log(record.first_name + ' ' + record.last_name + '(' + record.patient_id + ')')
        this.topBarSearchResults.push({displayName: record.first_name + ' ' + record.last_name + ' (' + record.patient_id + ')', id:record.id})
      });
    }
  }

  onSearchSelected(id: any) {
    if(this.patientCardScreen != undefined) {
      let patientCard = <PatientCardComponent>this.patientCardScreen.currentComponent;
      let patientSummary: PatientSummary = patientCard.patientsList[patientCard.patientsList.findIndex(patient => patient.id == id)];
      this.topBarSearchResults = [];
      if(this.topBar != undefined) {
        this.topBar.searchInputText = '';
      }
      patientCard.onPageMove.emit({key: 'patientCard', data: {record: patientSummary, list: patientCard.patientsList}});
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    //this.screenHeight = window.innerHeight;
    //this.screenWidth = window.innerWidth;
    this.scale = Math.min(window.innerWidth / 1920, 1);
  }

}
