import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],

})
export class InputComponent implements OnInit, ControlValueAccessor {

  @Input() placeholderText: string | undefined;
  @Output() textChange : EventEmitter<string> = new EventEmitter<string>();
  @Input() text: string = '';
  @Input() cols: number = 40;
  @Input() rows: number = 1;
  @Input() customStyle: any;
  @Input() disabled: boolean = false;
  @Input() type: string = 'text';
  @Output() keyup: EventEmitter<any> = new EventEmitter();
  
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {}

  ngOnInit(): void {
    
  }

  onTextChange(event: any) {
    this.text = event.srcElement.value;

    this.onChange(event.srcElement.value);
  }

  writeValue(value: string): void {
    this.text = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  Onkeyup(event: any) {
    if(!this.disabled){
      this.keyup.emit(event);
    }  
  }
}
