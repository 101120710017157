import { Component, EventEmitter, OnInit } from '@angular/core';
import { ContentComponent } from '../interfaces/content-component';
import { PatientRecord, PatientBasic, PatientInsurance, PatientMedical } from '../interfaces/patient-record';
import { PatientVisit, PatientVisit2, PatientVisit2Request } from '../interfaces/patient-visit';
import { SelectInterface } from '../interfaces/select-interface';
import { ToggleComponentItem } from '../toggle/toggle.component';
import { ApiService } from '../services/api.service';
import { formatDate } from '@angular/common';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TimeInputTime } from 'src/app/time-input/time-input.component';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-create-new-patient',
  templateUrl: './create-new-patient.component.html',
  styleUrls: ['./create-new-patient.component.scss'],
})
export class CreateNewPatientComponent implements OnInit, ContentComponent {
  screenTitle: string = "Create new patient";

  patientBasic: PatientBasic = {
    id: 0,
    patient_id: '',
    first_name : '',
    last_name: '',
    address1:'',
    address2:'',
    city: '',
    //dob: new Date(),
    dob: formatDate(new Date(),'YYYY-MM-dd','en-us'),
    email: '',
    gender: 'male',
    phone_number: '',
    shoe_size: '8',
    state: '',
    zip_code: '',
    imageBase64: '',
    footedness: 'RIGHT'
  }

  patientInsurance: PatientInsurance = {
    insurer: '',
    insurer_visits: 12
  }

  patientMedical: PatientMedical = {
    relevant_background: '',
    feet_pathology: '',
    foot_ulcer: false
  }
    
  patientRecord : PatientRecord = {
    basicInfo: this.patientBasic,
    insurance: this.patientInsurance,
    medicalInfo: this.patientMedical,
    history: [],
    isActive : true,
    insoleStatus: false,
    lastActiveInsoleDate: new Date(),
    authorizationDate: new Date(),
  }

  patientFirstVisit: PatientVisit2 = {
    id: 0,
    title: '',
    description: '',
    risk_level: 'MID',
    visit_date_start: new Date(),
    visit_date_end: new Date(),
    user: 0
    //attachmentsPaths: [],
  }

  selectShoeSize : SelectInterface = {
    constantText : '',
    constantTextStyle: {},
    optionTextStyle: {},
    selectedOptionKey: {},
    options: [],
    drawerStyle: {},
    selectDivStyle: {}
  };

  selectNumberOfVisits : SelectInterface = {
    constantText : '',
    constantTextStyle: {},
    optionTextStyle: {},
    selectedOptionKey: {},
    options: [],
    drawerStyle: {},
    selectDivStyle: {}
  };

  singleLineStyle = {
    //'height': '35px'
    'height': 'inherit'
  };

  multiLineStyle = {
    'height': '81px'
  };

  savePatientButtonTextStyle = {
    'font-family': 'Poppins-Bold',
    'font-size': '20px',
    'color': '#ffffff',
    'vertical-align': 'middle',
    'margin-left': '50px',
    'margin-top': '2px',
  };


  genderOptions: ToggleComponentItem[] = [
    { value: "male", displayName: "MALE"},
    { value: "female", displayName: "FEMALE"},
    { value: "other", displayName: "OTHER"},
  ];

  riskLevelOptions: ToggleComponentItem[] = [
    { value: "HIGH", displayName: "HIGH"},
    { value: "MID", displayName: "MID"},
    { value: "LOW", displayName: "LOW"},
  ];

  login_form: FormGroup;

  submit_error: string = '';
  submit_clicked: boolean = false;
  
  startTime : TimeInputTime = { hours: this.patientFirstVisit.visit_date_start.getHours() > 11 ? this.patientFirstVisit.visit_date_start.getHours() - 12 : this.patientFirstVisit.visit_date_start.getHours(), minutes: this.patientFirstVisit.visit_date_start.getMinutes(), is24hour: false, isAm: this.patientFirstVisit.visit_date_start.getHours() < 11};
  endTime : TimeInputTime = { hours: this.patientFirstVisit.visit_date_end.getHours() > 11 ? this.patientFirstVisit.visit_date_end.getHours() - 12 : this.patientFirstVisit.visit_date_end.getHours(), minutes: this.patientFirstVisit.visit_date_end.getMinutes(), is24hour: false, isAm: this.patientFirstVisit.visit_date_end.getHours() < 11};
  selectedRisk: ToggleComponentItem = this.riskLevelOptions[1];

  sectionList: Array<{name: string, isChanged: boolean}> = [
    {name: "basicInfo", isChanged:false},
    {name: "image", isChanged:false},
    {name: "insurance", isChanged:false},
    {name: "medicalInfo", isChanged:false}
  ];

  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  constructor(private api: ApiService, private fb: FormBuilder) {
    this.login_form = fb.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      dob: [null, Validators.compose([Validators.pattern(/^((19|20|21)\d{2}([-]((0[1-9])|(1[0-2])))([-]((0[1-9])|([1-2]\d{1})|30|31)))$/)])],
      phone: [null, Validators.compose([Validators.required])],
      first_name: [null, Validators.compose([Validators.required])],
      last_name: [null, Validators.compose([Validators.required])],
      patient_id: [null, Validators.compose([Validators.required])]
    });
  }
  setData(data: any): void {
    if(data?.id != undefined) {
      this.screenTitle = "Update Patient Details"
      // Use APIs to load patient data
      this.api.getPatient(data.id, (basicInfo) => {
        if(basicInfo.gender == null) basicInfo.gender = "male";
        this.patientRecord.basicInfo = basicInfo;
        this.login_form.controls["phone"].setValue(basicInfo.phone_number);
        this.login_form.controls["first_name"].setValue(basicInfo.first_name);
        this.login_form.controls["last_name"].setValue(basicInfo.last_name);
        this.login_form.controls["patient_id"].setValue(basicInfo.patient_id);
        this.login_form.controls["dob"].setValue(basicInfo.dob);
        this.login_form.controls["email"].setValue(basicInfo.email);
        
      })
      this.api.getPatientImage(data.id, (image) => {
        this.patientRecord.basicInfo.imageBase64 = image;
      });
      // TODO: Consider to remove code loading Visits
      this.api.getVisits(data.id, (visits) => {
        visits.forEach(visit => {
          visit.visit_date_start = new Date(visit.visit_date_start);
          visit.visit_date_end = new Date(visit.visit_date_end);
        })
        if(visits.length>0) this.patientRecord.history[0] = visits[0];
        this.selectedRisk = this.riskLevelOptions[this.riskLevelOptions.findIndex(option => option.value == this.patientFirstVisit.risk_level)];
      })
      this.api.getInsurance(data.id, (insurance) => {
        this.patientRecord.insurance = insurance;
      })
      this.api.getMedicalInfo(data.id, (medicalInfo) => {
        this.patientRecord.medicalInfo = medicalInfo;
      })  
    }
  }
  onPageMove: EventEmitter<{ key: string; data: any; }> = new EventEmitter<{ key: string; data: any; }>();
  ngOnInit(): void {
    this.selectShoeSize = {
      constantText : '',
      constantTextStyle : {
        //'font-family': 'Poppins-Bold' 
      },
      optionTextStyle: {
        'font-family': 'Poppins-Bold',
        'width': '60px'
      },
      selectedOptionKey: '8',
      options: [
        {
          key: 4, displayName: '4'
        },{
          key: '4.5', displayName: '4.5'
        },{
          key: '5', displayName: '5'
        },{
          key: '5.5', displayName: '5.5'
        },{
          key: '6', displayName: '6'
        },{
          key: '6.5', displayName: '6.5'
        },{
          key: '7', displayName: '7'
        },{
          key: '7.5', displayName: '7.5'
        },{
          key: '8', displayName: '8'
        },{
          key: '8.5', displayName: '8.5'
        },{
          key: '9', displayName: '9'
        },{
          key: '9.5', displayName: '9.5'
        },{
          key: '10', displayName: '10'
        },{
          key: '10.5', displayName: '10.5'
        },{
          key: '11', displayName: '11'
        },{
          key: '11.5', displayName: '11.5'
        },{
          key: '12', displayName: '12'
        },{
          key: '13', displayName: '13'
        },{
          key: '14', displayName: '14'
        },{
          key: '15', displayName: '15'
        }
      ],
      drawerStyle: {},
      selectDivStyle: {}
    };

    this.selectNumberOfVisits = {
      constantText : '',
      constantTextStyle : {
        //'font-family': 'Poppins-Bold' 
      },
      optionTextStyle: {
        'font-family': 'Poppins-Bold',
        'width': '60px'
      },
      selectedOptionKey: '8',
      options: [
        { key: '1', displayName: '1' },
        { key: '2', displayName: '2' },
        { key: '3', displayName: '3' },
        { key: '4', displayName: '4' },
        { key: '5', displayName: '5' },
        { key: '6', displayName: '6' },
        { key: '7', displayName: '7' },
        { key: '8', displayName: '8' },
        { key: '9', displayName: '9' },
        { key: '10', displayName: '10' },
        { key: '11', displayName: '11' },
        { key: '12', displayName: '12' },
        { key: '13', displayName: '13' },
        { key: '14', displayName: '14' },
        { key: '15', displayName: '15' },
        { key: '16', displayName: '16' },
      ],
      drawerStyle: {},
      selectDivStyle: {}
    };
    
    this.patientFirstVisit.visit_date_end = new Date(new Date().getTime() + 30 * 60 * 1000);
  }

  isControlValid(name: string, attribute: string) : Boolean {
    let res = true;
    if(attribute == '' && name=="phone") res=this.login_form.controls[name].invalid && (this.login_form.controls['phone'].value!=null || this.submit_clicked) && (this.login_form.controls[name].dirty || this.login_form.controls[name].touched);
    else if(attribute == '') res=this.login_form.controls[name].invalid && (this.login_form.controls[name].dirty || this.login_form.controls[name].touched || this.submit_clicked);
    else res = this.login_form.controls[name].hasError(attribute) && (this.login_form.controls[name].dirty || this.login_form.controls[name].touched || this.submit_clicked);
    return res;
  }
  onSelectedChange($event: ToggleComponentItem){
    //this.patientRecord.gender = $event.value;
    this.patientRecord.basicInfo.gender = $event.value;
    this.markChangedSection("basicInfo");
  }

  onRiskLevelSelectedChange($event: ToggleComponentItem){
    this.patientFirstVisit.risk_level = $event.value;
  }

  getDateString(d : Date){
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    //return mo + "/" + da + "/" + ye;
    return ye + "-" + mo + "-" + da;
  }

  onDateOfBirthTextChange($event : string){
    //this.patientRecord.dateOfBirth = new Date($event);
    //this.patientRecord.basicInfo.dob = new Date($event);
    //this.patientRecord.basicInfo.dob = formatDate($event, 'YYYY-MM-dd', 'en-us');
    this.patientRecord.basicInfo.dob = $event;
  }

  onNumberOfVisitsChanged($event : string){
    //this.patientRecord.visits = parseInt($event);
    this.patientRecord.insurance.insurer_visits = parseInt($event);
  }

  onFirstVisitDateChange($event : Date){
    this.patientFirstVisit.visit_date_start.setDate($event.getDate());
    this.patientFirstVisit.visit_date_start.setMonth($event.getMonth());
    this.patientFirstVisit.visit_date_start.setFullYear($event.getFullYear());
  }

  onImageChanged(event: any) {
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.patientRecord.basicInfo.imageBase64 = reader.result as string;
      };
    }
    this.markChangedSection("image");
  }

  markChangedSection(sectionName: string) {
    this.sectionList[this.sectionList.findIndex(section => section.name == sectionName)].isChanged = true;
  }

  onSaveClick(){
    this.submit_error = '';
    this.submit_clicked = true;
    if(!this.login_form.valid) {
      this.submit_error = 'Form is not validate';
      // Clear message after 2 seconds
      setTimeout(() => {
        this.submit_error = '';
      }, 2000);
      return;
    }

    this.patientRecord.basicInfo.phone_number = this.login_form.controls["phone"].value["e164Number"];

    this.submit_clicked = false;
    if(this.patientRecord.basicInfo.id != 0) this.updatePatient(this.patientRecord.basicInfo.id);
    else this.addNewPatient();
  }

  addNewPatient() {
    this.api.addNewPatient(this.patientRecord.basicInfo, (response) => {
      console.log("Create_new_patient: addNewPatient: " + response.success)
      if(response.success) {
        this.patientRecord.basicInfo.id = response.id;
        this.callApisBySection(response.id, true);
        this.addFirstVisit(response.id);
        setTimeout(() => this.onPageMove.emit({key: 'patientCard', data: {record: this.patientRecord.basicInfo}}),200);
      }
      else {
        console.log("Could not create patient");
        this.submit_error = "Could not create patient. Message: " + response.error;
      }
    });
  }

  addFirstVisit(patientId: number) {
    this.patientFirstVisit.user = patientId;
    this.patientFirstVisit.visit_date_start.setHours(this.startTime.isAm? this.startTime.hours : this.startTime.hours+12);
    this.patientFirstVisit.visit_date_start.setMinutes(this.startTime.minutes);
    this.patientFirstVisit.visit_date_end.setFullYear(this.patientFirstVisit.visit_date_start.getFullYear());
    this.patientFirstVisit.visit_date_end.setMonth(this.patientFirstVisit.visit_date_start.getMonth());
    this.patientFirstVisit.visit_date_end.setDate(this.patientFirstVisit.visit_date_start.getDate());
    this.patientFirstVisit.visit_date_end.setHours(this.endTime.isAm? this.endTime.hours : this.endTime.hours+12);
    this.patientFirstVisit.visit_date_end.setMinutes(this.endTime.minutes);

    this.api.addVisit(this.getVisitRequestFromVisit(this.patientFirstVisit), (success) => {
      console.log("Create_new_patient: addFirstVisit: " + success)
    });
  }

  getVisitRequestFromVisit(visit: PatientVisit2) :PatientVisit2Request {
    let visitReq: PatientVisit2Request = {
      id: visit.id,
      user: visit.user,
      title: visit.title,
      description: visit.description,
      risk_level: visit.risk_level.toUpperCase(),
      visit_date_start: formatDate(visit.visit_date_start,'YYYY-MM-ddTHH:mmZ','en-us'),
      visit_date_end: formatDate(visit.visit_date_end,'YYYY-MM-ddTHH:mmZ','en-us')
    }

    return visitReq;
  }

  updatePatient(patientId: number) {
    this.callApisBySection(patientId, false);
    setTimeout(() => this.onPageMove.emit({key: 'patientCard', data: {record: this.patientRecord.basicInfo}}),200);
  }

  callApisBySection(patientId: number, newPatient: boolean) {
    if(patientId == 0) return;
    this.sectionList.forEach( section => {
      if(section.isChanged) {
        switch(section.name) {
          case "image":
            this.updatePatientPhoto(patientId)
            break;
          case "basicInfo":
            if(newPatient) break; // Already called addNewPatient API
            this.updatePatientBasic();
            break;
          case "insurance":
            this.updatePatientInsurance(patientId)
            break;
          case "medicalInfo":
            this.updatePatientMedical(patientId)
            break;
          default:
            console.log("create-new-aptient: callApisBySection: section " + section.name + " has no API call");
            break;
        }  
      }
    })
  }

  updatePatientBasic() {
    this.api.updatePatient(this.patientRecord.basicInfo, (response) => {
      console.log("Create_new_patient_ updatePatient: " + response)
    });
  }
  updatePatientPhoto(patientId: number) {
    this.api.uploadPatientPhoto(patientId, this.patientRecord.basicInfo.imageBase64, (response) => {
      console.log("Create_new_patient_ uploadPhoto: " + response)
    });
  }

  updatePatientInsurance(patientId: number) {
    this.api.updateInsurance(patientId, this.patientRecord.insurance, (response) => {
      console.log("Create_new_patient_ updateInsurance: " + response)
    });
  }

  updatePatientMedical(patientId: number) {
    this.api.updateMedicalInfo(patientId, this.patientRecord.medicalInfo, (response) => {
      console.log("Create_new_patient_ updateMedicalInfo: " + response)
    });
  }

}
