import { Component, Input, Output, OnInit, ViewChild, EventEmitter, Type, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges, ComponentRef } from '@angular/core';
import { ContentDirective } from '../directives/content.directive';
import { Alert } from '../interfaces/alert';
import { WidgetInterface } from './widget-interface';
import { WidgetItem } from './widget-item';
import { AlertsWidgetComponent } from './widgets/alerts-widget/alerts-widget.component';
import { GraphWidgetComponent } from './widgets/graph-widget/graph-widget.component';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class WidgetComponent implements OnInit {

  @Output() alertClick: EventEmitter<Alert> = new EventEmitter<Alert>();
  @Input() widthPx : number = 336;
  @Input() heightPx : number = 276;
  @Input() widget : WidgetItem = {
    component : GraphWidgetComponent,
    data: {}
  };
  backgroundColor: string = '';
  
  @ViewChild(ContentDirective, {static: true}) host!: ContentDirective;
  @Input() alerts: Alert[] = [];
  viewRefs: ComponentRef<WidgetInterface>[] = [];

  constructor(private changeDetect : ChangeDetectorRef) { }
  type: Type<any> = Object;
  
  ngOnInit(): void {
    this.backgroundColor = this.widget.data.backgroundColor;
    this.loadComponent();
  }

  loadComponent(){
    const viewContainerRef = this.host.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<WidgetInterface>(this.widget.component);
    componentRef.instance.data = this.widget.data;
    this.type = this.widget.component;
    //componentRef.instance.alertClick.subscribe( alert => {this.onAlertClicked(alert)})
    if(componentRef.componentType == AlertsWidgetComponent) {
      (componentRef.instance as AlertsWidgetComponent).alertClick.subscribe( alert => {this.onAlertClicked(alert)});
    }
    this.viewRefs.push(componentRef);
  }

  onAlertClicked(alert: Alert) {
    console.log("widget: onAlertClicked");
    this.alertClick.emit(alert);
  }

  public updateView() {
    this.viewRefs.forEach(view => {
      if(view.instance instanceof AlertsWidgetComponent){
        (view.instance as AlertsWidgetComponent).alerts = this.alerts;
        view.hostView.checkNoChanges();
      }else if(view.instance instanceof GraphWidgetComponent){
        (view.instance as GraphWidgetComponent).loadData();
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("widget-component: ngOnChanges");
  }

}
