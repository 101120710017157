import { Component, OnInit, EventEmitter } from '@angular/core';
import { CustomDialogConfig } from 'src/app/custom-dialog/custom-dialog-config';
import { CustomDialogRef } from 'src/app/custom-dialog/custom-dialog-ref';
import { CustomDialogInterface } from 'src/app/interfaces/custom-dialog-interface';
import { PatientRecord } from 'src/app/interfaces/patient-record';
import { PatientVisit, PatientVisit2, PatientVisit2Request } from 'src/app/interfaces/patient-visit';
import { ToggleComponentItem } from 'src/app/toggle/toggle.component';
import { ApiService } from 'src/app/services/api.service';
import { TimeInputTime } from 'src/app/time-input/time-input.component';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-new-visit-dialog',
  templateUrl: './new-visit-dialog.component.html',
  styleUrls: ['./new-visit-dialog.component.scss']
})
export class NewVisitDialogComponent implements OnInit, CustomDialogInterface  {

  visit: PatientVisit = {
    riskLevel: 'mid',
    attachmentsPaths: [],
    description: '',
    startTime: new Date(),
    endTime: new Date(),
    title: '',
  }

  visit2: PatientVisit2 = {
    id: 0,
    title: '',
    description: '',
    risk_level: 'MID',
    visit_date_start: new Date(),
    visit_date_end: new Date(),
    user: 0,
  }
  singleLineStyle = {
    //'height': '30px'
    'height': 'inherit'
  };

  multiLineStyle = {
    'height': '81px'
  };

  genderOptions: ToggleComponentItem[] = [
    { value: "male", displayName: "MALE"},
    { value: "female", displayName: "FEMALE"},
    { value: "other", displayName: "OTHER"},
  ];

  riskLevelOptions: ToggleComponentItem[] = [
    { value: "HIGH", displayName: "HIGH"},
    { value: "MID", displayName: "MID"},
    { value: "LOW", displayName: "LOW"},
  ];

  screenTitle: string = 'New visit record';
  btnText: string = 'Save';
  screenMode: string = 'EDIT';
  isDisabled: boolean = false;

  startTime : TimeInputTime = { hours: this.visit2.visit_date_start.getHours() > 11 ? this.visit2.visit_date_start.getHours() - 12 : this.visit2.visit_date_start.getHours(), minutes: this.visit2.visit_date_start.getMinutes(), is24hour: false, isAm: this.visit2.visit_date_start.getHours() < 11};
  endTime : TimeInputTime = { hours: this.visit2.visit_date_end.getHours() > 11 ? this.visit2.visit_date_end.getHours() - 12 : this.visit2.visit_date_end.getHours(), minutes: this.visit2.visit_date_end.getMinutes(), is24hour: false, isAm: this.visit2.visit_date_end.getHours() < 11};
  selectedRisk: ToggleComponentItem = this.riskLevelOptions[1];

  saveVisitButtonTextStyle = {
    'font-family': 'Poppins-Bold',
    'font-size': '20px',
    'color': '#ffffff',
    'vertical-align': 'middle',
    'margin-left': '50px',
    'margin-top': '2px',
  };


  //constructor(public config: CustomDialogConfig<{ record: PatientRecord }>, public dialog: CustomDialogRef, private api: ApiService) {
  constructor(public config: CustomDialogConfig<{ record: PatientVisit2, mode: string}>, public dialog: CustomDialogRef, private api: ApiService) {
    this.selectedRisk = this.riskLevelOptions[1];
    //if(this.config.data?.record.basicInfo.id != undefined) this.visit2.user = this.config.data?.record.basicInfo.id;
    if(this.config.data?.record.user != undefined) this.visit2.user = this.config.data?.record.user;
    if(this.config.data?.record.id != undefined) {
      this.visit2 = this.config.data?.record;
      this.screenTitle = 'Visit record';

      //this.btnText = 'Close';
      //this.screenMode = 'VIEW';
      //this.isDisabled = true;
      this.btnText = this.config.data?.mode=="VIEW"? "Close": "Save";
      this.screenMode = this.config.data?.mode;
      this.isDisabled = this.config.data?.mode=="VIEW";

      this.startTime = this.getTimeInput(this.visit2.visit_date_start);
      this.endTime = this.getTimeInput(this.visit2.visit_date_end);
      this.getRiskIndex(this.visit2.risk_level);
    }
  }

  getTimeInput(dt: Date): TimeInputTime {
    let timeObj: TimeInputTime = {
      hours: dt.getHours(),
      minutes: dt.getMinutes(),
      is24hour: false,
      isAm: true
    }

    if(timeObj.hours > 12) {
      timeObj.hours -= 12;
      timeObj.isAm = false;
    } 
    return timeObj;
  }

  getRiskIndex(search: string) {
    this.riskLevelOptions.forEach(x => {if(x.value.toString() == search) this.selectedRisk = x;});
  }

  onOverlayClicked(): void {
    this.dialog.close({valid: false, visit: this.visit});
  }
  getConfig(): CustomDialogConfig<any> {
    return this.config;
  }

  onPageMove: EventEmitter<{ key: string; data: any; }> = new EventEmitter<{ key: string; data: any; }>();
  
  ngOnInit(): void {
  }

  onFirstVisitDateChange($event : Date){
    this.visit2.visit_date_start.setDate($event.getDate());
    this.visit2.visit_date_start.setMonth($event.getMonth());
    this.visit2.visit_date_start.setFullYear($event.getFullYear());
  }

  onStartChange($event: Date)
  {
    this.visit2.visit_date_start = $event;
  }

  onEndChange($event: any)
  {
    this.visit2.visit_date_end = $event;  
  }

  onRiskLevelSelectedChange($event: ToggleComponentItem){
    this.visit2.risk_level = $event.value;
  }

  onSaveNewVisitClick() {
    
    //TODO: Implement saving Visit and add to patient's visit list
    if(this.screenMode == 'VIEW') { this.closeDialog(); return;}

    this.visit2.visit_date_start.setHours(this.startTime.isAm? this.startTime.hours : this.startTime.hours+12);
    this.visit2.visit_date_start.setMinutes(this.startTime.minutes);
    this.visit2.visit_date_end.setFullYear(this.visit2.visit_date_start.getFullYear());
    this.visit2.visit_date_end.setMonth(this.visit2.visit_date_start.getMonth());
    this.visit2.visit_date_end.setDate(this.visit2.visit_date_start.getDate());
    this.visit2.visit_date_end.setHours(this.endTime.isAm? this.endTime.hours : this.endTime.hours+12);
    this.visit2.visit_date_end.setMinutes(this.endTime.minutes);

    let visitReq: PatientVisit2Request = {
      id: this.visit2.id,
      user: this.visit2.user,
      title: this.visit2.title,
      description: this.visit2.description,
      risk_level: this.visit2.risk_level.toUpperCase(),
      visit_date_start: formatDate(this.visit2.visit_date_start,'YYYY-MM-ddTHH:mmZ','en-us'),
      visit_date_end: formatDate(this.visit2.visit_date_end,'YYYY-MM-ddTHH:mmZ','en-us')
    }

    if(visitReq.id == 0) {
      this.api.addVisit(visitReq, (success) => {
        if(success) {this.onPageMove.emit({key: 'patientCard', data: {record: this.visit2.user}});
      }});
    }
    else {
      this.api.updateVisit(visitReq, (success) => {
        if(success) {this.onPageMove.emit({key: 'patientCard', data: {record: this.visit2.user}});
      }});
    }
    this.closeDialog(this.visit2);
    //this.dialog.close(this.visit2);
  }

  closeDialog(result?: any) {
    this.dialog.close(result);
  }

}
