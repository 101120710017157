import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-multiline-input',
  templateUrl: './multiline-input.component.html',
  styleUrls: ['./multiline-input.component.scss']
})
export class MultilineInputComponent implements OnInit {

  @Input() placeholderText: string | undefined;
  @Output() textChange : EventEmitter<string> = new EventEmitter<string>();
  @Input() text: string = '';
  @Input() cols: number = 40;
  @Input() rows: number = 1;
  @Input() customStyle: any;
  @Input() disabled: boolean = false;
  
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {}

  ngOnInit(): void {
    
  }

  onTextChange(event: any) {
    this.text = event.srcElement.value;

    this.onChange(event.srcElement.value);
  }

  writeValue(value: string): void {
    this.text = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
