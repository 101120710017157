import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { MainComponent } from './main/main.component';
import { SubMenuItemComponent } from './sub-menu-item/sub-menu-item.component';
import { LeftMenuItemComponent } from './left-menu-item/left-menu-item.component';
import { PlaceholderComponent } from './placeholder/placeholder.component';
import { ContentContainerComponent } from './content-container/content-container.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContentDirective } from './directives/content.directive';
import { AllPatientsComponent } from './all-patients/all-patients.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { SelectComponent } from './select/select.component';
import { ButtonComponent } from './button/button.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { PatientRecordComponent } from './patient-record/patient-record.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CreateNewPatientComponent } from './create-new-patient/create-new-patient.component';
import { InputComponent } from './input/input.component';
import { ToggleComponent } from './toggle/toggle.component';
import { DateSelectComponent } from './date-select/date-select.component';
import { CalendarComponent } from './calendar/calendar.component';
import { TimeInputComponent } from './time-input/time-input.component';
import { TimeRangeInputComponent } from './time-range-input/time-range-input.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { PatientCardComponent } from './patient-card/patient-card.component';
import { WidgetComponent } from './widget/widget.component';
import { GraphComponent } from './graph/graph.component';
import { CustomDialogComponent } from './custom-dialog/custom-dialog.component';
import { NewVisitDialogComponent } from './dialogs/new-visit-dialog/new-visit-dialog.component';
import { CustomDialogInsertionDirective } from './directives/custom-dialog-insertion.directive';
import { PatientHistoryEntryComponent } from './patient-history-entry/patient-history-entry.component';
import { NgChartsModule } from 'ng2-charts';

import { Chart } from 'chart.js';
import { GraphWidgetComponent } from './widget/widgets/graph-widget/graph-widget.component';
import { AlertsWidgetComponent } from './widget/widgets/alerts-widget/alerts-widget.component';
import { PatientSummaryRecordComponent } from './patient-summary-record/patient-summary-record.component';
import { GraphAlertComponent } from './graph-alert/graph-alert.component';
import { DateRangeSelectComponent } from './date-range-select/date-range-select.component';
import { RecordMenuComponent } from './record-menu/record-menu.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CustomHttpInterceptor } from './http-interceptor';
import { LoginComponent } from './login/login.component';
import { CookieService } from 'ngx-cookie-service';
import { MultilineInputComponent } from './multiline-input/multiline-input.component';

@NgModule({
  declarations: [
    AppComponent,
    LeftMenuComponent,
    MainComponent,
    SubMenuItemComponent,
    LeftMenuItemComponent,
    PlaceholderComponent,
    ContentContainerComponent,
    ContentDirective,
    AllPatientsComponent,
    TopBarComponent,
    SearchInputComponent,
    SelectComponent,
    ButtonComponent,
    CheckboxComponent,
    PatientRecordComponent,
    TruncatePipe,
    CreateNewPatientComponent,
    InputComponent,
    ToggleComponent,
    DateSelectComponent,
    CalendarComponent,
    TimeInputComponent,
    TimeRangeInputComponent,
    AttachmentsComponent,
    PatientCardComponent,
    WidgetComponent,
    GraphComponent,
    CustomDialogComponent,
    NewVisitDialogComponent,
    CustomDialogInsertionDirective,
    PatientHistoryEntryComponent,
    GraphWidgetComponent,
    AlertsWidgetComponent,
    PatientSummaryRecordComponent,
    GraphAlertComponent,
    DateRangeSelectComponent,
    RecordMenuComponent,
    LoginComponent,
    MultilineInputComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgChartsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: CustomHttpInterceptor,
    multi: true
  },
  CookieService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
