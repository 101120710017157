<div class="title-bar">
    <div class="title">
        All patients
    </div>
    <div class="title-number">
        ({{numberOfPatients}})
    </div>
    <div style="width: 1fr;"></div>
    <app-select [optionTextStyle]="selectShow.optionTextStyle" [constantTextStyle]="selectShow.constantTextStyle" [options]="selectShow.options" [constantText]="selectShow.constantText" [(selectedOptionId)]="selectShow.selectedOptionKey" (selectedOptionIdChange)="onShowOptionChanged($event)"></app-select>
    <div></div>
    <app-select [optionTextStyle]="selectSortBy.optionTextStyle" [constantTextStyle]="selectSortBy.constantTextStyle" [options]="selectSortBy.options" [constantText]="selectSortBy.constantText" [(selectedOptionId)]="selectSortBy.selectedOptionKey" (selectedOptionIdChange)="onSortByOptionChanged($event)"></app-select>
    <div></div>
    <app-button (onClick)="onNewPatientClick()" [buttonStyle]="newPatientButtonStyle" [textStyle]="newPatientButtonTextStyle" [imageStyle]="newPatientButtonImageStyle" imageSrc="assets/img/plus.svg" text="New patient"></app-button>
</div>
<div class="search-bar">
    <app-select [optionTextStyle]="selectAction.optionTextStyle" [constantTextStyle]="selectAction.constantTextStyle" [options]="selectAction.options" [constantText]="selectAction.constantText" [(selectedOptionId)]="selectAction.selectedOptionKey" (selectedOptionIdChanged)="onActionSelected()" [disabled]="true" [selectDivStyle]="selectAction.selectDivStyle"></app-select>
    <div></div>
    <app-search-input placeholderText="Search by name or ID" (textChange)="onSearchTextChanged($event)"></app-search-input>
</div>
<div style="margin-top: 11px; display: grid; grid-template-columns: 1fr;">
    <div class="separator"></div>
</div>  
<div class="table-header-line">
    <div></div>
    <app-checkbox (checkedChange)="onAllCheckToggle($event)"></app-checkbox>
    <div></div>
    <div class="table-header">
        PATIENT NAME
    </div>
    <div></div>
    <div class="table-header">
        IS ACTIVE
    </div>
    <div></div>
    <div class="table-header">
        PATIENT HISTORY
    </div>
    <div></div>
    <div class="table-header">
        ID
    </div>
    <div></div>
    <div class="table-header">
        INSOLES STATUS
    </div>
    <div></div>
    <div class="table-header">
        AUTHORIZATION DATE
    </div>
    <div></div>
</div>
<div class="table">
    <div class="spinner-container float-start">
        <div *ngIf="spinnerService.isVisible.value&&this.showRecords.length == 0" class="spinner-border text-primary main-spinner" role="status">
           <span class="visually-hidden">Loading...</span>
        </div>
    </div>

    <div *ngFor="let record of showRecords">
        <div style="height: 5px;"></div>
        <app-patient-summary-record [summaryRecord]="record" (actionClick)="onSummaryRecordClicked($event)" (actionMenuSelected)="onPatientMenuSelected($event)" (recordClick)="onRecordClick($event)"></app-patient-summary-record>
        <div style="height: 5px;"></div>
    </div>
</div>

<div class="footer">
    <div></div>
    <div>
        <div class="separator"></div>
        <div class="version-text disabled">{{this.dashboardVersion}} / {{this.backendVersion}}</div>
    </div>
    <div></div>
</div>
