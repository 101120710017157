import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit, AfterViewInit {

  @ViewChild(BaseChartDirective) chart : BaseChartDirective | undefined;

  @Input() graphData : ChartData = {
    datasets: [{
      data: []
    }]
  };

  @Input() graphOptions : ChartOptions = {
    maintainAspectRatio: false
  };

  @Input() width: number = 1000;

  constructor() { }
  ngAfterViewInit(): void {
    this.update();
  }

  ngOnInit(): void {
  }

  update(){
    this.chart?.chart?.update();
  }

}
