import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MenuItem } from '../entities/menu-item';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

  patientsCount: number = 0;
  @Output() pageChange = new EventEmitter<string>();

  menu: MenuItem[] = [
    {
      icon: "assets/img/combined-shape-2.svg",
      title: "Overview",
      selected: false,
      page: "overview",
    },{
      icon: "assets/img/ic-dashboard-8.svg",
      title: "Patients",
      selected: true,
      items:[
        {
          title: `All patients(${this.patientsCount})`,
          selected: true,
          page: "patients",
        },
        {
          title: "Create new patient",
          selected: false,
          page: "createNewPatient",
        }
      ]
    },{
      icon: "assets/img/ic-dashboard-4.svg",
      title: "Messages",
      selected: false,
      page: "messages",
    },{
      icon: "assets/img/ic-dashboard-7.svg",
      title: "Administration",
      selected: false,
      page: "administration",
    },{
      icon: "assets/img/ic-dashboard-6.svg",
      title: "Media library",
      selected: false,
      page: "mediaLibrary",
    },{
      icon: "assets/img/ic-dashboard-5.svg",
      title: "Schedule",
      selected: false,
      page: "schedule",
    }
  ];

  helpMenu : MenuItem[] = [{
      icon: "assets/img/ic-dashboard-3.svg",
      title: "Knowledge base",
      selected: false,
      page: "knowledgeBase",
    },{
      icon: "assets/img/ic-dashboard-2.svg",
      title: "Settings",
      selected: false,
      page: "settings",
    },{
      icon: "assets/img/ic-dashboard.svg",
      title: "Support",
      selected: false,
      page: "support",
    }
  ];

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.api.getPatients({show: 'allPatients', sortBy: 'az', searchInput: '', startIndex: 1, endIndex: 1}, (records) => {
      this.patientsCount = records.count;
      this.menu.forEach(subMenu => {
        subMenu.items?.forEach(item => {
          if(item.page == "patients") item.title = 'All patients(' + this.patientsCount + ')';
        })
      })
    }, (error) => {});
  }

  selectMenuItems(currentItem : MenuItem, selectedItem : MenuItem) : boolean{
    if(currentItem == selectedItem){
      currentItem.selected = true;
      return true;
    }
    let found = false;
    if(currentItem.items != undefined){
      for (const menuItem of currentItem.items){
        found = this.selectMenuItems(menuItem, selectedItem) || found;
      }
    }
    currentItem.selected = found;
    return found;
  }

  selectMenuItemsByPage(currentItem : MenuItem, page : string) : boolean{
    if(currentItem.page == page){
      currentItem.selected = true;
      if(page == "patients") {
        this.api.getPatients({show: 'allPatients', sortBy: 'az', searchInput: '', startIndex: 1, endIndex: 1}, (records) => {
          this.patientsCount = records.count;
          currentItem.title = 'All patients(' + this.patientsCount + ')';
        }, (error) => {})
      }
  
      return true;
    }
    let found = false;
    if(currentItem.items != undefined){
      for (const menuItem of currentItem.items){
        found = this.selectMenuItemsByPage(menuItem, page) || found;
      }
    }
    currentItem.selected = found;
    return found;
  }

  onMenuItemClick($event: MenuItem){
    for (const menuItem of this.menu.concat(this.helpMenu)){
      this.selectMenuItems(menuItem, $event);
    };
    this.pageChange.emit($event.page);
  }

  selectByPageKey(key: string){
    for (const menuItem of this.menu.concat(this.helpMenu)){
      this.selectMenuItemsByPage(menuItem, key);
    };
  }
}
