<div class="main" [style.transform]="'scale(' + scale + ')'">
    <div></div>
    <div>
        <app-left-menu (pageChange)="contentContainer.loadComponent($event, undefined)"></app-left-menu>
    </div>
    <div class="sub-main">
        <app-top-bar style="flex-shrink: 0;" (searchInput)="onSearchInput($event)" [searchResults]="topBarSearchResults" (onSelected)="onSearchSelected($event)"></app-top-bar>
        <app-content-container style="flex-grow: 1;" (onPageMove)="onPageMove($event)" #contentContainer></app-content-container>
    </div>
    <div></div>
</div>
