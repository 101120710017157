<div class="line">
    <div class="container">
        <div class="digit-container">
            <input type="number" #timedigit maxlength="1" class="text" [(ngModel)]="timeFirstDigit" (click)="onDigitClick($event, 0)" (keyup)="onDigit($event, 0)" (keydown)="onFirstDigitKeyDown($event)" [disabled]="disabled">
        </div>
        <div class="digit-container">
            <input type="number" #timedigit maxlength="1" class="text" [(ngModel)]="timeSecondDigit" (click)="onDigitClick($event, 1)" (keyup)="onDigit($event, 1)" (keydown)="onSecondDigitKeyDown($event)" [disabled]="disabled">
        </div>
        <div class="colon">:</div>
        <div class="digit-container">
            <input type="number" #timedigit maxlength="1" class="text"  [(ngModel)]="timeThirdDigit" (click)="onDigitClick($event, 2)" (keyup)="onDigit($event, 2)" (keydown)="onThirdDigitKeyDown($event)" [disabled]="disabled">
        </div>
        <div class="digit-container">
            <input type="number" #timedigit maxlength="1" class="text" [(ngModel)]="timeForthDigit" (click)="onDigitClick($event, 3)" (keyup)="onDigit($event, 3)" (keydown)="onForthDigitKeyDown($event)" [disabled]="disabled">
        </div>
    </div>
    <div *ngIf="!is24hour" class="ampm-container">
        <app-toggle [isVertical]="true"[items]="amPmOptions" [selected]="currentAmPmOption" (selectedChange)="onSelectedChange($event)" [disabled]="disabled"></app-toggle>
    </div>
</div> 

