import { Component, Input, Output, OnInit, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges, Type } from '@angular/core';
import { WidgetInterface } from '../../widget-interface';
import { Alert } from 'src/app/interfaces/alert';
import { WidgetItem } from '../../widget-item';
import { GraphWidgetComponent } from '../graph-widget/graph-widget.component';

@Component({
  selector: 'app-alerts-widget',
  templateUrl: './alerts-widget.component.html',
  styleUrls: ['./alerts-widget.component.scss'],
})
export class AlertsWidgetComponent implements OnInit, WidgetInterface {

  @Output() alertClick: EventEmitter<Alert> = new EventEmitter<Alert>();

  @Input() data: any;
  @Input() alerts: Alert[] = [];

  @Input() widthPx : number = 336;
  @Input() heightPx : number = 276;
  @Input() widget : WidgetItem = {
    component : GraphWidgetComponent,
    data: {}
  };
  backgroundColor: string = '';
  expanded: boolean = false;

  constructor() { }
  type: Type<any> = AlertsWidgetComponent;

  ngOnInit(): void {
    //this.loadData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateAlerts();
  }

  loadData(){
    //this.data.getData((alerts : Alert[]) =>{
    //  this.alerts = alerts;
    //});
    //this.updateAlerts();
  }

  onExpandClick() {
    console.log("alerts-widget: onExpandClicked");
    this.expanded = this.expanded ? false : true;
  }
  onAlertClicked($event: Alert) {
    console.log("alerts-widget: onAlertClicked");
    this.alertClick.emit($event);
  }

  public updateAlerts() {
    //this.changeDetect.markForCheck(); //.detectChanges();
  }
}
