<div class="container">
    <div class="icon">
        <img [src]="data.icon">
    </div>
<!--    <app-graph #pressureGraph style="width: 100%; height: 100%;" [graphData]="graphData" [graphOptions]="graphOptions" [width]="widgetWidth"></app-graph>
-->
    <app-graph #graph style="width: 100%; height: 100%;" [graphData]="graphData" [graphOptions]="graphOptions" [width]="widgetWidth"></app-graph>
    <div>
        <div class="value">
            {{data.calculateValue(dataPoints)}}
        </div>
        <div class="title">
            {{data.title}}
        </div>
    </div>
</div>