import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoremIpsum } from 'lorem-ipsum';
import { uniqueNamesGenerator, Config, names } from 'unique-names-generator';

@Injectable({
  providedIn: 'root'
})
export class GeneratorService {
  generateSimpleRandomDatesArray(startDate: Date, stopDate: Date) {
    var dateArray = [];
    var currentDate = new Date(startDate);
    var sleepPeriodHoursMax = 48;
    var sleepPeriodHoursMin = 24;
    currentDate.setTime(currentDate.getTime() + Math.floor(this.generateNumber(sleepPeriodHoursMin * 60 * 60 * 1000, sleepPeriodHoursMax * 60 * 60 * 1000))); //add time within 2 hours.
    var addPoint : boolean = true;
    while (currentDate <= stopDate) {
        if(addPoint){
          dateArray.push(new Date (currentDate));
          addPoint = false;
        }else{
          currentDate.setTime(currentDate.getTime() + Math.floor(this.generateNumber(sleepPeriodHoursMin * 60 * 60 * 1000, sleepPeriodHoursMax * 60 * 60 * 1000))); //add time within 2 hours.
          addPoint = true;
        }
    }
    return dateArray;
  }

  generateComplexRandomDatesArray(startDate: Date, stopDate: Date) {
    var dateArray = [];
    var currentDate = new Date(startDate);
    var sleepPeriodHoursMax = 20;
    var sleepPeriodHoursMin = 4;
    var activePeriodSamplingRateMs = 30000;
    var activePeriodMaxMs = 30 * 60 * 1000;
    var activePeriodMinMs = 2 * 60 * 1000;
    currentDate.setTime(currentDate.getTime() + Math.floor(this.generateNumber(sleepPeriodHoursMin * 60 * 60 * 1000, sleepPeriodHoursMax * 60 * 60 * 1000))); //add time within 2 hours.
    var passedMs = 0;
    var targetMs = 15 * 60 * 1000;
    while (currentDate <= stopDate) {
        dateArray.push(new Date (currentDate));
        if(passedMs < targetMs){
          currentDate.setTime(currentDate.getTime() + activePeriodSamplingRateMs); //add sample
          passedMs += activePeriodSamplingRateMs;
        }else{
          currentDate.setTime(currentDate.getTime() + Math.floor(this.generateNumber(sleepPeriodHoursMin * 60 * 60 * 1000, sleepPeriodHoursMax * 60 * 60 * 1000))); //add time within 2 hours.
          passedMs = 0;
          targetMs = Math.floor(this.generateNumber(activePeriodMinMs, activePeriodMaxMs));
        }
    }
    return dateArray;
  }

  customConfig: Config = {
    dictionaries: [names],
    length: 1,
  };

  constructor(private httpClient : HttpClient) { }

  generateName(){
    return uniqueNamesGenerator(this.customConfig);
  }

  generateDate(start: Date, end: Date, startHour?: number, endHour?: number, startMinute?: number, endMinute?: number) {
    var date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    if(startHour != undefined && endHour != undefined){
      var hour = startHour + Math.random() * (endHour - startHour) | 0;
      date.setHours(hour);
    }
    if(startMinute != undefined && endMinute != undefined){
      var minutes = startMinute + Math.random() * (endMinute - startMinute) | 0;
      date.setMinutes(minutes);
    }
    return date;
  }

  generateNumber(start: number, end: number){
    return Math.random() * (end - start) + start;
  }

  generateBoolean(){
    return Math.random() > 0.5;
  }

  generateChoice(...args:any[]){
    if(args.length == 0){
      return undefined;
    }
    return args[Math.floor(Math.random() * args.length)];
  }

  generateLoremIpsum(config : {
    sentencesPerParagraph: {
      max: number,
      min: number
    },
    wordsPerSentence: {
      max: number,
      min: number
    }
  }, paragraphs: number) {
    return new LoremIpsum(config).generateParagraphs(paragraphs);
  }

  private toDataURL(url : string, callback : (imageBase64 : string | ArrayBuffer) => void) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result ?? '');
      }
      reader.readAsDataURL(xhr.response);
      //callback(URL.createObjectURL(xhr.response));
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.withCredentials = true;
    xhr.send();
  }

  generateFaceBase64(callback : (imageBase64 : string | ArrayBuffer) => void){
    this.toDataURL("/generate/image", callback);
  }

  generateRandomArray(count : number, minValue: number, maxValue: number){
    let retVal = [];
    for(let i=0; i< count; i++){
      retVal.push(Math.floor(this.generateNumber(minValue, maxValue)));
    }
    return retVal;
  }
}
