import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Output() checkedChange : EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() checked : boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
