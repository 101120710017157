<div class="Container">
    <div class="Month" [@transparentOpaque]="transparentOpaque">
        {{ monthYear }}
    </div>
    <div class="NextPreviousMonth NextMonth" (click)="onPreviousMonth()">
        <img src="/assets/shapes/navigate_before-24px.svg" 
        [ngClass]="isPreviousEnabled?'NextPreviousMonthContent':'NextPreviousMonthContentDisabled'">
    </div>
    <div class="NextPreviousMonth PreviousMonth" (click)="onNextMonth()">
        <img src="/assets/shapes/navigate_next-24px.svg" 
        [ngClass]="isNextEnabled?'NextPreviousMonthContent':'NextPreviousMonthContentDisabled'">
    </div>
    <table class="Dates" cellpadding="0" cellspacing="0">
        <tr class="Days"  style="border-collapse: separate;
        border-spacing: 0;">
            <td class="DateCell">S</td>
            <td class="DateCell">M</td>
            <td class="DateCell">T</td>
            <td class="DateCell">W</td>
            <td class="DateCell">T</td>
            <td class="DateCell">F</td>
            <td class="DateCell">S</td>
        </tr>
        <tr *ngFor="let row of rows" [@transparentOpaque]="transparentOpaque" style="border-collapse: separate;
        border-spacing: 0;">
            <td *ngFor="let col of cols" (click)="onCellClick(row, col)">
                <div style="padding-top: 2px; padding-bottom: 2px;">
                    <div [ngClass]="getDateCellSemiSelectedClass(row, col)">
                        <div [ngClass]="getDateCellSelectedClass(row, col)">
                            <div [ngClass]="isItToday(row, col)">
                                {{ getDate(row, col) }}
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </table>
</div>
