<div (click)="onDateSelectClick()" style="cursor: pointer;">
    <img src="assets/img/date.svg" class="date-icon"/>
    <textarea [disabled]="true" [ngStyle]="customStyle" class="rectangle" [cols]="40" [rows]="1" [textContent]="textContent"></textarea>
</div>
<div *ngIf="showSelectionArea" class="selection-area">
<!--
        <app-calendar #calendar [m_markEndDate]="date" [m_markStartDate]="date" (onDateClick)="onDateClick($event)"></app-calendar>
-->
    <app-calendar #calendar [m_markEndDate]="date" [m_markStartDate]="date" [m_currentDate]="date" (onDateClick)="onDateClick($event)"></app-calendar>
</div>
