import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  animations: [
    trigger('transparentOpaque', [
      state('transparent', style({
        opacity: 0
      })),
      state('opaque', style({
        opacity: 1
      })),
      transition('transparent => opaque', [
        animate('0.25s')
      ]),
      transition('opaque => transparent', [
        animate('0.25s')
      ]),
    ]),
  ],
})
export class CalendarComponent implements OnInit {

  @Input() maxDate: Date | null = null;
  @Input() minDate: Date | null = null;
  @Input() startDate: Date | null = null;
  @Input() endDate: Date | null = null;
  @Input() m_currentDate: Date | null = null;
  @Output() startDateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() endDateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() m_currentDateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() onDateClick: EventEmitter<Date> = new EventEmitter<Date>();
  days: Date[] = [];
  rows: number[] = [];
  cols: number[] = [];
  offset: number = 0;
  transparentOpaque = "opaque";
  @Input() m_markStartDate: Date | null = null;
  @Input() m_markEndDate: Date | null = null;

  get markStartDate(){
    return this.m_markStartDate ?? new Date();
  }

  set markStartDate(value : Date){
    this.m_markStartDate = value;
  }

  get markEndDate(){
    return this.m_markEndDate ?? new Date();
  }

  set markEndDate(value : Date){
    this.m_markEndDate = value;
    this.update();
  }

  get isNextEnabled(){
    if(this.maxDate != null){
      var currentPlusMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
      currentPlusMonth.setMonth(currentPlusMonth.getMonth() + 1 );
      return currentPlusMonth < this.maxDate;
    }
    return true;
  }

  get isPreviousEnabled(){
    if(this.minDate != null){
      var currentMinusMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
      currentMinusMonth.setMonth(currentMinusMonth.getMonth() - 1 );
      return currentMinusMonth > this.minDate;
    }
    return true;
  }

  get monthYear(){
    return this.currentDate.toLocaleString('default', {month: 'long'}) + ' ' + this.currentDate.getFullYear();
  }

  calcDatesInCurrentMonth(){
    this.days.length = 0;
    var date = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(),1);
    while (date.getMonth() === this.currentDate.getMonth()) {
      this.days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
  }

  get currentDate(){
    return this.m_currentDate ?? new Date();
  }

  set currentDate(value: Date){
    this.m_currentDate = value;
    this.update();
    this.m_currentDateChange.emit(this.m_currentDate);
  }

  update(){
    this.calcDatesInCurrentMonth();
    this.offset = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1).getDay();
  }

  constructor() { }

  ngOnInit(): void {
    this.rows = Array(6).fill(0).map((x, i) => i);
    this.cols = Array(7).fill(0).map((x, i) => i);
    if(this.m_currentDate == null){
      this.m_currentDate = new Date();
      this.m_currentDate.setDate(1);
    }
    this.update();
    if(this.minDate != null && this.currentDate <= this.minDate){
      this.currentDate.setMonth(this.currentDate.getMonth() + 1 );
    }
    if(this.maxDate != null && this.currentDate > this.maxDate){
      this.currentDate.setMonth(this.currentDate.getMonth() - 1 );
    }
  }

  onPreviousMonth(){
    if(this.isPreviousEnabled){
      this.transparentOpaque = "transparent";
      var that = this;
      setTimeout(() =>{
        that.currentDate.setMonth(that.currentDate.getMonth() - 1 );
        that.update();
        that.transparentOpaque = "opaque";
      }, 250);
    }
  }

  onNextMonth(){
    if(this.isNextEnabled){
      this.transparentOpaque = "transparent";
      var that = this;
      setTimeout(() =>{
        that.currentDate.setMonth(that.currentDate.getMonth() + 1 );
        that.update();
        that.transparentOpaque = "opaque";
      }, 250);
    }
  }

  getDate(row: number, col:number){
    var cell = row * 7 + col;
    if(cell >= this.offset && cell < this.offset + this.days.length){
      return this.days[cell - this.offset].getDate().toString();
    }else{
      return " ";
    }
  }

  onCellClick(row: number, col:number){
    var cell = row * 7 + col;
    if(cell >= this.offset && cell < this.offset + this.days.length){
      return this.onDateClick.emit(this.days[cell - this.offset]);
    }
  }

  getDateCellSemiSelectedClass(row: number, col:number){
    var cell = row * 7 + col;
    if(cell >= this.offset && cell < this.offset + this.days.length){
      var current = this.days[cell - this.offset];
      if(this.markStartDate != null && this.markEndDate != null && this.withoutTime(current) >= this.withoutTime(this.markStartDate) && this.withoutTime(current) <= this.withoutTime(this.markEndDate)){
        switch(col){
          case 0:
            if(this.withoutTime(current).valueOf() == this.withoutTime(this.markEndDate).valueOf() || this.withoutTime(current).getDate() === this.days[this.days.length-1].getDate()){
              return "DateCellSemiSelectedSingle";
            }
            return "DateCellSemiSelectedLeftEdge";
          case 6:
            if(this.withoutTime(current).valueOf() == this.withoutTime(this.markStartDate).valueOf() || this.withoutTime(current).getDate() === this.days[0].getDate()){
              return "DateCellSemiSelectedSingle";
            }
            return "DateCellSemiSelectedRightEdge";
          default:
            if(this.withoutTime(current).valueOf() === this.withoutTime(this.markStartDate).valueOf() && this.withoutTime(current).valueOf() == this.withoutTime(this.markEndDate).valueOf()){
              return "DateCellSemiSelectedSingle";
            }else{
              if(this.withoutTime(current).valueOf() == this.withoutTime(this.markStartDate).valueOf()){
                return "DateCellSemiSelectedLeftEdge";
              }else{
                if(this.withoutTime(current).valueOf() == this.withoutTime(this.markEndDate).valueOf()){
                  return "DateCellSemiSelectedRightEdge";
                }else{
                  if(this.withoutTime(current).getDate() === this.days[this.days.length-1].getDate()){
                    return "DateCellSemiSelectedRightEdge"
                  } else{
                    if(this.withoutTime(current).getDate() === this.days[0].getDate()){
                      return "DateCellSemiSelectedLeftEdge";
                    }else{
                      return "DateCellSemiSelected";
                    }
                  }
                }
              }
            }
        }
      }
    }
    return "DateCell";
  }

  withoutTime(date: Date) : Date{
    var d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
  }

  getDateCellSelectedClass(row: number, col:number){
    var cell = row * 7 + col;
    if(cell >= this.offset && cell < this.offset + this.days.length){
      var current = this.days[cell - this.offset];
      if((this.markStartDate != null && this.withoutTime(current).valueOf() === this.withoutTime(this.markStartDate).valueOf()) || (this.markEndDate != null && this.withoutTime(current).valueOf() === this.withoutTime(this.markEndDate).valueOf())){
        return "DateCellSelected"
      }
    }
    return "DateCell";
  }

  isItToday(row: number, col:number){
    var cell = row * 7 + col;
    if(cell >= this.offset && cell < this.offset + this.days.length){
      var current = this.days[cell - this.offset];
      if(this.withoutTime(current).valueOf() == this.withoutTime(new Date()).valueOf()){
        return "DateToday"
      }
    }
    return "";
  }
}
