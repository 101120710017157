<div class="menu">
    <div class="menu-btn">
        <button class="options-btn" (click)="onMenuClick()">
            <img class="options-btn-img" src="assets/img/menu.svg" />
        </button>
    </div>
    <div *ngIf="isDropdownOpen" class="drawer">
        <div *ngFor="let option of menuOptions" class="drawer-option" (click)="onOptionSelected(option)">
            <div class="selected-text">
                {{option.displayName}}
            </div>
        </div>
    </div>
</div>
