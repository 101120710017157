<div class="container">
    <div></div>
    <div class="title" (click)="onVisitClick()">
        {{visit2.title  | truncate:[14, '...']}}
    </div>
    <div class="description" (click)="onVisitClick()">
        {{visit2.description  | truncate:[24, '...']}}
    </div>
    <div class="other" (click)="onVisitClick()">
        {{textContent}}
    </div>
    <div class="other" (click)="onVisitClick()">
        {{Math.floor((this.visit2.visit_date_end.getTime() - this.visit2.visit_date_start.getTime())/(60*1000))}}m
    </div>
    <div [ngSwitch]="visit2.risk_level" (click)="onVisitClick()">
        <div *ngSwitchCase="'LOW'" class="low">
            LOW
        </div>
        <div *ngSwitchCase="'MID'" class="mid">
            MID
        </div>
        <div *ngSwitchCase="'HIGH'" class="high">
            HIGH
        </div>
    </div>
    <div class="other" (click)="onVisitClick()">
        <!-- TODO: Add attachments field to visit2 class -->
        N/A
    </div>
    <app-record-menu [menuOptions]="historyMenuOptions" (menuSelected)="onMenuOptionSelected($event)"></app-record-menu>

</div>
