import {
  Injectable,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  EmbeddedViewRef,
  ComponentRef,
  Type
} from '@angular/core';
import { CustomDialogConfig } from '../custom-dialog/custom-dialog-config';
import { CustomDialogInjector } from '../custom-dialog/custom-dialog-injector';
import { CustomDialogRef } from '../custom-dialog/custom-dialog-ref';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CustomDialogService {
  dialogComponentRef: ComponentRef<CustomDialogComponent> | undefined;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  public open(componentType: Type<any>, config: CustomDialogConfig): CustomDialogRef {
    const dialogRef = this.appendDialogComponentToBody(config);

    this.dialogComponentRef!.instance.childComponentType = componentType;

    return dialogRef;
  }

  private appendDialogComponentToBody(config: CustomDialogConfig): CustomDialogRef {
    const map = new WeakMap();
    map.set(CustomDialogConfig, config);

    const dialogRef = new CustomDialogRef();
    map.set(CustomDialogRef, dialogRef);

    const sub = dialogRef.afterClosed.subscribe(() => {
      // close the dialog
      this.removeDialogComponentFromBody();
      sub.unsubscribe();
    });

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CustomDialogComponent);
    const componentRef = componentFactory.create(new CustomDialogInjector(this.injector, map));
    this.appRef.attachView(componentRef.hostView);
    console.log(componentRef);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.dialogComponentRef = componentRef;

    this.dialogComponentRef.instance.onClose.subscribe(() => {
      this.removeDialogComponentFromBody();
    });

    
    return dialogRef;
  }

  private removeDialogComponentFromBody(): void {
    this.dialogComponentRef!.instance.transparentOpaque = "transparent";
    var that = this;
    setTimeout(() =>{
      that.appRef.detachView(this.dialogComponentRef!.hostView);
      that.dialogComponentRef!.destroy();
    }, 250);
  }
}