import { Injectable } from '@angular/core';
import { DataPoint, GraphPoint } from '../interfaces/data-point';
import { DataQuery, GraphQuery } from '../interfaces/data-query';
import { PatientBasic, PatientDisplayRecord, PatientInsurance, PatientMedical, PatientRecord, PatientSummary, PatientSummaryResponse, InsoleRecord } from '../interfaces/patient-record';
import { PatientRecordQuery } from '../interfaces/patient-record-query';
import { PatientVisit, PatientVisit2, PatientVisit2Request, PatientVisit2Response } from '../interfaces/patient-visit';
import { GeneratorService } from './generator.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { GeneralResponse, GraphResponse, AddNewResponse, HeatmapsResponse, VersionResponse } from '../interfaces/api-response';
import { formatDate } from '@angular/common';
import { Alert } from '../interfaces/alert';
import { Activity } from '../interfaces/activity';
import { LoginInfo, LoginAttempt } from '../interfaces/server-interface';
import { TemporaryStorageService } from './temporary-storage.service';
import { environment } from 'src/environments/environment';
import {PhysicianBasic} from '../interfaces/physician'

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private useGenerator : boolean = false; //true;
  private baseURL: string = environment.baseURL;

  //private token: string = 'c33367fe533b0827b433c860416f73417f638fff';
  private token: string|undefined = '';
  private dashboardURL: string = this.baseURL + 'dashboard/'
  private generalURL: string = this.baseURL + 'general/'

  constructor(private generatorService : GeneratorService, private httpClient : HttpClient, private storageService : TemporaryStorageService) {}

  getApiUrl(apiName : string, id?: number) : string {
    let url = this.dashboardURL;
    switch(apiName) {
      case 'getBackendVersion':
        url = this.generalURL + 'server/get_version';
        break;
      case 'login':
        url = url + 'physicians_login/login/';
        break;
      case 'addPatient':
        url = url + 'patient/add_patient/';
        break;
      case 'addVisit':
        url = url + 'visit/add_visit/';
        break;
      case 'getPatients':
        url = url + 'patient/get_my_patients/';
        break;
      case 'getPatient':
        url = url + 'patient/' + id + '/get_patient/';
        break;
      case 'getPatientImage':
        url = url + 'patient/' + id + '/get_photo/';
        break;
      case 'getVisits':
        url = url + 'patient/' + id + '/get_visits/';
        break;
      case 'getVisit':
        //TODO: Consider path consistency 'patient/patient_id/get_visit?visit_id=
        url = url + 'visit/' + id + '/get_visit/';
        break;
      case 'getInsurance':
        url = url + 'patient/' + id + '/get_patient_insurance/';
        break;
      case 'getMedicalInfo':
        url = url + 'patient/' + id + '/get_patient_medical/';
        break;
      case 'updatePatient':
        url = url + 'patient/' + id + '/update_patient/';
        break;
      case 'uploadPhoto':
        url = url + 'patient/' + id + '/upload_photo/';
        break;
      case 'updateVisit':
        //TODO: Consider path consistency 'patient/patient_id/update_visit?visit_id=
        url = url + 'visit/' + id + '/update_visit/';
        break;
      case 'updateInsurance':
        url = url + 'patient/' + id + '/update_patient_insurance/';
        break;
      case 'updateMedicalInfo':
        url = url + 'patient/' + id + '/update_patient_medical/';
        break;
      case 'deletePatient':
        url = url + 'patient/' + id + '/delete_patient/';
        break;
      case 'deleteVisit':
        //TODO: Consider path consistency 'patient/patient_id/update_visit?visit_id=
        url = url + 'visit/' + id + '/delete_visit/';
        break;
      case 'getGraphsData':
        url = url + 'patient/' + id + '/get_user_data_by_range/';
        break;
      // Same API as getGraphsData only through Lambda server
      case 'getGraphsDataLambda':
        //url = 'https://uqnbyytbqidsrdqiclbssnzeby0fxpxc.lambda-url.eu-west-1.on.aws/';
        url = environment.getUserDataLambda;
        break;
      case 'getPressureHeatmap':
        url = url + 'patient/' + id + '/get_pressure_heatmap/';
        break;
      case 'getTemperatureHeatmap':
        url = url + 'patient/' + id + '/get_temperature_heatmap/';
        break;
      case 'getInsoleRecords':
        url = url + 'patient/' + id + '/get_records/';
        break;
      case 'getRecordVideo':
        url = url + 'patient/get_pressure_heatmap_video/?id=' + id;
        break;
      case 'getActivities':
        url = url + 'patient/' + id + '/get_activities/';
        break;
      case 'getPhysician':
        url = url + 'physician/get_info/';
        break;
      default:
        url = url + 'patient/get_my_patients/';
        break;                    
    }
    return url;
  }

  getHttpHeaders(conType: string = "application/json") : HttpHeaders {
    //let header = new HttpHeaders({"Content-Type": conType, "Accept": "*/*", "Authorization": "Token " + this.token});
    let header = new HttpHeaders({"Content-Type": conType, "Accept": "*/*", "Authorization": "Token " + this.storageService.token});
    return header;
  }

  getBackendVersion( callback: (version: VersionResponse) => void, error: (error: any) => void) {
    const url = this.getApiUrl('getBackendVersion');
    let header = this.getHttpHeaders();

    this.httpClient.get<VersionResponse>(url, { headers: header }).subscribe({
      next: (response) => {callback(response)},
      error: (err) => { console.error(err), error(err)},
      //TODO: Call Error logging methods
    });
  }
  
  login(attempt: LoginAttempt, callback: (data: LoginInfo) => void, error: (err: any) => void,){
    const url = this.getApiUrl('login');
    let header = new HttpHeaders({"Content-Type": "application/json", "Accept": "*/*"});

    this.httpClient.post<LoginInfo>(url, attempt, { headers: header }).subscribe({
      next: (response) => { callback(response); },
      error: (err) => { error(err); },
    });
  }

  addNewPatient(patient: PatientBasic, callback: (response: AddNewResponse) => void) {
    const url = this.getApiUrl('addPatient');
    let header = this.getHttpHeaders();

    this.httpClient.post<AddNewResponse>(url, patient, { headers: header }).subscribe({
      next: (response) => {callback(response)},
      error: (err) => { console.error(err); callback({success:false, id:0, error: err.message})},
      //TODO: Call Error logging methods
    });
  }

  getPatients(queryParams : PatientRecordQuery, callback: (records : PatientSummaryResponse) => void, error: (error: any) => void){
    if(this.useGenerator){
      //this.generateRecordsForSummary(queryParams, callback);
      return;
    }
    const url = this.getApiUrl('getPatients');
    let header = this.getHttpHeaders();
    let params = new HttpParams();
    params = params.append("show", queryParams.show);
    params = params.append("searchInput", queryParams.searchInput);
    params = params.append("startIndex",queryParams.startIndex);
    params = params.append("endIndex", queryParams.endIndex);
    params = params.append("sortBy", queryParams.sortBy);

    this.httpClient.get<PatientSummaryResponse>(url, { headers: header, params: params }).subscribe({
      next: (records) => {callback(records)},
      error: (err) => { console.error(err), error(err)},
      //TODO: Call Error logging methods
    });
  }

  getPatient(patientId: number, callback: (record : PatientBasic) => void){
    if(this.useGenerator){
    }
    const url = this.getApiUrl('getPatient', patientId);
    let header = this.getHttpHeaders();

    this.httpClient.get<PatientBasic>(url, { headers: header }).subscribe({
      next: (record) => {callback(record)},
      error: (err) => { console.error(err)},
      //TODO: Call Error logging methods
    });
  }

  updatePatient(patient: PatientBasic, callback: (success: boolean) => void) {
    const url = this.getApiUrl('updatePatient', patient.id);
    let header = this.getHttpHeaders();

    this.httpClient.put<GeneralResponse>(url, patient, { headers: header }).subscribe({
      next: (response) => {callback(response.success)},
      error: (err) => { console.error(err)},
      //TODO: Call Error logging methods
    });
  }

  getVisits(patientId: number, callback: (records : PatientVisit2[]) => void){
    if(this.useGenerator){ 
      let visits : PatientVisit2[] = [];
      for(let i=0;i< 1 + Math.floor(Math.random() * 5);i++) visits.push(this.generateVisit2());
      callback(visits);
      return;
    }
    const url = this.getApiUrl('getVisits', patientId);
    let header = this.getHttpHeaders();

    this.httpClient.get<PatientVisit2Response>(url, { headers: header }).subscribe({
      next: (records) => {callback(records.results)},
      error: (err) => { console.error(err)},
      //TODO: Call Error logging methods
    });
  }

  //getPatientImage(patientId: number, callback: (record : Blob) => void){
  getPatientImage(patientId: number, callback: (record : string | ArrayBuffer) => void){
    if(this.useGenerator){ 
      this.generatorService.generateFaceBase64((image) => {
        callback(image);
      });
    }
    const url = this.getApiUrl('getPatientImage', patientId);
    let header = this.getHttpHeaders();
  
    this.httpClient.get<{photo: string | ArrayBuffer}>(url, { headers: header }).subscribe({
      next: (record) => {callback(record.photo)},
      error: (err) => { console.error(err)},
      //TODO: Call Error logging methods
    });
  }

  getInsurance(patientId: number, callback: (records : PatientInsurance) => void){
    if(this.useGenerator){ 
      let insurance : PatientInsurance = {insurer: '', insurer_visits: 12};
      callback(insurance);
      return;
    }
    const url = this.getApiUrl('getInsurance', patientId);
    let header = this.getHttpHeaders();

    this.httpClient.get<PatientInsurance>(url, { headers: header}).subscribe({
      next: (records) => {callback(records)},
      error: (err) => { console.error(err)},
      //TODO: Call Error logging methods
    });
  }

  getMedicalInfo(patientId: number, callback: (records : PatientMedical) => void){
    if(this.useGenerator){ 
      let insurance : PatientMedical = {relevant_background: '', feet_pathology: '', foot_ulcer: false};
      callback(insurance);
      return;
    }
    const url = this.getApiUrl('getMedicalInfo', patientId);
    let header = this.getHttpHeaders();

    this.httpClient.get<PatientMedical>(url, { headers: header}).subscribe({
      next: (records) => {callback(records)},
      error: (err) => { console.error(err)},
      //TODO: Call Error logging methods
    });
  }

  getInsoleRecords(patientId: number, callback: (records : InsoleRecord[]) => void){
    if(this.useGenerator){ 
      return;
    }
    const url = this.getApiUrl('getInsoleRecords', patientId);
    let header = this.getHttpHeaders();

    this.httpClient.get<InsoleRecord[]>(url, { headers: header }).subscribe({
      next: (records) => {callback(records)},
      error: (err) => { console.error(err)},
      //TODO: Call Error logging methods
    });
  }

  getRecordVideo(recordId: number, callback: (records : any, err: string) => void){
    if(this.useGenerator){ 
      return;
    }
    const url = this.getApiUrl('getRecordVideo', recordId);
    let header = this.getHttpHeaders("video/mp4");

    this.httpClient.get(url, { headers: header , responseType: "blob"}).subscribe({
      next: (records) => {callback(records, '')},
      error: (err) => { callback([], err);},
      //TODO: Call Error logging methods
    });
  }

  addVisit(visitReq: PatientVisit2Request, callback: (success: boolean) => void){
    const url = this.getApiUrl('addVisit');
    let header = this.getHttpHeaders();
    let body = visitReq;

    this.httpClient.post<GeneralResponse>(url, body, { headers: header }).subscribe({
      next: (response) => {callback(response.success)},
      error: (err) => { console.error(err)},
      //TODO: Call Error logging methods
    });
  }

  uploadPatientPhoto(patientId: number, imageBase64: string | ArrayBuffer, callback: (success: boolean) => void){
      const url = this.getApiUrl('uploadPhoto', patientId);
      let header = this.getHttpHeaders();
      let body = {photo: imageBase64};
  
      this.httpClient.post<GeneralResponse>(url, body, { headers: header }).subscribe({
        next: (response) => {callback(response.success)},
        error: (err) => { console.error(err)},
        //TODO: Call Error logging methods
      });
    }

    //updateVisit(visitId: number, visit: PatientVisit, callback: (success: boolean) => void){
  updateVisit(visit: PatientVisit2Request, callback: (success: boolean) => void){   
    const url = this.getApiUrl('updateVisit', visit.id);
    let header = this.getHttpHeaders();
    
    this.httpClient.put<GeneralResponse>(url, visit, { headers: header }).subscribe({
      next: (response) => {callback(response.success)},
      error: (err) => { console.error(err)},
      //TODO: Call Error logging methods
    });
  }

  updateInsurance(patientId: number, insurance: PatientInsurance, callback: (success: boolean) => void){
    const url = this.getApiUrl('updateInsurance', patientId);
    let header = this.getHttpHeaders();

    this.httpClient.put<GeneralResponse>(url, insurance, { headers: header }).subscribe({
      next: (response) => {callback(response.success);},
      error: (err) => { console.error(err);},
      //TODO: Call Error logging methods
    });
  }

  updateMedicalInfo(patientId: number, medicalInfo: PatientMedical, callback: (success: boolean) => void){
    //TODO: make API call
    const url = this.getApiUrl('updateMedicalInfo', patientId);
    let header = this.getHttpHeaders();
    
    this.httpClient.put<GeneralResponse>(url, medicalInfo, { headers: header }).subscribe({
      next: (response) => {callback(response.success);},
      error: (err) => { console.error(err)},
      //TODO: Call Error logging methods
    });
  }

  deletePatient(patientId: number, callback: (success: boolean) => void) {
    const url = this.getApiUrl('deletePatient', patientId);
    let header = this.getHttpHeaders();
    
    this.httpClient.delete<GeneralResponse>(url, { headers: header }).subscribe({
      next: (response) => {callback(response.success);},
      error: (err) => { console.error(err)},
      //TODO: Call Error logging methods
    });
  }

  deleteVisit(visitId: number, callback: (success: boolean) => void) {
    const url = this.getApiUrl('deleteVisit', visitId);
    let header = this.getHttpHeaders();
    
    this.httpClient.delete<GeneralResponse>(url, { headers: header }).subscribe({
      next: (response) => {callback(response.success);},
      error: (err) => { console.error(err)},
      //TODO: Call Error logging methods
    });
  }

  getRecords(queryParams : PatientRecordQuery, callback: (records : PatientDisplayRecord[]) => void){
    if(this.useGenerator){
      this.generateRecords(queryParams, callback);
      return;
    }
    //TODO: make API call
  }

  getRecordsForSummary(queryParams : PatientRecordQuery, callback: (records : PatientSummary[]) => void){
    if(this.useGenerator){
      this.generateRecordsForSummary(queryParams, callback);
      return;
    }
    //TODO: make API call
  }

  getAllGraphs(queryParams: GraphQuery, callback: (graph: GraphResponse) => void) {
    this.generateAllGraphs(queryParams, callback);
    return;
    //TODO: make API call
  }

  getGraphData(queryParams : DataQuery, callback: (points : GraphPoint[]) => void){
      if(this.useGenerator){
      this.generateGraphData(queryParams, callback);
      return;
    }
    this.generateGraphData(queryParams, callback);
    return;
  //TODO: make API call
  }

    getGraphsData(patientId: number, startTime: string, endTime: string, callback: (graphs: any, err: string) => void) {
    if(this.useGenerator){
      //this.generateAllGraphs({patient_id: patientId, start_date: new Date(startTime), end_date: new Date(endTime), resolution: 'day'}, callback);
      return;
    }

    const url = this.getApiUrl('getGraphsData', patientId);
    let header = this.getHttpHeaders();
    let params = new HttpParams();
    params = params.append("startTime", startTime);
    params = params.append("endTime", endTime);
    
    this.httpClient.get<GraphResponse>(url, { headers: header, params: params }).subscribe({
      next: (response) => {callback(response, '');},
      error: (err) => { callback([], err);},
    });
  }

  getGraphsDataLambda(patientId: number, startTime: string, endTime: string, callback: (graphs: any, err: string) => void) {
    if(this.useGenerator){
      return;
    }

    const url = this.getApiUrl('getGraphsDataLambda', patientId);
    let header = this.getHttpHeaders();
    let params = new HttpParams();
    params = params.append("startTime", startTime);
    params = params.append("endTime", endTime);
    params = params.append("patient_id", patientId);
    
    this.httpClient.get<GraphResponse>(url, { headers: header, params: params }).subscribe({
      next: (response) => {callback(response, '');},
      error: (err) => { callback([], err);},
    });
  }

  getActivities(patientId: number, startTime: string, endTime: string, callback: (activities: any, err: string) => void) {
    if(this.useGenerator){
      //this.generateAllGraphs({patient_id: patientId, start_date: new Date(startTime), end_date: new Date(endTime), resolution: 'day'}, callback);
      return;
    }

    const url = this.getApiUrl('getActivities', patientId);
    let header = this.getHttpHeaders();
    let params = new HttpParams();
    params = params.append("startTime", startTime);
    params = params.append("endTime", endTime);
    
    this.httpClient.get<Activity[]>(url, { headers: header, params: params }).subscribe({
      next: (response) => {callback(response, '');},
      error: (err) => { callback([], err);},
    });
  }

  getHeatmapImages(heatmapType: string, patientId: number, startTime: string, endTime: string, callback: (heatmaps: any, err: string) => void) {
    let url = '';
    switch(heatmapType) {
      case "pressure":
        url = this.getApiUrl('getPressureHeatmap', patientId);
        break;
      case "temperature":
        url = this.getApiUrl('getTemperatureHeatmap', patientId);
        break;
      default: break;
    }
    let header = this.getHttpHeaders();
    let params = new HttpParams();
    params = params.append("startTime", startTime);
    params = params.append("endTime", endTime);
    
    this.httpClient.get<HeatmapsResponse>(url, { headers: header, params: params }).subscribe({
      next: (response) => {callback(response, '');},
      error: (err) => { callback([], err);},
    });
  }

  getPhysician(callback: (record : PhysicianBasic) => void){
    if(this.useGenerator){
    }
    const url = this.getApiUrl('getPhysician');
    let header = this.getHttpHeaders();

    this.httpClient.get<PhysicianBasic>(url, { headers: header }).subscribe({
      next: (record) => {callback(record)},
      error: (err) => { console.error(err)},
      //TODO: Call Error logging methods
    });
  }


  private generateTimeSeriesData(queryParams: DataQuery, callback: (points: GraphPoint[]) => void){
    let date = this.generatorService.generateComplexRandomDatesArray(queryParams.startDate, queryParams.endDate);
    let value = this.generatorService.generateRandomArray(date.length, 600, 800);
    let gpoints = [];
    for(let i=0; i< date.length; i++){
      gpoints.push({points: [{date: date[i], value: value[i]}], foot_side: queryParams.foot, foot_area: queryParams.footArea});
    }
    callback(gpoints);
  }

  private generateSimpleTimeSeriesData(queryParams: DataQuery, callback: (points: GraphPoint[]) => void){
    let date = this.generatorService.generateSimpleRandomDatesArray(queryParams.startDate, queryParams.endDate);
    let value : number[] = [];
    switch(queryParams.measurementType){
      case 'steps':
        value = this.generatorService.generateRandomArray(date.length, 200, 6000);
        break;
      case 'activity':
        value = this.generatorService.generateRandomArray(date.length, 2, 8);
        break;
    }
    let gpoints = [];
    for(let i=0; i< date.length; i++){
      gpoints.push({points: [{date: date[i], value: value[i]}], foot_side: queryParams.foot, foot_area: queryParams.footArea});
    }
    callback(gpoints);
  }

  generateGraphData(queryParams: DataQuery, callback: (points: GraphPoint[]) => void) {
    switch(queryParams.measurementType){
      case 'pressure':
        this.generateTimeSeriesData(queryParams, callback);
        break;
      case 'temperature':
        this.generateTimeSeriesData(queryParams, callback);
        break;
      case 'activity':
        this.generateSimpleTimeSeriesData(queryParams, callback);
        break;
      case 'steps':
        this.generateSimpleTimeSeriesData(queryParams, callback);
        break;
    }
  }

  private generateVisit() : PatientVisit{
    let startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 6);
    let visit =  {
      title: this.generatorService.generateLoremIpsum({
        sentencesPerParagraph: {
          max: 2,
          min: 1
        },
        wordsPerSentence: {
          max: 6,
          min: 2
        }
      }, 1),
      startTime: this.generatorService.generateDate(startDate, new Date(), 8, 8, 0, 10),
      endTime: new Date(),
      description: this.generatorService.generateLoremIpsum({
        sentencesPerParagraph: {
          max: 8,
          min: 4
        },
        wordsPerSentence: {
          max: 16,
          min: 4
        }
      }, 1),
      riskLevel: this.generatorService.generateChoice('low','mid','high'),
      attachmentsPaths: []
    } 
    visit.endTime = new Date(visit.startTime);
    visit.endTime.setMinutes(visit.endTime.getMinutes() + Math.floor(this.generatorService.generateNumber(0, 15)));
    return visit;
  }

  private generateVisit2() : PatientVisit2{
    let startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 6);
    let visit =  {
      id:this.generatorService.generateNumber(0, 20),
      title: this.generatorService.generateLoremIpsum({
        sentencesPerParagraph: {
          max: 2,
          min: 1
        },
        wordsPerSentence: {
          max: 6,
          min: 2
        }
      }, 1),
      visit_date_start: this.generatorService.generateDate(startDate, new Date(), 8, 8, 0, 10),
      visit_date_end: new Date(),
      description: this.generatorService.generateLoremIpsum({
        sentencesPerParagraph: {
          max: 8,
          min: 4
        },
        wordsPerSentence: {
          max: 16,
          min: 4
        }
      }, 1),
      risk_level: this.generatorService.generateChoice('low','mid','high'),
      user: this.generatorService.generateNumber(0, 5),
    } 
    visit.visit_date_end = new Date(visit.visit_date_start);
    visit.visit_date_end.setMinutes(visit.visit_date_end.getMinutes() + Math.floor(this.generatorService.generateNumber(0, 15)));
    return visit;
  }

  private generateRecords(queryParams: PatientRecordQuery, callback: (records : PatientDisplayRecord[]) => void) {
    let records : PatientDisplayRecord[] = [];
    for(let i=0;i<queryParams.endIndex - queryParams.startIndex;i++){
      let startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 80);
      let displayRecord : PatientDisplayRecord = {
        record: {
          isActive: this.generatorService.generateBoolean(),
          basicInfo: {
            id: queryParams.startIndex + i,
            first_name: this.generatorService.generateName(),
            last_name: this.generatorService.generateName(),
            address1: this.generatorService.generateLoremIpsum({sentencesPerParagraph: {min: 1, max: 1}, wordsPerSentence: {min: 3, max: 4}}, 1),
            address2: '', //this.generatorService.generateLoremIpsum({sentencesPerParagraph: {min: 1, max: 1}, wordsPerSentence: {min: 3, max: 4}}, 1),
            city: this.generatorService.generateLoremIpsum({sentencesPerParagraph: {min: 1, max: 1}, wordsPerSentence: {min: 1, max: 1}}, 1),
            //dob: this.generatorService.generateDate(startDate, new Date(), 0, 0),
            dob: formatDate(this.generatorService.generateDate(startDate, new Date(), 0, 0),'YYYY-MM-dd','en-us'),
            email: `${this.generatorService.generateLoremIpsum({sentencesPerParagraph: {min: 1, max: 1}, wordsPerSentence: {min: 1, max: 1}}, 1)}@${this.generatorService.generateLoremIpsum({sentencesPerParagraph: {min: 1, max: 1}, wordsPerSentence: {min: 1, max: 1}}, 1)}.com`,
            gender: this.generatorService.generateChoice('male', 'female', 'other'),
            phone_number: Math.floor(this.generatorService.generateNumber(0, 999999999)).toString().padStart(9, '0'),
            shoe_size: this.generatorService.generateNumber(0, 20).toString(),
            state: this.generatorService.generateLoremIpsum({sentencesPerParagraph: {min: 1, max: 1}, wordsPerSentence: {min: 1, max: 1}}, 1),
            zip_code: this.generatorService.generateNumber(0, 9999999).toString().padStart(7, '0'),
            imageBase64: '',
            patient_id: Math.floor(Math.random() * 99999999).toString().padStart(8, '0'),
            footedness: this.generatorService.generateChoice('left', 'right')
          },
          insurance: {
            insurer: '',
            insurer_visits: Math.floor(this.generatorService.generateNumber(0, 20)),
          },
          medicalInfo: {
            feet_pathology:this.generatorService.generateLoremIpsum({sentencesPerParagraph: {min: 2, max: 4}, wordsPerSentence: {min: 4, max: 6}}, 1),
            relevant_background: this.generatorService.generateLoremIpsum({sentencesPerParagraph: {min: 2, max: 4}, wordsPerSentence: {min: 4, max: 6}}, 1),
            foot_ulcer: this.generatorService.generateBoolean()
          },
          insoleStatus: this.generatorService.generateBoolean(),
          lastActiveInsoleDate: new Date(),
          authorizationDate: new Date(),
          history: []
        },
        isChecked: false,
        isExpanded: false,
      };
      let date : Date = new Date();
      let date2 : Date = new Date();
      if(displayRecord.record.insoleStatus){
        date.setDate(date.getDate() - 3);
      }else{
        date.setDate(date.getDate() - 30);
        date2.setDate(date2.getDate() - 3)
      }
      displayRecord.record.lastActiveInsoleDate = this.generatorService.generateDate(date, date2, 0, displayRecord.record.insoleStatus ? new Date().getHours() : 0);
      for(let i=0;i< 1 + Math.floor(Math.random() * 7);i++){
        displayRecord.record.history.push(this.generateVisit2())
      }
      //displayRecord.record.history.sort((a, b) => {
      //  return b.startTime.getTime() - a.startTime.getTime();
      //})
      displayRecord.record.history.sort((a, b) => {
        return b.visit_date_start.getTime() - a.visit_date_start.getTime();
      })
      //displayRecord.record.authorizationDate = displayRecord.record.history[0].startTime;
      displayRecord.record.authorizationDate = displayRecord.record.history[0].visit_date_start;
      this.generatorService.generateFaceBase64((image) => {
        //displayRecord.record.imageBase64 = image;
        displayRecord.record.basicInfo.imageBase64 = image;
      });
      records.push(displayRecord);
    }
    callback(records);
  }

  private generateRecordsForSummary(queryParams: PatientRecordQuery, callback: (records : PatientSummary[]) => void) {
    let records : PatientSummary[] = [];
    for(let i=0;i<queryParams.endIndex - queryParams.startIndex;i++){
      let startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 80);
      let history = [];
      for(let i=0;i< 1 + Math.floor(Math.random() * 7);i++){
        history.push(this.generateVisit2())
      }
      let summaryRecord : PatientSummary = {
        id: queryParams.startIndex + i,
        is_active: this.generatorService.generateBoolean(),
        imageBase64: '',
        insole_status: this.generatorService.generateBoolean(),
        //lastActiveInsoleDate: new Date(),
        authorization_date: new Date(),
        isChecked: false,
        first_name: this.generatorService.generateName(),
        last_name: this.generatorService.generateName(),
        patient_id: Math.floor(Math.random() * 99999999).toString().padStart(8, '0'),
        last_record_date: new Date(),
        last_seen: Math.floor(Math.random() * 999).toString(),
        last_visits: history,
      };

      let date : Date = new Date();
      let date2 : Date = new Date();
      if(summaryRecord.insole_status){
        date.setDate(date.getDate() - 3);
      }else{
        date.setDate(date.getDate() - 30);
        date2.setDate(date2.getDate() - 3)
      }
      summaryRecord.last_record_date = this.generatorService.generateDate(date, date2, 0, summaryRecord.insole_status ? new Date().getHours() : 0);
      summaryRecord.authorization_date = this.generatorService.generateDate(new Date(summaryRecord.last_record_date.getFullYear()-3, summaryRecord.last_record_date.getMonth()), summaryRecord.last_record_date);
      this.generatorService.generateFaceBase64((image) => {
        //displayRecord.record.imageBase64 = image;
        summaryRecord.imageBase64 = image;
      });
      records.push(summaryRecord);
    }
    callback(records);
  }

  private generateDataPoint(startDate: Date, endDate: Date, min: number, max: number) : DataPoint{
    let point =  {
      //date: this.generatorService.generateDate(startDate, endDate, 8, 8, 0, 10),
      date: this.generatorService.generateDate(startDate, endDate, 8, 8, 0, 0),
      value: Math.floor(Math.random() * (max-min)) + min,
      alert: this.generatorService.generateLoremIpsum({
        sentencesPerParagraph: {
          max: 1,
          min: 0
        },
        wordsPerSentence: {
          max: 5,
          min: 3
        }
      }, 1),
    } 
    return point;
  }

  private generateAllGraphs(queryParams: GraphQuery, callback: (records : GraphResponse) => void) {
    let records : GraphResponse = {pressure:[], temperature:[], alerts:[], activity:[], error:''};
    let startDate = new Date(queryParams.start_date);
    let endDate = new Date(queryParams.end_date);
    let step = 1;
    let min,max = 0;
    switch (queryParams.resolution) {
      case 'month':
        step = 30;
        break;
      case 'day':
        step = 1;
        break;
      case 'hour':
        step = 1;
        break;
      default:
        step = 1;
        break;
    }
    records.pressure = this.generateGraph(startDate, endDate, step, 0, 350);
    records.temperature = this.generateGraph(startDate, endDate, step, 27, 35);
    records.alerts = this.generateAlerts(records.pressure, 320, 50, records.temperature, 33, 25);
    callback(records);
  }

  private generateGraph(startDate: Date, endDate: Date, step: number, min: number, max: number) : GraphPoint[] {
    let graph: GraphPoint[] = [];
    for(let dt=new Date(startDate); dt<=endDate; ){
      //let rand = Math.floor(Math.random() * 9) + 1;
      let rand = 1;
      ['left', 'right'].forEach(side => {
        ['heel', 'arch', 'midFoot', 'bigToe', '1stMeta', '2ndMeta', '3-4thMeta', '5thMeta'].forEach(area => {
          let points = [];
          for(let i=0; i < rand; i++) points.push(this.generateDataPoint(startDate, endDate, min, max));          
          let graphRecord : GraphPoint = {
            points: points,
            foot_side: side as "left"| "right"| "both",
            foot_area: area as 'heel'| 'arch'| 'midFoot'| 'bigToe'| '1stMeta' | '2ndMeta' | '3-4thMeta' | '5thMeta',
          };
          graph.push(graphRecord);
        })
      })
      dt.setDate(dt.getDate() + step);
    }
    return graph;
  }

  private generateAlerts(pressure: GraphPoint[], pressureAlertHighVal: number, pressureAlertLowVal: number, temperature: GraphPoint[], temperatureAlertHighVal: number, temperatureAlertLowVal: number) : Alert[] {
    let alerts: Alert[] = [];
    pressure.forEach(pointArray => {
      pointArray.points.forEach(point => {
        if(point.value > pressureAlertHighVal) alerts.push(this.generateHighPressureAlert(pointArray.foot_side, pointArray.foot_area, point))
        if(point.value < pressureAlertLowVal) alerts.push(this.generateLowPressureAlert(pointArray.foot_side, pointArray.foot_area, point))
      })
    })

    temperature.forEach(pointArray => {
      pointArray.points.forEach(point => {
        if(point.value > temperatureAlertHighVal) alerts.push(this.generateHighTemperatureAlert(pointArray.foot_side, pointArray.foot_area, point))
        if(point.value < temperatureAlertLowVal) alerts.push(this.generateLowTemperatureAlert(pointArray.foot_side, pointArray.foot_area, point))
      })
    })

    return alerts   
  }

  //private generateHighPressureAlert(side: "left" | "right" | "both", area: 'heel' | 'arch' | 'midFoot' | 'bigToe' | '1stMeta' | '2ndMeta' | '3-4thMeta' | '5thMeta', point: DataPoint) : Alert {
  private generateHighPressureAlert(side: string, area: string, point: DataPoint) : Alert {
      let msg = "High Pressure (" + point.value + ")";
    let alert: Alert = this.generateAlert('pressure', side, area, point, msg);

    return alert;
  }

  //private generateLowPressureAlert(side: "left" | "right" | "both", area: 'heel' | 'arch' | 'midFoot' | 'bigToe' | '1stMeta' | '2ndMeta' | '3-4thMeta' | '5thMeta', point: DataPoint) : Alert {
  private generateLowPressureAlert(side: string, area: string, point: DataPoint) : Alert {
    let msg = "Low Pressure (" + point.value + ")";
    let alert: Alert = this.generateAlert('pressure', side, area, point, msg);

    return alert;
  }

  //private generateHighTemperatureAlert(side: "left" | "right" | "both", area: 'heel' | 'arch' | 'midFoot' | 'bigToe' | '1stMeta' | '2ndMeta' | '3-4thMeta' | '5thMeta', point: DataPoint) : Alert {
  private generateHighTemperatureAlert(side: string, area: string, point: DataPoint) : Alert {
    let msg = "High Temperture (" + point.value + ")";
    let alert: Alert = this.generateAlert('temperature', side, area, point, msg);

    return alert;
  }

  //private generateLowTemperatureAlert(side: "left" | "right" | "both", area: 'heel' | 'arch' | 'midFoot' | 'bigToe' | '1stMeta' | '2ndMeta' | '3-4thMeta' | '5thMeta', point: DataPoint) : Alert {
  private generateLowTemperatureAlert(side: string, area: string, point: DataPoint) : Alert {
    let msg = "Low Temperture (" + point.value + ")";
    let alert: Alert = this.generateAlert('temperature', side, area, point, msg);

    return alert;
  }

  //private generateAlert(type: "pressure" | "temperature", side: "left" | "right" | "both", area: 'heel' | 'arch' | 'midFoot' | 'bigToe' | '1stMeta' | '2ndMeta' | '3-4thMeta' | '5thMeta', point: DataPoint, msg: string) : Alert {
  private generateAlert(type: "pressure" | "temperature", side: string, area: string, point: DataPoint, msg: string) : Alert {
    let severity = this.generatorService.generateChoice('low', 'mid', 'high');
    //let alert: Alert = {message: msg, severity: severity, graph_type: type, graph_info: {foot_side: side, foot_area: area, points: [point]}}
    let alert: Alert = {message: msg, severity: severity, graph_type: type, foot_side: side, foot_area: area, date: point.date, value: point.value}

    return alert;
  }
  
}
