import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {

  selected:boolean = false;
  @Input() placeholderText: string | undefined;
  @Output() textChange : EventEmitter<string> = new EventEmitter<string>();
  @Input() text: string = '';

  @Input() searchResults: {displayName: string, id: any}[] = [];
  @Output() onSelected : EventEmitter<any> = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit(): void {
  }

  valueChange($event : string){
    this.textChange.emit(this.text);
  }

  onSearchSelected(id: any) {
    this.onSelected.emit(id);
  }

}
