import { ChangeDetectorRef, Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { DataPoint, GraphPoint } from 'src/app/interfaces/data-point';
import { PatientRecord } from 'src/app/interfaces/patient-record';
import { ApiService } from 'src/app/services/api.service';
import { WidgetInterface } from '../../widget-interface';
import { formatDate } from '@angular/common';
import { GraphComponent } from 'src/app/graph/graph.component';

@Component({
  selector: 'app-graph-widget',
  templateUrl: './graph-widget.component.html',
  styleUrls: ['./graph-widget.component.scss']
})
export class GraphWidgetComponent implements OnInit, WidgetInterface {

  //@ViewChild(BaseChartDirective) chart : BaseChartDirective | undefined;
  @ViewChild('graph') graph: GraphComponent | undefined;

  @Input() record : PatientRecord = {
    basicInfo: {
      id: 0,
      first_name: '',
      last_name: '',
      address1:'',
      address2:'',
      city: '',
      //dob: new Date(),
      dob: formatDate(new Date(),'YYYY-MM-dd','en-us'),
      email: '',
      gender: 'male',
      phone_number: '',
      shoe_size: '8',
      state: '',
      zip_code: '',
      imageBase64: '',
      patient_id: '',
      footedness: 'right'
    },
    medicalInfo: {
      relevant_background: '',
      feet_pathology: '',
      foot_ulcer: false
    },
    insurance: {
      insurer: '',
      insurer_visits: 12,
    },
    history: [],
    isActive : false,
    insoleStatus: false,
    lastActiveInsoleDate: new Date(),
    authorizationDate: new Date(),
  };

  @Input() data: any;

  graphOptions : ChartOptions= {
    responsive: true,
    maintainAspectRatio: false,
    layout:{
      padding:{
        bottom: 10,
        top: 10
      },
    },
    scales: {
      x: {
        display: false,
        //type: 'time',
        type: 'category',
        ticks: {
          display:false,
          font: {
            family: 'Poppins-Regular',
          }
        },
        grid: {
          display:false
        }
      },
      y: {
        display: false,
        beginAtZero: false,
        title:{
          display: false,
          font: {
            family: 'Poppins-Regular',
          }
        },
        ticks: {
          font: {
            family: 'Poppins-Regular',
          }
        },
        grid:{
          display:false
        }
      },
    },
    plugins: {
      legend:{
        display: false,
      }
    },
  };

  graphData : ChartData= {
    labels: Array(Number),
    datasets: [{
      data: [],
      type: 'line',
      label: '',
      fill: false,
      spanGaps:true,
      normalized: true,
      tension: 0.5,
      borderColor: '#000000',
      backgroundColor: '#2f0f13',
      pointRadius: 3,
      pointHitRadius: 10, 
      pointHoverBackgroundColor: '#2f0f13',
      pointHoverBorderColor: '#ffffff',
      capBezierPoints: true,
      borderCapStyle: 'round' 
    }]
  }; 

  widgetWidth: number = 250;

  @Input() startDate: Date = new Date();
  @Input() endDate: Date = new Date();

  dataPoints : DataPoint[] = [];

  constructor(private changeDet: ChangeDetectorRef) { }
  type: Type<any> = GraphWidgetComponent;

  ngAfterViewInit(): void {
    this.changeDet.detectChanges();
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.data.getData((points : DataPoint[]) =>{
      this.graphData.datasets[0].data = [];
      this.graphData!.labels! = [];
      this.dataPoints = points;
      if(points.length > 0) {
        let data:number[] = [];
        let lbls:string[] = []
        points.forEach(point => {
            data.push(point.value)
            lbls.push(formatDate(new Date(point.date), "MM-dd, h a", "en-us"))
          })
        this.graphData.datasets[0].data = data;
        this.graphData!.labels! = lbls;
      }
      this.update();
    });

  }

  update(){
    this.graph?.chart?.chart?.update();
  }

}
