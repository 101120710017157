<div class="content">
    <div class="container">
        <div>
            <div class="header">
                Start time
            </div>
            <app-time-input [(time)]="startTime"></app-time-input>
        </div>
        <div class="spacing"></div>
        <div>
            <div class="header">
                End time
            </div>
            <app-time-input [(time)]="endTime"></app-time-input>
        </div>
    </div>
    <div class="total">
        <div class="total-text">
            total: 
        </div>
        <div class="total-value">
            {{duration}}
        </div>
    </div>
</div>