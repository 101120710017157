<div class="title-bar">
    <div class="title">
        {{screenTitle}}
    </div>
    <div></div>
    <div class="confirm-panel">
        <div class="confirm-btn">
            <app-button (onClick)="onSaveClick()" text="Save" [textStyle]="savePatientButtonTextStyle"></app-button>
        </div>
    </div>
</div>

<div [ngStyle]="{'text-align':'center', 'display':'block'}">
    <span class="error-input" *ngIf="submit_error!=''">{{submit_error | json}}</span>
</div>

<div class="form-grid">
    <div>
        <div class="form-title" style="margin-top: 40px;">
            Basic info
        </div>
        <div class="form-separator-bold"></div>
        <div class="form-sub-grid" style="margin-top: 40.23px;">
            <div class="form-image-entry">
                <img class="form-patient-image" [src]="patientRecord.basicInfo.imageBase64 ? 'data:image/jpg;base64,'+patientRecord.basicInfo.imageBase64 : ''" />
                <img src="assets/img/plus-button.svg" class="form-image-entry-button" (click)="fileInput.click()">
                <input style="display: none" type="file" accept="image/*" (change)="onImageChanged($event)" #fileInput>
            </div>
            <div [formGroup]="login_form">
                <div class="input-title">
                    First name
                </div>
                <app-input [customStyle]="singleLineStyle" [cols]="40" [rows]="1" [(text)]="patientRecord.basicInfo.first_name" (change)="markChangedSection('basicInfo')" ngDefaultControl formControlName="first_name" required></app-input>
<!--                <span class="error-input" *ngIf="login_form.controls['first_name'].hasError('required') && (login_form.controls['first_name'].dirty || login_form.controls['first_name'].touched)">Field is required</span>                
    -->
                <span class="error-input" *ngIf="isControlValid('first_name', 'required')">Field is required</span>                
            </div>
            <div [formGroup]="login_form">
                <div class="input-title">
                    Last name
                </div>
                <app-input [customStyle]="singleLineStyle" [cols]="40" [rows]="1" [(text)]="patientRecord.basicInfo.last_name" (change)="markChangedSection('basicInfo')" ngDefaultControl formControlName="last_name" required></app-input>
<!--                <span class="error-input" *ngIf="login_form.controls['last_name'].hasError('required') && (login_form.controls['last_name'].dirty || login_form.controls['last_name'].touched)">Field is required</span>                
    -->
                <span class="error-input" *ngIf="isControlValid('last_name', 'required')">Field is required</span>                
            </div>
            <div [formGroup]="login_form">
                <div class="input-title">
                    Patient ID
                </div>
                <app-input [customStyle]="singleLineStyle" [cols]="40" [rows]="1" [(text)]="patientRecord.basicInfo.patient_id" (change)="markChangedSection('basicInfo')" ngDefaultControl formControlName="patient_id" required></app-input>
<!--                <span class="error-input" *ngIf="login_form.controls['patient_id'].hasError('required') && (login_form.controls['patient_id'].dirty || login_form.controls['patient_id'].touched)">Field is required</span>                
    -->
                <span class="error-input" *ngIf="isControlValid('patient_id', 'required')">Field is required</span>                
            </div>
            <div>
                <div class="input-title">
                    Gender
                </div>
                <div style="margin-top: 13px"></div>
                <app-toggle [items]="genderOptions" [selected]="genderOptions[0]" (selectedChange)="onSelectedChange($event)" ></app-toggle>
            </div>
            <div [formGroup]="login_form">
                <div style="display: flex; flex-direction: row;">
                    <img src="assets/img/cake.svg"/>
                    <div class="input-title" style="margin-top: 14px; margin-left: 6px;">
                        Date of birth
                    </div>
                </div>
                <app-input [customStyle]="singleLineStyle" [cols]="40" [rows]="1" [(text)]="patientRecord.basicInfo.dob" ngDefaultControl formControlName="dob" (change)="markChangedSection('basicInfo')" required></app-input>
<!--                <span class="error-input" *ngIf="login_form.controls['dob'].hasError('pattern') && (login_form.controls['dob'].dirty || login_form.controls['dob'].touched)">Format is 'YYYY-MM-DD'</span>
                <span class="error-input" *ngIf="login_form.controls['dob'].hasError('required') && (login_form.controls['dob'].dirty || login_form.controls['dob'].touched)">Field is required</span>
                -->
                <span class="error-input" *ngIf="isControlValid('dob', 'pattern')">Format is 'YYYY-MM-DD'</span>
                <span class="error-input" *ngIf="isControlValid('dob', 'required')">Field is required</span>
            </div>
            <div style="width: 140px;">
                <div style="display: flex; flex-direction: row;">
                    <img src="assets/img/footsteps.svg" />
                    <div class="input-title" style="margin-top: 14px; margin-left: 6px;">
                        Shoe size
                    </div>
                </div>
                <app-select [optionTextStyle]="selectShoeSize.optionTextStyle" [options]="selectShoeSize.options" [(selectedOptionId)]="patientRecord.basicInfo.shoe_size" (selectedOptionIdChange)="markChangedSection('basicInfo')"></app-select>
            </div>
            <div [formGroup]="login_form">
                <div style="display: flex; flex-direction: row;">
                    <img src="assets/img/phone.svg"/>
                    <div class="input-title" style="margin-top: 14px; margin-left: 6px;">
                        Phone number
                    </div>
                </div>
                <div>
                    <ngx-intl-tel-input [preferredCountries]="preferredCountries"
				        [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
				        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
				        [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
				        [phoneValidation]="true" [separateDialCode]="separateDialCode"
				        [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="phone" (change)="markChangedSection('basicInfo')">
			        </ngx-intl-tel-input>
                </div>
                <div>                
<!--                    <span class="error-input" *ngIf="login_form.controls['phone'].invalid && (login_form.controls['phone'].dirty || login_form.controls['phone'].touched)">Invalid phone number</span>                
    -->
                    <span class="error-input" *ngIf="isControlValid('phone', '')">Invalid phone number</span>                
                </div>
            </div>
            <div [formGroup]="login_form">
                <div style="display: flex; flex-direction: row;">
                    <img src="assets/img/mail.svg"/>
                    <div class="input-title" style="margin-top: 14px; margin-left: 6px;">
                        Email
                    </div>
                </div>
                <app-input [customStyle]="singleLineStyle" [cols]="40" [rows]="1" [(text)]="patientRecord.basicInfo.email" type="text" ngDefaultControl formControlName="email" (change)="markChangedSection('basicInfo')"></app-input>
<!--			    <span class="error-input" *ngIf="login_form.controls['email'].hasError('pattern') && (login_form.controls['email'].dirty || login_form.controls['email'].touched)">Please enter valid email address</span>
                <span class="error-input" *ngIf="login_form.controls['email'].hasError('required') && (login_form.controls['email'].dirty || login_form.controls['email'].touched)">Field is required</span>
-->
                <span class="error-input" *ngIf="isControlValid('email', 'pattern')">Please enter valid email address</span>
                <span class="error-input" *ngIf="isControlValid('email', 'required')">Field is required</span>
            </div>
        </div>
        <div class="form-separator"></div>
        <div class="form-sub-grid2" style="margin-top: 20px;">
            <div style="grid-column: 1 / 4;">
                <div style="display: flex; flex-direction: row;">
                    <img src="assets/img/location.svg"/>
                    <div class="input-title" style="margin-top: 14px; margin-left: 6px;">
                        Address
                    </div>
                </div>
                <app-input [customStyle]="singleLineStyle" [cols]="80" [rows]="1" [(text)]="patientRecord.basicInfo.address1" (change)="markChangedSection('basicInfo')"></app-input>
            </div>
            <div>
                <div class="input-title">
                    City
                </div>
                <app-input [customStyle]="singleLineStyle" [cols]="30" [rows]="1" [(text)]="patientRecord.basicInfo.city" (change)="markChangedSection('basicInfo')"></app-input>
            </div>
            <div>
                <div class="input-title">
                    State
                </div>
                <app-input [customStyle]="singleLineStyle" [cols]="30" [rows]="1" [(text)]="patientRecord.basicInfo.state" (change)="markChangedSection('basicInfo')"></app-input>
            </div>
            <div>
                <div class="input-title">
                    Zip code
                </div>
                <app-input [customStyle]="singleLineStyle" [cols]="30" [rows]="1" [(text)]="patientRecord.basicInfo.zip_code" (change)="markChangedSection('basicInfo')"></app-input>
            </div>
        </div>
        <div class="form-separator"></div>
        <div class="form-sub-grid3" style="margin-top: 20px;">
            <div>
                <div style="display: flex; flex-direction: row;">
                    <img src="assets/img/insurer.svg"/>
                    <div class="input-title" style="margin-top: 14px; margin-left: 6px;">
                        Insurer
                    </div>
                </div>
                <app-input [customStyle]="singleLineStyle" [cols]="40" [rows]="1" [(text)]="patientRecord.insurance.insurer" (change)="markChangedSection('insurance')"></app-input>
            </div>
            <div style="width: 140px;">
                <div class="input-title" style="margin-top: 14px;">
                    Visits
                </div>
                <app-select [optionTextStyle]="selectNumberOfVisits.optionTextStyle" [options]="selectNumberOfVisits.options" [selectedOptionId]="patientRecord.insurance.insurer_visits.toString()" (selectedOptionIdChange)="onNumberOfVisitsChanged($event); markChangedSection('insurance')"></app-select>
            </div>
        </div>
        <div class="form-separator"></div>
        <div class="form-sub-grid4" style="margin-top: 20px;">
            <div>
                <div class="input-title">
                    Relevant background
                </div>
<!--
                <app-input [customStyle]="multiLineStyle" [cols]="80" [rows]="5" [(text)]="patientRecord.medicalInfo.relevant_background" (change)="markChangedSection('medicalInfo')"></app-input>
    -->
                <app-multiline-input [customStyle]="multiLineStyle" [cols]="80" [rows]="5" [(text)]="patientRecord.medicalInfo.relevant_background" (change)="markChangedSection('medicalInfo')"></app-multiline-input>
            </div>
            <div>
                <div class="input-title">
                    Feet pathology
                </div>
<!--
                <app-input [customStyle]="multiLineStyle" [cols]="80" [rows]="5" [(text)]="patientRecord.medicalInfo.feet_pathology" (change)="markChangedSection('medicalInfo')"></app-input>
-->
                <app-multiline-input [customStyle]="multiLineStyle" [cols]="80" [rows]="5" [(text)]="patientRecord.medicalInfo.feet_pathology" (change)="markChangedSection('medicalInfo')"></app-multiline-input>
            </div>
        </div>
        <div style="height: 80px;"></div>
    </div>
    <div class="first-visit-record-box">
        <div class="first-visit-record-content">
            <div style="display: flex; flex-direction: row; align-items: center;">
                <div class="first-visit-record-rectangle">
                    <img src="assets/img/ic-dashboard-8.svg" style="margin-top: 11px; width: 12px; height:14px;"/>
                </div>
                <div class="form-title" style="margin-left: 10px;">
                    First visit record
                </div>
            </div>
            <div class="form-separator" style="background-color: #000; margin-top: 23px;"></div>
            <div class="first-visit-record-sub-grid" style="margin-top: 53px;">
                <div style="grid-column: 1 / 4;">
                    <div class="input-title">
                        Title
                    </div>
                    <app-input [customStyle]="singleLineStyle" [cols]="30" [rows]="1" [(text)]="patientFirstVisit.title"></app-input>
                </div>
                <div>
                    <div class="input-title">
                        Date
                    </div>
                    <app-date-select [customStyle]="singleLineStyle" [date]="patientFirstVisit.visit_date_start" (dateChange)="onFirstVisitDateChange($event)"></app-date-select>
                </div>
                <div style="grid-column: 1 / 5;">
                    <div class="input-title">
                        Description
                    </div>
<!--
                    <app-input [customStyle]="multiLineStyle" [cols]="80" [rows]="5" [(text)]="patientFirstVisit.description"></app-input>
-->
                    <app-multiline-input [customStyle]="multiLineStyle" [cols]="80" [rows]="5" [(text)]="patientFirstVisit.description"></app-multiline-input>
                </div>
                <div style="grid-column: 1 / 5;">
                    <div class="input-title">
                        Duration
                    </div>
                    <!--<app-time-range-input [date]="patientFirstVisit.visit_date_start" (startDate)="patientFirstVisit.visit_date_start" (endDate)="patientFirstVisit.visit_date_end"></app-time-range-input>-->
                    <!-- REPLACING TIME-RANGE-INPUT START -->
                    <div>
                        <div class="time-title">
                            Start time
                        </div>
                        <app-time-input [(time)]="startTime"></app-time-input>
                    </div>
                    <div class="spacing"></div>
                    <div>
                        <div class="time-title">
                            End time
                        </div>
                        <app-time-input [(time)]="endTime"></app-time-input>
                    </div>
                    <!-- REPLACING TIME-RANGE-INPUT ENDS -->
                </div>
                <div style="grid-column: 1 / 5; width: 212px;">
                    <div class="input-title">
                        Risk level
                    </div>
                    <div style="margin-top: 13px"></div>
                    <app-toggle [items]="riskLevelOptions" [selected]="selectedRisk" (selectedChange)="onRiskLevelSelectedChange($event)" ></app-toggle>
                </div>
                <div style="grid-column: 1 / 3;">
                    <div class="input-title">
                        Attachments
                    </div>
                </div>
                <div style="grid-column: 3 / 5;">
                    <div class="file-input">
                        <img src="assets/img/attachment.svg" class="attachment-img">
                        <div style="width: 9.5px"></div>
                        <div>
                            from file
                        </div>
                    </div>
                </div>
                <div style="grid-column: 1 / 5;">
                    <app-attachments></app-attachments>
                </div>
            </div>
        </div>
    </div>
</div>