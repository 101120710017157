import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  count: number = 0;
  isVisible: BehaviorSubject<boolean>;

  constructor() {
    this.isVisible = new BehaviorSubject<boolean>(false);
  }

  show() {
    this.count++;
    this.isVisible.next(true);
  }

  hide() {
  this.count--;
  if(this.count <= 0) this.isVisible.next(false);
  }

}
