<div class="first-visit-record-content">
    <div style="display: flex; flex-direction: row; align-items: center;">
        <img src="assets/img/person-orange.svg"/>
        <div class="title" style="margin-left: 10px;">
            {{screenTitle}}
        </div>
    </div>
    <div class="first-visit-record-sub-grid" style="margin-top: 33px;">
        <div style="grid-column: 1 / 4;">
            <div class="input-title">
                Title
            </div>
            <app-input [customStyle]="singleLineStyle" [cols]="30" [rows]="1" [(text)]="visit2.title" [disabled]="isDisabled"></app-input>
        </div>
        <div>
            <div class="input-title">
                Date
            </div>
            <app-date-select [customStyle]="singleLineStyle" [date]="visit2.visit_date_start" (dateChange)="onFirstVisitDateChange($event)" [disabled]="isDisabled"></app-date-select>
        </div>
        <div style="grid-column: 1 / 5;">
            <div class="input-title">
                Description
            </div>
<!--
            <app-input [customStyle]="multiLineStyle" [cols]="80" [rows]="5" [(text)]="visit2.description" [disabled]="isDisabled"></app-input>
-->
            <app-multiline-input [customStyle]="multiLineStyle" [cols]="80" [rows]="5" [(text)]="visit2.description" [disabled]="isDisabled"></app-multiline-input>
        </div>
        <div style="grid-column: 1 / 5;">
            <div class="input-title">
                Duration
            </div>
<!--            <app-time-range-input [date]="visit2.visit_date_start" (startDate)="visit2.visit_date_start" (endDate)="visit2.visit_date_end"></app-time-range-input>-->
<!-- REPLACING TIME-RANGE-INPUT START -->
<div>
    <div class="header">
        Start time
    </div>
    <app-time-input [(time)]="startTime" [disabled]="isDisabled"></app-time-input>
</div>
<div class="spacing"></div>
<div>
    <div class="header">
        End time
    </div>
    <app-time-input [(time)]="endTime" [disabled]="isDisabled"></app-time-input>
</div>
<!-- REPLACING TIME-RANGE-INPUT ENDS -->
        </div>
        <div style="grid-column: 1 / 5; width: 212px;">
            <div class="input-title">
                Risk level
            </div>
            <div style="margin-top: 13px"></div>
            <app-toggle [items]="riskLevelOptions" [selected]="selectedRisk" (selectedChange)="onRiskLevelSelectedChange($event)" [disabled]="isDisabled"></app-toggle>
        </div>
        <div style="grid-column: 1 / 3;">
            <div class="input-title">
                Attachments
            </div>
        </div>
        <div style="grid-column: 3 / 5;">
            <div class="file-input">
                <img src="assets/img/attachment.svg" class="attachment-img">
                <div style="width: 9.5px"></div>
                <div>
                    from file
                </div>
            </div>
        </div>
        <div style="grid-column: 1 / 5;">
            <app-attachments></app-attachments>
        </div>
    </div>
    <div class="save-btn">
        <app-button (onClick)="onSaveNewVisitClick()" [text]="btnText" [textStyle]="saveVisitButtonTextStyle"></app-button>
    </div>
</div>
