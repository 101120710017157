import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from '../entities/menu-item';

@Component({
  selector: 'app-left-menu-item',
  templateUrl: './left-menu-item.component.html',
  styleUrls: ['./left-menu-item.component.scss']
})
export class LeftMenuItemComponent implements OnInit {

  expanded : boolean = false;
  @Output() clicked = new EventEmitter<MenuItem>();
  @Input()  data!: MenuItem;

  constructor() { }

  ngOnInit(): void {
  }

  onExpandClick(){
    this.expanded = !this.expanded;
  }

  onClick(){
/* Commented out for changing Click behavior
    this.data.selected = true;
    this.expanded = true;
    if(this.data.items != undefined && this.data.items.length > 0){
      this.data.items[0].selected = true;
      this.clicked.emit(this.data.items[0]);
    }else{
      this.clicked.emit(this.data);
    }
*/
    // YM 2022-07-24 NEW BEHAVIOR (TEST)
    this.data.selected = true;
    if(this.data.items != undefined && this.data.items.length > 0){
      this.  onExpandClick();
    }else{
      this.clicked.emit(this.data);
      this.expanded = true;
    }
  }

  onSubMenuClicked($event : MenuItem){
    this.data.selected = true;
    this.clicked.emit($event);
  }

}
