import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-date-range-select',
  templateUrl: './date-range-select.component.html',
  styleUrls: ['./date-range-select.component.scss']
})
export class DateRangeSelectComponent implements OnInit {
 
  @Output() dateFromChange : EventEmitter<Date> = new EventEmitter<Date>();
  @Output() dateToChange : EventEmitter<Date> = new EventEmitter<Date>();
  @Output() btnCLick = new EventEmitter();
  @Input() dateFrom: Date = new Date();
  @Input() dateTo: Date = new Date();
  
  constructor() { }

  ngOnInit(): void {
  }

  updateDateFrom($event: Date) {
    this.dateFrom = $event;
    this.dateFromChange.emit($event);
  }

  updateDateTo($event: Date) {
    this.dateTo = $event;
    this.dateToChange.emit($event);
  }

  onUpdateClick() {
    //this.dateFromChange.emit();
    //this.dateToChange.emit();
    this.btnCLick.emit();
  }
}
