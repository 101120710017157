import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { PatientVisit, PatientVisit2 } from '../interfaces/patient-visit';
import { CustomDialogService } from '../services/custom-dialog.service';
import { NewVisitDialogComponent } from '../dialogs/new-visit-dialog/new-visit-dialog.component';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-patient-history-entry',
  templateUrl: './patient-history-entry.component.html',
  styleUrls: ['./patient-history-entry.component.scss']
})
export class PatientHistoryEntryComponent implements OnInit {

  @Output() actionClick : EventEmitter<PatientVisit2> = new EventEmitter<PatientVisit2>();
  @Output() deleteRecord: EventEmitter<number> = new EventEmitter<number>();

  @Input() visit : PatientVisit = {
    attachmentsPaths: [],
    description: "",
    riskLevel: "mid",
    title: "",
    endTime: new Date(),
    startTime: new Date()
  }

  @Input() visit2 : PatientVisit2 = {
    id: 0,
    description: "",
    risk_level: "mid",
    title: "",
    visit_date_end: new Date(),
    visit_date_start: new Date(),
    user: 0,
  }

  Math = Math;

  //---------- CODE RELATED TO MENU OPTIONS
  historyMenuOptions = [
    {
      key: 'EDIT', displayName: 'Edit'
    },
    {
      key: 'DELETE', displayName: 'Delete'
    }
  ];

  onMenuOptionSelected(option: string) {
    console.log("patient-history-entry: onMenuOptionSelected");
    switch(option) {
      case "EDIT":
        this.onVisitClick(option);
        break;
      case "DELETE":
        this.deleteVisitRecord();
        break;
      default:
        console.log("onMenuOptionSelected: option = " + option);
        break;
    }
  }
  //----------

  constructor( private dialog: CustomDialogService, private api: ApiService ) { }

  ngOnInit(): void {
  }

  get textContent(){
    //return this.getFormattedDate(this.visit.startTime);
    return this.getFormattedDate(this.visit2.visit_date_start);
  }

  getFormattedDate(date: Date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return month + '/' + day + '/' + year;
  }

  onVisitClick(viewMode: string = "VIEW"){
    const ref = this.dialog.open(NewVisitDialogComponent, {
      data: { record: this.visit2, mode:viewMode },
      paddingBottom: "33px",
      paddingTop: "40px",
      paddingLeft: "48px",
      paddingRight: "48px",
      borderRadius: "34px"
    });

    ref.afterClosed.subscribe(result => {
      if(result.user != 0){
        //TODO: Add result.visit to DB.
      }
    });

  }

  deleteVisitRecord() {
    this.api.deleteVisit(this.visit2.id, (success) => {
      if(success) {
        console.log("patient-history-entry: deleteVisitRecord: Visit was delete successfuly");
        this.deleteRecord.emit(this.visit2.id);
      }
    })
  }

}
