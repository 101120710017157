import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CalendarComponent } from '../calendar/calendar.component';

@Component({
  selector: 'app-date-select',
  templateUrl: './date-select.component.html',
  styleUrls: ['./date-select.component.scss']
})
export class DateSelectComponent implements OnInit {

  @Input() customStyle: any;
  @Output() dateChange : EventEmitter<Date> = new EventEmitter<Date>();
  @Input() date: Date = new Date();
  showSelectionArea : boolean = false;
  @Input() disabled: boolean = false;

  @ViewChild('calendar') calendar : CalendarComponent | null = null;

  constructor(private eRef: ElementRef) { }

  get textContent(){
    return this.getFormattedDate(this.date);
  }

  ngOnInit(): void {
  }

  onDateSelectClick(){
    if(this.disabled) return;
    this.showSelectionArea = !this.showSelectionArea;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.showSelectionArea = false;
    }
  }

  onDateClick($event: Date){
    this.date = $event;
    if(this.calendar){
      this.calendar.markStartDate = this.date;
      this.calendar.markEndDate = this.date;
    }
    this.dateChange.emit(this.date);

    //TODO: Decide if we need to keep Calendar open when clicking a date
    this.showSelectionArea = false;
  }

  getFormattedDate(date: Date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    //return month + '/' + day + '/' + year;
    return year + '-' + month + '-' + day;
  }

}
