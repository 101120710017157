import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, Input, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { CustomDialogInsertionDirective } from '../directives/custom-dialog-insertion.directive';
import { Subject } from 'rxjs';
import { CustomDialogRef } from './custom-dialog-ref';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CustomDialogInterface } from '../interfaces/custom-dialog-interface';
import { CustomDialogConfig } from './custom-dialog-config';

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.css'],
  animations: [
    trigger('transparentOpaque', [
      state('transparent', style({
        opacity: 0
      })),
      state('opaque', style({
        opacity: 1
      })),
      transition('transparent => opaque', [
        animate('0.25s')
      ]),
      transition('opaque => transparent', [
        animate('0.25s')
      ]),
    ]),
  ],
})
export class CustomDialogComponent implements AfterViewInit, OnDestroy {

  componentRef: ComponentRef<CustomDialogInterface> | undefined;
  childComponentType: Type<CustomDialogInterface> | undefined;
  transparentOpaque: string = "transparent";

  @Input() config: CustomDialogConfig = {
    borderRadius: "0px",
    paddingBottom: "0px",
    paddingTop: "0px",
    paddingRight: "0px",
    paddingLeft: "0px"
  }

  @ViewChild(CustomDialogInsertionDirective) insertionPoint: CustomDialogInsertionDirective | undefined;

  private readonly _onClose = new Subject<CustomDialogInterface | undefined>();
  public onClose = this._onClose.asObservable();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private dialogRef: CustomDialogRef
  ) { }

  ngAfterViewInit(): void {
    this.loadChildComponent(this.childComponentType!);
    this.transparentOpaque = "opaque";
    if(this.componentRef != undefined){
      this.config = this.componentRef.instance.getConfig();
    }
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  onOverlayClicked(evt: MouseEvent): void {
    if(this.componentRef != undefined && this.componentRef.instance != undefined){
      this.componentRef.instance.onOverlayClicked();
    }else{
      this.close();
    }
  }

  onDialogClicked(evt: MouseEvent): void {
    evt.stopPropagation();
  }

  loadChildComponent(componentType: Type<CustomDialogInterface>): void {
    let viewContainerRef = this.insertionPoint?.viewContainerRef;
    viewContainerRef?.clear();
    if(viewContainerRef != undefined){
      this.componentRef = viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(componentType).componentType);
    }
  }

  close(): void {
    this._onClose.next(undefined);
  }
}
