import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from '../entities/menu-item';

@Component({
  selector: 'app-sub-menu-item',
  templateUrl: './sub-menu-item.component.html',
  styleUrls: ['./sub-menu-item.component.scss']
})
export class SubMenuItemComponent implements OnInit {

  @Output() clicked = new EventEmitter<MenuItem>();
  @Input()  data!: MenuItem;
  
  constructor() { }

  ngOnInit(): void {
  }

  onClick(){
    this.data.selected = true;
    this.clicked.emit(this.data);
  }

}
