import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() text: string = '';
  @Input() imageSrc: string = '';
  @Input() disabled: boolean = false;
  @Input() buttonStyle: any = {};
  @Input() textStyle: any = {};
  @Input() imageStyle: any = {};
  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  OnClick(){
    if(!this.disabled){
      this.onClick.emit();
    }
  }

}
