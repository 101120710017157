import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PatientDisplayRecord, PatientRecord, PatientSummary } from '../interfaces/patient-record';
import { PatientVisit, PatientVisit2 } from '../interfaces/patient-visit';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-patient-record',
  templateUrl: './patient-record.component.html',
  styleUrls: ['./patient-record.component.scss']
})
export class PatientRecordComponent implements OnInit {

  @Output() actionClick : EventEmitter<PatientRecord> = new EventEmitter<PatientRecord>();
  @Input() displayRecord : PatientDisplayRecord;
  constructor() {
    let lastActiveDate = new Date();
    lastActiveDate.setDate(lastActiveDate.getDate() - 3);
    this.displayRecord = {
      record: {
        basicInfo: {
          id: 0,
          first_name : 'John',
          last_name: 'Doe',
          address1:'',
          address2:'',
          city: '',
          //dob: new Date(),
          dob: formatDate(new Date(),'YYYY-MM-dd','en-us'),
          email: '',
          gender: 'male',
          phone_number: '',
          shoe_size: '12',
          state: '',
          zip_code: '',
          imageBase64: '',
          patient_id: '00000000',
          footedness: 'right',
        },
        medicalInfo: {
          relevant_background: '',
          feet_pathology: '',
          foot_ulcer: false,
        },
        insurance: {
          insurer: '',
          insurer_visits: 12,
        },
        history: [],
        isActive : true,
        insoleStatus: false,
        lastActiveInsoleDate: lastActiveDate,
        authorizationDate: new Date(),
      },
      isChecked : false,
      isExpanded : false,
    }
   }

  get containerClass(){
    if(this.displayRecord.isChecked){
      if(this.displayRecord.isExpanded){
        return 'container-active-expanded';
      }else{
        return 'container-active';
      }
    }else{
      if(this.displayRecord.isExpanded){
        return 'container-expanded';
      }else{
        return 'container';
      }
    }
  }

  get lastActiveText(): string{
    let daysAgo = Math.floor((new Date().valueOf() - this.displayRecord.record.lastActiveInsoleDate.valueOf())/1000/60/60/24);
    if(daysAgo == 0){
      let hoursAgo = Math.floor((new Date().valueOf() - this.displayRecord.record.lastActiveInsoleDate.valueOf())/1000/60/60);
      if(hoursAgo == 0){
        return 'In the last hour';
      }
      if(hoursAgo == 1){
        return 'An hour ago';
      }
      return `${hoursAgo} hours ago`;
    }else{
      if(daysAgo == 1){
        return 'Yesterday';
      }
      return `${daysAgo} days ago`;
    }
  }

  getHistoryRecordClass(historyRecord : PatientVisit2){
    if(this.displayRecord.record.history.findIndex((value) => value == historyRecord) == this.displayRecord.record.history.length-1){
      return 'history-entry-last';
    }else{
      return 'history-entry';
    }
  }

  ngOnInit(): void {
  }

  onPatientClick(){
    this.actionClick.emit(this.displayRecord.record);
  }

}
