<div [ngClass]="data.selected ? 'selected' : 'not-selected'">
    <div [ngClass]="data.selected ? 'icon-selected' : 'icon-not-selected'">
        <div (click)="onClick()" style="display: flex;">
            <img [src]="data.icon" [ngClass]="data.selected ? 'img-icon-selected' : 'img-icon-not-selected'">
            <div [ngClass]="data.selected ? 'text-selected' : 'text-not-selected'">
                    {{data.title}}
            </div>
        </div>
        <button *ngIf="data.items !== undefined" class="expand-btn" (click)="onExpandClick()">
            <img [ngClass]="expanded ? 'cheveron-up' : 'cheveron-down'" src="assets/img/fill-1.svg" />
        </button>
    </div>
</div>
<div class="sub-menu-item">
    <div *ngIf="expanded">
        <app-sub-menu-item *ngFor="let menuItem of data.items" [data]="menuItem" (clicked)="onSubMenuClicked($event)"></app-sub-menu-item>
    </div>
</div>
