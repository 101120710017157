<div style="position: relative;">
    <div class='select' [ngStyle]="selectDivStyle">
        <div class="select-text" style="margin: left 20px;" [ngStyle]="constantTextStyle">
            {{constantText}}
        </div>
        <div *ngIf="displaySelectedOption" style="display: flex;">
            <img *ngIf="selectDivImage" src="assets/img/ic-watch-video.svg" />
            <div style="margin-left: 4px;"></div>
            <div class="selected-text" [ngStyle]="optionTextStyle">
                {{selectedOptionDisplayName}}
            </div>
        </div>
        <button *ngIf="options !== undefined" [ngClass]="disabled? 'expand-btn-disabled': 'expand-btn'" (click)="onExpandClick()">
            <img [ngClass]="disabled? 'cheveron-down-disabled': isDropdownOpen ? 'cheveron-up' : 'cheveron-down'" src="assets/img/fill-1.svg"/>
        </button>
    </div>
    <div *ngIf="isDropdownOpen" class="drawer" [ngStyle]="drawerStyle">
        <div *ngFor="let option of options" class="drawer-option" (click)="onOptionSelected(option)">
            <div class="selected-text">
                {{option.displayName}}
            </div>
        </div>
    </div>
</div>
