<div class="container">
    <div class="content">
        <div class="content-grid">
            <div class="top">
                <img class="logo" src="assets/img/actics-logo.svg">
                <div class="login-input">
                    <div class="input-title">
                        E-mail
                    </div>        
                    <app-input (textChange)="onEmailChange($event)"></app-input>
                    <div style="height: 45px;"></div>

                    <div class="input-title">
                        Password
                    </div>
        
                    <app-input type="password" (textChange)="onPasswordChange($event)" (keyup)="onKeyup($event)"></app-input>
                    <div class="error-text">
                        {{errorText}}
                    </div>

                    <div style="height: 90px;"></div>
                    <div class="login-btn">
                        <app-button text="Login" [textStyle]="loginButtonTextStyle" (onClick)="onLoginClicked()"></app-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div></div>
</div>