import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ToggleComponentItem } from '../toggle/toggle.component';

export interface TimeInputTime{
  hours : number;
  minutes : number;
  is24hour : boolean;
  isAm : boolean;
}

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss']
})
export class TimeInputComponent implements OnInit {

  @ViewChildren('timedigit') digits: QueryList<ElementRef> | undefined;

  @Input() is24hour : boolean = false;
  @Output() timeChange : EventEmitter<TimeInputTime> = new EventEmitter<TimeInputTime>();
  @Input() time : TimeInputTime | undefined = { hours : 0, minutes : 0, is24hour: this.is24hour, isAm : true};
  @Input() disabled = false;

  get timeFirstDigit(){
    return (Math.floor(this.time!.hours / 10)).toString();
  }
  set timeFirstDigit(value : string){
    this.time!.hours = parseInt(value) * 10 + this.time!.hours % 10;
    this.timeChange.emit(this.time);
  }
  get timeSecondDigit(){
    return (this.time!.hours % 10).toString();
  }
  set timeSecondDigit(value : string){
    this.time!.hours = Math.floor(this.time!.hours / 10) * 10 + parseInt(value);
    console.log(this.time);
    this.timeChange.emit(this.time);
  }
  get timeThirdDigit(){
    return (Math.floor(this.time!.minutes / 10)).toString();
  }
  set timeThirdDigit(value : string){
    this.time!.minutes = parseInt(value) * 10 + this.time!.minutes % 10;
    this.timeChange.emit(this.time);
  }
  get timeForthDigit(){
    return (this.time!.minutes % 10).toString();
  }
  set timeForthDigit(value : string){
    this.time!.minutes = Math.floor(this.time!.minutes / 10)  * 10 + parseInt(value);
    this.timeChange.emit(this.time);
  }

  amPmOptions: ToggleComponentItem[] = [
    { value: "am", displayName: "AM"},
    { value: "pm", displayName: "PM"}
  ];

  get currentAmPmOption(){
    return this.time?.isAm ? this.amPmOptions[0] : this.amPmOptions[1]
  }

  constructor() { }

  ngOnInit(): void {
  }

  onDigit($event: any, index: number){
    if(this.digits != undefined && this.digits?.toArray()[index].nativeElement.value.length > 0){
      if(this.digits?.toArray().length > index + 1){
        this.digits?.toArray()[index+1].nativeElement.focus();
        this.digits?.toArray()[index+1].nativeElement.select();
      }
    }
  }

  onDigitClick($event: any, index: number){
    this.digits?.toArray()[index].nativeElement.select();
  }

  onFirstDigitKeyDown($event: any){
    if(this.digits?.toArray()[0].nativeElement.value.length >= 1){
      this.digits!.toArray()[0].nativeElement.value = "";
    }
    if(this.is24hour){
      if(($event.keyCode < 48 || $event.keyCode > 50) && ($event.keyCode < 96 || $event.keyCode > 98)){
        this.digits!.toArray()[0].nativeElement.value = "";
        $event.preventDefault();
        console.log("deleted");
      }else{
        if(($event.keyCode == 50 || $event.keyCode == 98) && parseInt(this.timeSecondDigit) > 4){
          this.timeSecondDigit = "4";
        }
      }
    }else{
      if(($event.keyCode < 48 || $event.keyCode > 49) && ($event.keyCode < 96 || $event.keyCode > 97)){
        this.digits!.toArray()[0].nativeElement.value = "";
        $event.preventDefault();
        console.log("deleted");
      }else{
        if(($event.keyCode == 49 || $event.keyCode == 97) && parseInt(this.timeSecondDigit) > 1){
          this.timeSecondDigit = "1";
        }
      }
    }
  }

  onSecondDigitKeyDown($event: any){
    if(this.digits?.toArray()[1].nativeElement.value.length >= 1){
      this.digits!.toArray()[1].nativeElement.value = "";
    }
    if(this.is24hour){
      if(parseInt(this.timeFirstDigit) == 2){
        if(($event.keyCode < 48 || $event.keyCode > 52) && ($event.keyCode < 96 || $event.keyCode > 100)){
          this.digits!.toArray()[1].nativeElement.value = "";
          $event.preventDefault();
          console.log("deleted");
        }
      }
    }else{
      console.log(this.time);
      if(parseInt(this.timeFirstDigit) == 1){
        if(($event.keyCode < 48 || $event.keyCode > 49) && ($event.keyCode < 96 || $event.keyCode > 97)){
          this.digits!.toArray()[1].nativeElement.value = "";
          $event.preventDefault();
          console.log("deleted");
        }
      }
    }
  }

  onThirdDigitKeyDown($event: any){
    if(this.digits?.toArray()[2].nativeElement.value.length >= 1){
      this.digits!.toArray()[2].nativeElement.value = "";
    }
    if($event.keyCode < 48 || $event.keyCode > 53 && ($event.keyCode < 96 || $event.keyCode > 101)){
      this.digits!.toArray()[2].nativeElement.value = "";
      $event.preventDefault();
      console.log("deleted");
    }
  }

  onForthDigitKeyDown($event: any){
    if(this.digits?.toArray()[3].nativeElement.value.length >= 1){
      this.digits!.toArray()[3].nativeElement.value = "";
    }
  }

  onSelectedChange($event: ToggleComponentItem){
    this.time!.isAm = $event == this.amPmOptions[0];
  }
}
