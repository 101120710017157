import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-record-menu',
  templateUrl: './record-menu.component.html',
  styleUrls: ['./record-menu.component.scss']
})
export class RecordMenuComponent implements OnInit {

  @Output() menuSelected: EventEmitter<string> = new EventEmitter<string>();
  @Input() menuOptions: { key: any, displayName: string}[] = [];
  isDropdownOpen: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  }

  onMenuClick(){
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onOptionSelected(option: { key: any, displayName: string}){
    this.isDropdownOpen = false;
    this.menuSelected.emit(option.key);
  }

}
