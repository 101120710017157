import { Component, EventEmitter, OnInit } from '@angular/core';
import { ContentComponent } from '../interfaces/content-component';

@Component({
  selector: 'app-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss']
})
export class PlaceholderComponent implements OnInit, ContentComponent {

  constructor() { }

  setData(data: any) {
  }
  
  onPageMove: EventEmitter<{ key: string; data: any; }>= new EventEmitter<{ key: string; data: any; }>();

  ngOnInit(): void {
  }

}
