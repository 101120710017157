import { Component, OnInit, EventEmitter, Input, Output, ViewChild, HostListener } from '@angular/core';
import { PatientRecord, PatientSummary } from '../interfaces/patient-record';
import { PatientVisit2 } from '../interfaces/patient-visit';
import { RecordMenuComponent } from '../record-menu/record-menu.component';

@Component({
  selector: 'app-patient-summary-record',
  templateUrl: './patient-summary-record.component.html',
  styleUrls: ['./patient-summary-record.component.scss']
})
export class PatientSummaryRecordComponent implements OnInit {

  @Output() actionClick : EventEmitter<PatientSummary> = new EventEmitter<PatientSummary>();
  @Input() summaryRecord : PatientSummary;
  @Output() actionMenuSelected : EventEmitter<{menuOption: string, patientId: number}> = new EventEmitter<{menuOption: string, patientId: number}>();

  @Output() recordClick : EventEmitter<number> = new EventEmitter<number>();
  @ViewChild(RecordMenuComponent) menuViewer : RecordMenuComponent | null = null;

  @HostListener('click')
  checkMenu() {
    this.recordClick.emit(this.summaryRecord.id);
  }

  
  patientMenuOptions = [
    {
      key: 'EDIT', displayName: 'Edit'
    },
    {
      key: 'DELETE', displayName: 'Delete'
    }
  ];

  isExpanded: boolean = false;


  constructor() {
    let lastActiveDate = new Date();
    lastActiveDate.setDate(lastActiveDate.getDate() - 3);
    this.summaryRecord = {
      id: 0,
      imageBase64: '',
      is_active: true,
      insole_status: false,
      //lastActiveInsoleDate: lastActiveDate,
      authorization_date: new Date(),
      isChecked : false,
      first_name: 'John',
      last_name: 'Doe',
      patient_id: '00000000',
      last_record_date: lastActiveDate,
      last_seen: '',
      last_visits: []
    }
  }

  get containerClass(){
    if(this.summaryRecord.isChecked)
        return 'container-active';
    else return 'container';
  }

  getHistoryRecordClass(visit : PatientVisit2){
    if(this.summaryRecord.last_visits.findIndex((value) => value == visit) == this.summaryRecord.last_visits.length-1){
      return 'history-entry-last';
    }else{
      return 'history-entry';
    }
  }

  get lastActiveText(): string{
    if(this.summaryRecord.last_seen == undefined || this.summaryRecord.last_seen === '') {
      return 'Never seen';
    }
    let daysAgo = parseInt(this.summaryRecord.last_seen);
    if(daysAgo == 0){
      let now = new Date();
      let recordDate = new Date(this.summaryRecord.last_record_date);
      let hoursAgo = Math.floor((now.valueOf() - recordDate.valueOf())/1000/60/60);
      if(hoursAgo == 0){
        return 'Last hour';
      }
      if(hoursAgo == 1){
        return 'An hour ago';
      }
      return `${hoursAgo} hours ago`;
    }else{
      if(daysAgo == 1){
        return 'Yesterday';
      }
      return `${daysAgo} days ago`;
    }
  }
  
  ngOnInit(): void {
  }
  onPatientClick(){
    console.log("patientSummaryRecord: onPatientClick");
    this.actionClick.emit(this.summaryRecord);
  }

  onMenuOptionSelected(option: string) {
    switch(option) {
      case "EDIT":
        this.editPatientRecord();
          break;
      case "DELETE":
        this.deletePatientRecord();
        break;
      default:
        console.log("onMenuOptionSelected: option = " + option);
        break;
    }
  }

  editPatientRecord() {
    console.log("patient-summary-record: editPatientRecord: id = " + this.summaryRecord.id);
    this.actionMenuSelected.emit({menuOption: "EDIT", patientId:this.summaryRecord.id});
  }
  
  deletePatientRecord() {
    console.log("patient-summary-record: deletePatientRecord: id = " + this.summaryRecord.id);
    this.actionMenuSelected.emit({menuOption: "DELETE", patientId:this.summaryRecord.id});
  }

}

